// cubicast recording
const cubicast = () => {
  const cubicastScript = document.createElement('script')
  cubicastScript.onload = !(function () {
    const cw = (window.cubicast = window.cubicast || {
      loaded: false,
      invoked: false,
      methods: ['identify', 'track', 'err', 'showSupportRequestDialog'],
      config: {},
      callQueue: [],
      methodFactory: function (method) {
        return function () {
          const args = Array.prototype.slice.call(arguments)
          args.unshift(method)
          cw.callQueue.push(args)
          return cw
        }
      },
      load: function (k) {
        if (cw.loaded) return
        const d = document
        const s = d.createElement('script')
        s.async = !0
        s.src = 'https://static.cubicast.com/js/widget/widget.js'
        const poll = setInterval(function () {
          const w = window.cubicast
          if (w.error || w.log) {
            clearInterval(poll)
            if (window.console && console.error) {
              if (w.error) {
                console.error(w.error)
              } else if (w.log) {
                console.log(w.log)
              }
            }
          } else if (w.loaded) {
            clearInterval(poll)
            w._fq(cw.callQueue)
          }
        }, 500)
        d.querySelector('head').appendChild(s)
        cw.config = { apiKey: k }
      }
    })
    if (cw.invoked) {
      if (window.console && console.error) {
        console.error('Cubicast widget snippet included twice.')
      }
      return
    }
    cw.invoked = true
    for (let i = 0; i < cw.methods.length; i += 1) {
      const key = cw.methods[i]
      cw[key] = cw.methodFactory(key)
    }
    cw.load(process.env.REACT_APP_CUBICAST_API_KEY)
  })()
  document.body.appendChild(cubicastScript)
}
// end of cubicast recording

if (process.env.NODE_ENV === 'production') {
  // load cubicast
  cubicast()
}
