const OrkaLogo = () => (
  <svg
    width='36'
    height='36'
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='36' height='36' fill='#F1F1F1' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M34 2H2V34H34V2ZM15.5 12C15.5 13.933 13.933 15.5 12 15.5C10.067 15.5 8.5 13.933 8.5 12C8.5 10.067 10.067 8.5 12 8.5C13.933 8.5 15.5 10.067 15.5 12ZM18 12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12Z'
      fill='black'
    />
  </svg>
)

export default OrkaLogo
