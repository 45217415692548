const Arrow = props => {
  const { right } = props

  const transform = 'rotate(180deg)'

  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
      style={{ transform: right ? transform : 'none' }}
    >
      <path d='M6.52719 13C6.52719 12.7239 6.75104 12.5 7.02719 12.5H17.5C17.7761 12.5 18 12.2761 18 12V8C18 7.72386 17.7761 7.5 17.5 7.5H7.02719C6.75105 7.5 6.52719 7.27614 6.52719 7V5.13943C6.52719 4.70395 6.00914 4.47662 5.68865 4.77147L0.400292 9.63688C0.184877 9.83507 0.185023 10.1751 0.400608 10.3731L5.68897 15.2301C6.00956 15.5246 6.52719 15.2972 6.52719 14.8619V13Z' />
    </svg>
  )
}

export default Arrow
