import React from 'react'
import { ToggleButtonGroup } from '@mui/material'
import { styled } from '@mui/material/styles'

const ToggleButtonGroupBase = styled(ToggleButtonGroup)(({ theme }) => ({
  borderRadius: '6px',
  height: '26px',
  width: '102px',
  backgroundColor: theme.palette.gray[300],
  alignItems: 'center',
  justifyContent: 'center',
  '&.MuiToggleButtonGroup-grouped': {
    '&.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
      borderRadius: '4px'
    },
    '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
      margin: 0,
      border: 'none',
      borderRadius: '4px'
    }
  }
}))

const FCToggleButtonGroup = (props) => {
  return (
    <ToggleButtonGroupBase {...props}>{props.children}</ToggleButtonGroupBase>
  )
}

export default FCToggleButtonGroup
