const Checked = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='20' height='20' rx='4' fill='#F9AA1E' />
    <path
      d='M3.94995 10.5504L7.79995 14.4004L16.05 6.15039'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default Checked
