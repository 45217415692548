const RadioChecked = () => (
  <svg
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='11' cy='11' r='10' stroke='#B1B7C2' strokeWidth='2' />
    <circle cx='11' cy='11' r='11' fill='#F9AA1E' />
    <path
      d='M5 11.4996L8.85 15.3496L17.1 7.09961'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default RadioChecked
