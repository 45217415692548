import client from './client'

export const getAppointments = async () => {
  let appointments
  try {
    const remoteFitting = await client().get('/appointments', {
      params: {
        is_audiologist: 'True'
      }
    })
    appointments = remoteFitting.data
    const clinicFitting = await client().get('/clinic', {
      params: {
        is_audiologist: 'True'
      }
    })
    appointments = clinicFitting.data.concat(appointments)
    const backendFitting = await client().get('/audiologists/reviewgaintables', {
      params: {
        is_audiologist: 'True'
      }
    })
    appointments = backendFitting.data.concat(appointments)
    return appointments
  } catch (e) {
    console.log('Error: ', e)
  }
}

export const getRemoteAppointments = async () => {
  try {
    const remoteFitting = await client().get('/appointments', {
      params: {
        is_audiologist: 'True'
      }
    })
    return remoteFitting.data
  } catch (e) {
    console.log('Error: ', e)
  }
}

export const patchApproachingAppointment = async (
  userName,
  startTime,
  selectedDate
) => {
  try {
    await client().patch(
      '/appointments/changeappointment/', {
        startTime: new Date(
          selectedDate.year(),
          selectedDate.month(),
          selectedDate.date(),
          9
        ).toISOString(),
        endTime: new Date(
          selectedDate.year(),
          selectedDate.month(),
          selectedDate.date(),
          21
        ).toISOString()
      }, {
        params: {
          is_audiologist: 'True',
          status: 4,
          user_email: userName,
          start_time: startTime + 'Z'
        }
      })
  } catch (e) {
    console.log('Error: ', e)
  }
}

export const getHistory = async (userName) => {
  try {
    const res = await client().get('/appointments/history', {
      params: {
        is_audiologist: 'True',
        username: userName
      }
    })
    return res.data.history
  } catch (e) {
    console.log('Error: ', e)
  }
}

export const getNote = async (userName) => {
  try {
    const res = await client().get('/appointments/comments', {
      params: {
        is_audiologist: 'True',
        username: userName
      }
    })
    return res.data
  } catch (e) {
    console.log('Error: ', e)
  }
}

export const postNote = async (userName, note) => {
  try {
    await client().post('/appointments/comments/', {
      comment: note
    }, {
      params: {
        is_audiologist: 'True',
        username: userName
      }
    })
  } catch (e) {
    console.log('Error: ', e)
  }
}

export const postNewAppointment = async (code) => {
  try {
    await client().post('/clinic/appointment/', {}, {
      params: {
        is_audiologist: 'True',
        code
      }
    })
  } catch (e) {
    console.log('Error: ', e)
  }
}
