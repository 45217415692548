import { createContext, useContext, useState } from 'react'
import { getAudiologistInfo } from 'services/apis/v1/audiologist'

const AudiologistContext = createContext({})

const useAudiologistContext = () => {
  return useContext(AudiologistContext)
}

const useAudiologistStates = () => {
  const [audiologistInfo, setAudiologistInfo] = useState(null)
  const fetchAudiologistInfo = async () => {
    const res = await getAudiologistInfo()
    setAudiologistInfo(res)
  }
  return { fetchAudiologistInfo, audiologistInfo, setAudiologistInfo }
}

export { AudiologistContext, useAudiologistContext, useAudiologistStates }
