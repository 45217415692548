const Search = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.40001 3C11.3823 3 13.8 5.41766 13.8 8.4C13.8 9.9112 13.1806 11.2759 12.1792 12.2572C11.2042 13.2126 9.8717 13.8 8.40001 13.8C5.41767 13.8 3.00001 11.3823 3.00001 8.4C3.00001 5.41766 5.41767 3 8.40001 3ZM15.8 8.4C15.8 4.31309 12.4869 1 8.40001 1C4.3131 1 1.00001 4.31309 1.00001 8.4C1.00001 12.4869 4.3131 15.8 8.40001 15.8C10.0618 15.8 11.5968 15.2513 12.8318 14.3266L17.2994 18.7135L18.7007 17.2865L14.2571 12.9231C15.2238 11.6728 15.8 10.1031 15.8 8.4Z'
    />
  </svg>
)

export default Search
