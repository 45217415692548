import React, { useEffect, useState } from 'react'
import { Box, Grid, styled, Typography } from '@mui/material'

import OrkaGainChartWc from 'components/GainChart'
import FCTable from 'components/Table/FCTable'
import { useCustomerContext } from 'contexts/Customer'
import { useTranslation } from 'react-i18next'

const Root = styled(Box)(({ theme }) => ({
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.03)',
  borderRadius: '12px',
  height: '408px',
  backgroundColor: theme.palette.white[0],
  overflow: 'hidden',
  '& .gain-container': {
    margin: 'auto',
    '& .customer-gain-chart': {
      '& > div': {
        width: '368px',
        height: '216px'
      }
    },
    '& .gain-chart-left': {
      marginLeft: '56px',
      [theme.breakpoints.down('xl')]: {
        marginLeft: '-8px'
      }
    }
  },
  '& .customer-gain-table-right': {
    marginTop: '8px',
    marginLeft: '8px'
  },
  '& .customer-gain-table-left': {
    marginTop: '8px',
    marginLeft: '64px',
    [theme.breakpoints.down('xl')]: {
      marginLeft: '0px'
    }
  }

}))

const Gain = (props) => {
  const { containerWidth } = props
  const { selectedHistory } = useCustomerContext()
  const [leftGainData, setLeftGainData] = useState(new Array(4).fill(0).map(() => new Array(8).fill(0)))
  const [rightGainData, setRightGainData] = useState(new Array(4).fill(0).map(() => new Array(8).fill(0)))
  const { t } = useTranslation()

  useEffect(() => {
    if (selectedHistory) {
      if (JSON.parse(selectedHistory.left_gaintable)) {
        setLeftGainData(JSON.parse(selectedHistory.left_gaintable))
      } else {
        setLeftGainData([])
      }
      if (JSON.parse(selectedHistory.right_gaintable)) {
        setRightGainData(JSON.parse(selectedHistory.right_gaintable))
      } else {
        setRightGainData([])
      }
    }
  }, [selectedHistory])

  const threshold = [20, 31, 38, 40, 40, 37, 35, 32]

  return (
    <Root
      sx={{
        width: containerWidth || {
          xs: '744px',
          xl: '944px'
        }
      }}
    >
      <Grid container justifyContent='center'>
        <Grid
          item
          container
          spacing={1}
          direction='column'
          sx={{ padding: '24px' }}
        >
          <Grid item>
            <Typography variant='h5'>{t('client_tab_gain_section_title')}</Typography>
          </Grid>
          <Grid item className='gain-container'>
            <Grid
              container
              wrap='nowrap'
              justifyContent='space-around'
            >
              <Grid container direction='column'>
                <Grid item className='customer-gain-chart gain-chart-right'>
                  <Box>
                    <OrkaGainChartWc
                      gainData={rightGainData}
                      threshold={threshold}
                      display
                    />
                  </Box>
                </Grid>
                <Grid item className='customer-gain-table-right'>
                  <FCTable gainTable={rightGainData} displayOnly />
                </Grid>
              </Grid>
              <Grid item container direction='column'>
                <Grid item className='customer-gain-chart gain-chart-left' sx={{ marginLeft: containerWidth ? '56px !important' : '0px' }}>
                  <Box>
                    <OrkaGainChartWc
                      isLeft
                      gainData={leftGainData}
                      threshold={threshold}
                      display
                    />
                  </Box>
                </Grid>
                <Grid item className='customer-gain-table-left' sx={{ marginLeft: containerWidth ? '64px !important' : '0px' }}>
                  <FCTable gainTable={leftGainData} displayOnly />
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </Root>
  )
}

export default Gain
