import React, { useEffect } from 'react'
import { Grid, Container, Box } from '@mui/material'

import CustomerList from 'pages/sections/CustomerProfile/CustomerList'
import FittingHistory from 'pages/sections/CustomerProfile/FittingHistory'
import Profile from 'pages/sections/CustomerProfile/Profile'
import Audiogram from 'pages/sections/CustomerProfile/Audiogram'
import Gain from 'pages/sections/CustomerProfile/Gain'
import { getCustomerList } from 'services/customers'
import { useCustomerContext } from 'contexts/Customer'

const Customer = () => {
  const { allCustomers, setAllCustomers } = useCustomerContext()
  // get customerList
  useEffect(() => {
    const fetchInfo = async () => {
      const result = await getCustomerList()
      setAllCustomers(result)
    }
    if (allCustomers === null) {
      fetchInfo()
    }
  }, [allCustomers])

  return (
    <Box sx={{ marginTop: 'clamp(16px,calc((100vh - 826px)/2),88px)' }}>
      <Container
        fixed
        sx={{
          maxWidth: { xl: '1744px', xs: '1408px' },
          padding: { xs: '0' }
        }}
      >
        <Grid
          container
          spacing={2}
          flexWrap='nowrap'
        >
          <Grid item>
            <CustomerList />
          </Grid>
          <Grid item>
            <Profile />
          </Grid>
          <Grid item container direction='column' spacing={2}>
            <Grid item container spacing={2}>
              <Grid item>
                <FittingHistory />
              </Grid>
              <Grid item>
                <Audiogram />
              </Grid>
            </Grid>
            <Grid item>
              <Gain />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Customer
