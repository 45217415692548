const Chevron = props => {
  const { down } = props

  const transform = 'rotate(180deg)'

  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
      style={{ transform: down ? transform : 'none' }}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 11.821L14.8809 13L10 7.85799L5.11912 13L4 11.821L10 5.5L16 11.821Z'
      />
    </svg>
  )
}

export default Chevron
