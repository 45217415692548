const typographyOptions = {
  fontFamily: 'Inter',
  h1: {
    fontWeight: 600,
    fontSize: '28px',
    lineHeight: '28px',
    textTransform: 'capitalize'
  },
  h2: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '26px',
    textTransform: 'capitalize'
  },
  h3: {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: '22px',
    lineHeight: '26px',
    textTransform: 'capitalize'
  },
  h4: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '24px',
    textTransform: 'capitalize'
  },
  h5: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px'
  },
  tab1: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '22px',
    textTransform: 'capitalize'
  },
  tab2: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    textTransform: 'capitalize'
  },
  button1: {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '24px',
    textTransform: 'uppercase'
  },
  button2: {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '20px',
    textTransform: 'uppercase'
  },
  button3: {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '14px',
    textTransform: 'uppercase'
  },
  text1: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    textTransform: 'capitalize'
  },
  text2: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    textDecorationLine: 'underline',
    textTransform: 'capitalize'
  },
  body18Semi: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '22px'
  },
  body18Med: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '22px',
    letterSpacing: '0.5px'
  },
  body18Reg: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '22px',
    letterSpacing: '0.5px'
  },
  body16Semi: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.5px'
  },
  body16Med: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.5px'
  },
  body16Reg: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0px'
  },
  body14Semi: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.5px'
  },
  body14Med: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.5px'
  },
  body14Reg: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.5px'
  },
  body12Semi: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.5px'
  },
  body12Med: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.5px'
  },
  body10Semi: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '10px',
    lineHeight: '12px',
    letterSpacing: '0.5px'
  }
}

export default typographyOptions
