import React, { useEffect, useState } from 'react'
import { Box, Grid, styled, Typography } from '@mui/material'

import { OrkaAudiogramDisplayWc } from 'components/Audiogram'
import { useCustomerContext } from 'contexts/Customer'
import { getAudiogramDataHistory } from 'services/customers'
import { useTranslation } from 'react-i18next'

const Root = styled(Box)(({ theme }) => ({
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.03)',
  borderRadius: '12px',
  height: '296px',
  backgroundColor: theme.palette.white[0],
  '& .audiogram-chart': {
    margin: 'auto',
    '& > div': {
      width: '326px',
      height: '228px'
    }
  }

}))
const Audiogram = (props) => {
  const { containerWidth } = props
  const { selectedHistory } = useCustomerContext()
  const [acData, setAcData] = useState([])
  const [bcData, setBcData] = useState([])
  const [uclData, setUclData] = useState([])
  const [mclData, setMclData] = useState([])
  const { t } = useTranslation()

  useEffect(() => {
    if (selectedHistory) {
      const audiogramData = getAudiogramDataHistory(selectedHistory)
      setAcData(audiogramData[0])
      setBcData(audiogramData[1])
      setUclData(audiogramData[2])
      setMclData(audiogramData[3])
    }
  }, [selectedHistory])

  return (
    <Root
      sx={{
        width: containerWidth || {
          xs: '432px',
          xl: '632px'
        }
      }}
    >
      <Grid container justifyContent='center'>
        <Grid
          item
          container
          spacing={1}
          direction='column'
          sx={{ padding: '24px' }}
        >
          <Grid item>
            <Typography variant='h5'>{t('client_tab_audiogram_section_title')}</Typography>
          </Grid>
          <Grid item className='audiogram-chart'>
            <Box>
              <OrkaAudiogramDisplayWc
                acData={acData}
                bcData={bcData}
                uclData={uclData}
                mclData={mclData}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Root>
  )
}

export default Audiogram
