const Image = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M17 4H3L3 16H17V4ZM3 2C1.89543 2 1 2.89543 1 4V16C1 17.1046 1.89543 18 3 18H17C18.1046 18 19 17.1046 19 16V4C19 2.89543 18.1046 2 17 2H3Z' />
      <path d='M10.5 9L3 16H17V7.5L13.5 12L10.5 9Z' />
      <path d='M8.50003 7.49999C8.50003 8.60456 7.6046 9.49999 6.50003 9.49999C5.39546 9.49999 4.50003 8.60456 4.50003 7.49999C4.50003 6.39542 5.39546 5.49999 6.50003 5.49999C7.6046 5.49999 8.50003 6.39542 8.50003 7.49999Z' />
    </svg>
  )
}

export default Image
