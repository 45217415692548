import React from 'react'
import { Grid, Typography } from '@mui/material'
import FCDialog from 'components/Dialog'
import FCButton from 'components/Button'
import AppIcon from 'assets/imgs/AppIcon.svg'

export const SendCheckDialog = (props) => {
  const { open, setOpen, handleSend, sendGainTableLoading } = props
  return (
    <FCDialog open={open}>
      <Grid container direction='column' wrap='nowrap'>
        <Grid item className='dialog-img'>
          <img src={AppIcon} alt='' />
        </Grid>
        <Grid item className='dialog-title'>
          <Typography variant='h5'>Are you sure to send this data?</Typography>
        </Grid>
        <Grid item className='dialog-description'>
          <Typography variant='body16Reg'>
            Once you send, The client will receive a new Hearing Profile in his/her Orka Health app.
          </Typography>
        </Grid>
        <Grid item className='dialog-button'>
          <FCButton
            variant='outlined'
            size='medium'
            onClick={() => setOpen(false)}
          >
            cancel
          </FCButton>
          <FCButton
            variant='contained'
            size='medium'
            loading={sendGainTableLoading}
            onClick={() => handleSend()}
          >
            send
          </FCButton>
        </Grid>
      </Grid>
    </FCDialog>
  )
}
