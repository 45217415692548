import { createContext, useContext, useState } from 'react'
import moment from 'moment'

const AppointmentContext = createContext({})

const useAppointmentContext = () => {
  return useContext(AppointmentContext)
}

const useAppointmentStates = () => {
  const [remoteAppointments, setRemoteAppointments] = useState(null)
  const [remoteNewAppointment, setRemoteNewAppointment] = useState(null)
  const [selectedAppointments, setSelectedAppointments] = useState(null)
  const [nextAppointment, setNextAppointment] = useState(null)
  const [selectedDate, setSelectedDate] = useState(moment())
  const [currentAppointment, setCurrentAppointment] = useState(null)
  const [twilioRoom, setTwilioRoom] = useState(null)
  const [twilioToken, setTwilioToken] = useState(null)
  const [microsoftSpeechRecToken, setMicrosoftSpeechRecToken] = useState(null)
  return {
    remoteAppointments,
    setRemoteAppointments,
    remoteNewAppointment,
    setRemoteNewAppointment,
    selectedAppointments,
    setSelectedAppointments,
    nextAppointment,
    setNextAppointment,
    selectedDate,
    setSelectedDate,
    currentAppointment,
    setCurrentAppointment,
    twilioRoom,
    setTwilioRoom,
    twilioToken,
    setTwilioToken,
    microsoftSpeechRecToken,
    setMicrosoftSpeechRecToken
  }
}

export { AppointmentContext, useAppointmentContext, useAppointmentStates }
