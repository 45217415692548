import React from 'react'
import { ToggleButton, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const ToggleButtonBase = styled(ToggleButton)(({ theme }) => ({
  border: 'none',
  borderRadius: '4px',
  width: '48px',
  height: '22px',
  color: theme.palette.black[550],
  ':hover': {
    color: theme.palette.black[700],
    backgroundColor: theme.palette.gray[300]
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.brand[500],
    color: theme.palette.white[0],
    ':hover': {
      backgroundColor: theme.palette.brand[500],
      color: theme.palette.white[0]
    }
  }
}))

const FCToggleButton = (props) => {
  return (
    <ToggleButtonBase {...props}>
      <Typography variant='body14Semi'>{props.children}</Typography>
    </ToggleButtonBase>
  )
}

export default FCToggleButton
