const Combine = props => {
  const { split } = props

  return split
    ? (
      <svg
        width='26'
        height='20'
        viewBox='0 0 26 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M25 4H22C20.4087 4 18.8826 4.6321 17.7574 5.7574C16.6321 6.8826 16 8.4087 16 10C16 11.5913 16.6321 13.1174 17.7574 14.2426C18.8826 15.3679 20.4087 16 22 16H25M1 4H4C5.5913 4 7.1174 4.6321 8.2426 5.7574C9.3679 6.8826 10 8.4087 10 10C10 11.5913 9.3679 13.1174 8.2426 14.2426C7.1174 15.3679 5.5913 16 4 16H1'
          strokeWidth='2.5'
          strokeMiterlimit='10'
        />
      </svg>
      )
    : (
      <svg
        width='26'
        height='20'
        viewBox='0 0 26 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M11 4H8C6.4087 4 4.8826 4.6321 3.75736 5.7574C2.63214 6.8826 2 8.4087 2 10C2 11.5913 2.63214 13.1174 3.75736 14.2426C4.8826 15.3679 6.4087 16 8 16H11M15 4H18C19.5913 4 21.1174 4.6321 22.2426 5.7574C23.3679 6.8826 24 8.4087 24 10C24 11.5913 23.3679 13.1174 22.2426 14.2426C21.1174 15.3679 19.5913 16 18 16H15M8 10H18'
          strokeWidth='2.5'
          strokeMiterlimit='10'
        />
      </svg>
      )
}

export default Combine
