import client from './client'

export const getRoom = async roomName => {
  try {
    const res = await client().get('/console/fetch-room/' + roomName, {
      params: {
        is_audiologist: 'True'
      }
    })
    return res.data.room_id
  } catch (e) {
    console.log('Error: ', e)
  }
}

export const getTwilioToken = async (roomName, userName) => {
  try {
    const res = await client().get(
      '/console/audiologist-token/' + userName + '/' + roomName
    )
    return res.data.access_token
  } catch (e) {
    console.log('Error: ', e)
  }
}

export const getSpeechToken = async () => {
  try {
    const res = await client().get('/console/microsoft-speech-rec-token')
    return res.data.access_token
  } catch (e) {
    console.log('Error: ', e)
  }
}

export const getDialogues = async (audiologist, customer, startTime) => {
  try {
    const res = await client().get('/console/chat-history', {
      params: {
        room_name: audiologist + '_' + customer,
        start_time: startTime + 'Z',
        is_audiologist: 'True'
      }
    })
    return res.data
  } catch (e) {
    console.log('Error: ', e)
  }
}

export const generateGaintableV2 = async audiogramData => {
  try {
    const res = await client().post('/console/gaintablev2/', {
      data: audiogramData
    })

    return res.data
  } catch (e) {
    console.log('Error: ', e)
  }
}

export const generateGaintableV4 = async (audiogramData, domeType) => {
  try {
    const res = await client().post('/console/gaintablev4/', {
      data: audiogramData,
      dome: domeType
    })
    return res.data
  } catch (e) {
    console.log('Error: ', e)
  }
}

export const getOutputData = async (
  gainData
) => {
  try {
    const res = await client().post(
      '/console/gain-output/', {
        gain_data: gainData
      }, {
        params: {
          is_audiologist: 'True'
        }
      }
    )
    return res.data.gain_output
  } catch (e) {
    console.log('Error: ', e)
  }
}
