import client from './client'

export const getMedicalInfo = async userName => {
  try {
    const res = await client().get('/audiologists/getmedicalinfo', {
      params: {
        is_audiologist: 'True',
        username: userName
      }
    })
    return res.data
  } catch (e) {
    console.log('Error: ', e)
  }
}

export const postMedicalInfo = async (userName, medicalInfo) => {
  try {
    await client().post(
      '/audiologists/postmedicalinfo/', {
        chief_complaint: medicalInfo.chiefComplaint,
        hearing_aid_wear_experience: medicalInfo.experience,
        hearing_preference: medicalInfo.lifeStyle
      }, {
        params: {
          is_audiologist: 'True',
          username: userName
        }
      }
    )
  } catch (e) {
    console.log('Error: ', e)
  }
}

export const getConfirmUser = async input => {
  try {
    const res = await client().get('/audiologists/user', {
      params: {
        is_audiologist: 'True',
        user: input
      }
    })
    return res.data.is_exist
  } catch (e) {
    console.log('Error: ', e)
  }
}

export const getAllCustomers = async () => {
  try {
    const result = await client().get('/audiologists/customers')
    return result.data
  } catch (e) {
    console.error(e)
  }
}
