import React from 'react'
import { Button, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import Lottie from 'react-lottie'
import { LoadingButton } from '@mui/lab'

const ButtonBase = styled(LoadingButton)({
  gap: '3px',
  boxShadow: 'none',
  ':hover': {
    boxShadow: 'none'
  },
  minWidth: 0,
  '&.MuiButton-sizeLarge': {
    padding: '14px 32px',
    borderRadius: '26px'
  },
  '&.MuiButton-sizeMedium': {
    padding: '10px 28px',
    borderRadius: '26px'
  },
  '&.MuiButton-sizeSmall': {
    padding: '8px 20px',
    borderRadius: '26px'
  }
})

const ContainedButtonBase = styled(ButtonBase)(({ theme }) => ({
  color: theme.palette.white[0],
  fill: theme.palette.white[0],
  backgroundColor: theme.palette.black[700],
  ':hover': {
    backgroundColor: theme.palette.black[800]
  },
  ':active': {
    backgroundColor: theme.palette.black[900]
  },
  ':disabled': {
    color: theme.palette.white[0],
    backgroundColor: theme.palette.black[400]
  },
  '.MuiLoadingButton-loadingIndicator': {
    transform: 'translate(-50%) scale(1.5)'
  },
  '&.MuiButton-containedSizeAudiogram': {
    padding: '6px 24px',
    borderRadius: '24px',
    width: '72px',
    stroke: theme.palette.white[0]
  },
  '&.MuiButton-containedSizeFineTune': {
    padding: '6px 16px',
    borderRadius: '20px',
    width: '56px',
    stroke: theme.palette.white[0]
  },
  '&.MuiButton-containedSizeNavLeft': {
    padding: '10px 30px 10px 20px',
    borderRadius: '0px 20px 20px 0px'
  },
  '&.MuiButton-containedSizeNavRight': {
    padding: '10px 20px 10px 30px',
    borderRadius: '20px 0px 0px 20px'
  }
}))

const OutlinedButtonBase = styled(ButtonBase)(({ theme }) => ({
  color: theme.palette.black[700],
  fill: theme.palette.black[700],
  backgroundColor: theme.palette.white[0],
  border: `2px solid ${theme.palette.black[700]}`,
  boxSizing: 'border-box',
  ':hover': {
    color: theme.palette.black[900],
    fill: theme.palette.black[900],
    backgroundColor: theme.palette.black[50],
    border: `2px solid ${theme.palette.black[900]}`
  },
  ':disabled': {
    color: theme.palette.black[400],
    fill: theme.palette.black[400],
    border: `2px solid ${theme.palette.black[400]}`
  },
  '&.MuiButton-outlinedSizeLarge': {
    padding: '12px 30px'
  },
  '&.MuiButton-outlinedSizeMedium': {
    padding: '8px 26px'
  },
  '&.MuiButton-outlinedSizeSmall': {
    padding: '6.5px 18.5px',
    border: `1.5px solid ${theme.palette.black[700]}`,
    ':hover': {
      border: `1.5px solid ${theme.palette.black[900]}`
    },
    ':disabled': {
      border: `1.5px solid ${theme.palette.black[400]}`
    }
  },
  '&.MuiButton-outlinedSizeAudiogram': {
    padding: '4px 22px',
    borderRadius: '24px',
    width: '72px',
    stroke: theme.palette.black[700],
    border: `2px solid ${theme.palette.black[700]}`,
    ':hover': {
      border: `2px solid ${theme.palette.black[900]}`,
      stroke: theme.palette.black[900]
    },
    ':disabled': {
      border: `2px solid ${theme.palette.black[400]}`,
      stroke: theme.palette.black[400]
    }
  },
  '&.MuiButton-outlinedSizeFineTune': {
    padding: '4px 14px',
    borderRadius: '20px',
    width: '56px',
    stroke: theme.palette.black[700],
    ':hover': {
      border: `2px solid ${theme.palette.black[900]}`,
      stroke: theme.palette.black[900]
    },
    ':disabled': {
      border: `2px solid ${theme.palette.black[400]}`,
      stroke: theme.palette.black[400]
    }
  }
}))

const TextButtonBase = styled(ButtonBase)(({ theme }) => ({
  gap: 0,
  color: theme.palette.black[800],
  fill: theme.palette.black[800],
  ':hover': {
    textDecorationLine: 'underline',
    backgroundColor: 'transparent'
  },
  ':disabled': {
    color: theme.palette.black[550],
    fill: theme.palette.black[550]
  }
}))

const textStyleMap = {
  large: 'button1',
  medium: 'button2',
  small: 'button3',
  audiogram: 'button2',
  text: 'text1',
  navLeft: 'button2',
  navRight: 'button2'
}

const tickAnimation = {
  loop: false,
  autoplay: true,
  animationData: require('./tick.json'),
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid meet'
  }
}
const LottieBase = (props) => {
  if (!props.success) return
  return props.size === 'large'
    ? <Lottie
        options={tickAnimation}
        height='24px'
        isStopped={false}
        isPaused={false}
      />
    : <Lottie
        options={tickAnimation}
        height='14px'
        style={{ marginTop: '-3px' }}
        isStopped={false}
        isPaused={false}
      />
}

const FCButton = (props) => {
  const { variant } = props
  let StyledButton

  if (variant === 'contained') {
    StyledButton = ContainedButtonBase
  } else if (variant === 'outlined') {
    StyledButton = OutlinedButtonBase
  } else if (variant === 'text') {
    StyledButton = TextButtonBase
  } else {
    StyledButton = Button
  }

  return (
    <StyledButton {...props} disableRipple>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        {props.icon
          ? (
              props.children
            )
          : (
            <Typography variant={textStyleMap[props.size]} style={{ opacity: props.success ? '0' : '1' }}>
              {props.children}
            </Typography>
            )}
        <div style={{ position: 'absolute', transform: 'scale(2)' }}><LottieBase {...props} /></div>
      </div>
    </StyledButton>
  )
}

export default FCButton
