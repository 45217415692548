import React from 'react'
import { Radio } from '@mui/material'
import { styled } from '@mui/material/styles'

import RadioOutline from '../Icon/RadioOutline'
import RadioChecked from '../Icon/RadioChecked'

const RadioBase = styled(Radio)(({ theme }) => ({
  padding: 0,
  '&.Mui-disabled': {
    backgroundColor: theme.palette.gray[400],
    'svg circle': {
      stroke: theme.palette.gray[400]
    }
  }
}))

const FCRadio = (props) => {
  return (
    <RadioBase
      {...props}
      disableRipple
      icon={<RadioOutline />}
      checkedIcon={<RadioChecked />}
    />
  )
}

export default FCRadio
