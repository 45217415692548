import React, { useState, useEffect } from 'react'
import {
  Outlet,
  Routes,
  Route,
  useNavigate,
  useLocation
} from 'react-router-dom'
import { Grid, Box, Typography, useTheme } from '@mui/material'
import moment from 'moment'

import { FCTabs, FCTab } from 'components/Tab'
import FCButton from 'components/Button'
import Audiogram from './Audiogram'
import Customer from './Customer'
import DeviceInfo from './DeviceInfo'
import FineTuning from './FineTuning'
import Undo from 'components/Icon/Undo'
import { postNewAppointment } from 'services/apis/v1/appointment'
import { endClinicSession } from 'services/apis/v1/audiologist'
import { SessionEndDialog } from 'pages/sections/SessionEndDialog'
import { ProfileUnsavedDialog } from 'pages/sections/CustomerProfile/ProfileUnsavedDialog'
import { AudiogramUnsavedDialog } from 'pages/sections/AudiogramSection/AudiogramUnsavedDialog'
import { usePageContext } from 'contexts/Page'
import { CounterDownTimer } from 'pages/sections/CounterDownTimer'
import { useCustomerContext } from 'contexts/Customer'
import { useWebSocketFittingContext } from 'contexts/WebSocket'
import { useAudiologistContext } from 'contexts/Audiologist'
import { useDeviceContext } from 'contexts/Device'
import { useClinicFittingContext } from 'contexts/ClinicFitting'
import { useGaintableContext } from 'contexts/Gaintable'
import { getHistoricalGaintable } from 'services/apis/v1/gaintable'
import { useTranslation } from 'react-i18next'

const InClinicFitting = () => {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<Customer />} />
        <Route path='customer' element={<Customer />} />
        <Route path='audiogram' element={<Audiogram />} />
        <Route path='device' element={<DeviceInfo />} />
        <Route path='fine-tuning' element={<FineTuning />} />
      </Route>
    </Routes>
  )
}

const Layout = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const location = useLocation()
  const pages = ['customer', 'audiogram', 'device', 'fine-tuning']
  const currentPage = location.pathname.split('/')[2]
  const currentIndex = pages.indexOf(currentPage)
  const initialPage = currentIndex < 0 ? 0 : currentIndex
  const [page, setPage] = useState(null)
  const [tooltipDisplay, setTooltipDisplay] = useState('none')
  const [nextPage, setNextPage] = useState(null)
  const [sessionEndDialogOpen, setSessionEndDialogOpen] = useState(false)
  const [profileUnsavedOpen, setProfileUnsavedOpen] = useState(false)
  const [audiogramUnsavedOpen, setAudiogramUnsavedOpen] = useState(false)
  const [generateCodeLoading, setGenerateCodeLoading] = useState(false)
  const { unsavedPageType, setUnsavedPageType, PageType } = usePageContext()
  const {
    setGainTableEditHistory,
    setCurrentSession,
    setEditingHistoryIndex,
    setPreviousSession,
    setFittingData
  } = useGaintableContext()
  const {
    selectedCustomerEmail,
    setSelectedCustomerEmail,
    setSelectedCustomer,
    setSelectedHistory
  } = useCustomerContext()
  const { createWebSocketFitting } = useWebSocketFittingContext()
  const { audiologistInfo } = useAudiologistContext()
  const { setDeviceInfo } = useDeviceContext()
  const { setClinicFitting, code, setCode } = useClinicFittingContext()
  const { t } = useTranslation()

  const handleLeave = nextPage => {
    if (unsavedPageType === PageType.CustomerProfile) {
      setProfileUnsavedOpen(false)
    }
    if (unsavedPageType === PageType.Audiogram) {
      setAudiogramUnsavedOpen(false)
    }
    setUnsavedPageType(null)
    setPage(nextPage)
  }
  const { initialData } = useGaintableContext()
  const handleEndSession = async nextPage => {
    await endClinicSession(code)
    initialData()
    setSessionEndDialogOpen(false)
    setSelectedCustomerEmail(null)
    setSelectedCustomer(null)
    setSelectedHistory(null)
    setClinicFitting(null)
    setCode(null)
    setPage(nextPage)
  }

  useEffect(() => {
    if (page === 0) {
      navigate('customer')
    } else if (page === 1) {
      navigate('audiogram')
    } else if (page === 2) {
      navigate('device')
    } else if (page === 3) {
      navigate('fine-tuning')
    }
  }, [page])

  useEffect(() => {
    const recordNow = `InClinic ${moment().format('DD/MM/YY hh:mm:ss')}`
    const fetchGainTableInfo = async (email) => {
      const results = await getHistoricalGaintable(email)
      setCurrentSession([
        {
          record: recordNow,
          fittingNote: '',
          leftGainData: [],
          rightGainData: [],
          leftMPO: [],
          rightMPO: []
        }
      ])
      setFittingData(recordNow)
      setGainTableEditHistory([
        {
          leftGainData: JSON.parse(results[0].left_gaintable),
          rightGainData: JSON.parse(results[0].right_gaintable),
          leftMPO: JSON.parse(results[0].left_mpo),
          rightMPO: JSON.parse(results[0].right_mpo),
          fittingNote: results[0].note || ''
        }
      ])
      setEditingHistoryIndex(0)
    }
    const fetchGainTableHistory = async (email) => {
      const history = await getHistoricalGaintable(email)
      const previousSessionTemp = []
      if (history.length) {
        history.forEach((item, index) => {
          previousSessionTemp.push({
            record: `${item.source.split('_')[0]} ${moment(item.time).format('DD/MM/YY hh:mm:ss')}`,
            fittingNote: item.note || '',
            leftGainData: JSON.parse(item.left_gaintable),
            rightGainData: JSON.parse(item.right_gaintable),
            leftMPO: item.left_mpo ? JSON.parse(item.left_mpo) : '',
            rightMPO: item.right_mpo ? JSON.parse(item.right_mpo) : ''
          })
        })
        setPreviousSession(previousSessionTemp)
      }
    }
    if (selectedCustomerEmail) {
      createWebSocketFitting(audiologistInfo.username, selectedCustomerEmail)
      fetchGainTableInfo(selectedCustomerEmail)
      fetchGainTableHistory(selectedCustomerEmail)
    }
  }, [selectedCustomerEmail])

  const generateCode = async () => {
    setGenerateCodeLoading(true)
    const randomCode = Math.floor(Math.random() * 9000) + 999
    setSelectedCustomerEmail(null)
    setSelectedCustomer(null)
    setSelectedHistory(null)
    setDeviceInfo(null)
    await postNewAppointment(randomCode)
    setCode(randomCode)
    setGenerateCodeLoading(false)
  }

  useEffect(() => {
    const currentPage = location.pathname.split('/')[2]
    const currentIndex = pages.indexOf(currentPage)
    if (currentIndex >= 0) {
      setPage(currentIndex)
    }
  }, [location])

  return (
    <div>
      <Grid
        container
        justifyContent='space-between'
        sx={{
          boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.03)',
          backgroundColor: theme.palette.white[0],
          maxHeight: '40px'
        }}
      >
        <Grid item xs={3}>
          <Grid container alignItems='center'>
            <Grid item>
              <Box
                sx={{
                  display: tooltipDisplay,
                  position: 'absolute',
                  top: '112px',
                  left: '40px',
                  background: theme.palette.black[700],
                  padding: '6px 10px',
                  color: theme.palette.white[0],
                  width: '371px',
                  borderRadius: '6px',
                  boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)'
                }}
              >
                <Typography variant='body14Med'>
                  {t('get_code_tooltip1')}
                </Typography>
                <Typography variant='body14Semi'>
                  {t('get_code_tooltip2')}
                </Typography>
                <Typography variant='body14Med'>
                  {t('get_code_tooltip3')}
                </Typography>
              </Box>
              <FCButton
                variant='contained'
                size='navLeft'
                onMouseOver={() => setTooltipDisplay('block')}
                onMouseOut={() => setTooltipDisplay('none')}
                onClick={() => {
                  setTooltipDisplay('none')
                  generateCode()
                }}
                loading={generateCodeLoading}
                disabled={!!code}
              >
                {t('get_code_button')}
              </FCButton>
            </Grid>
            {code
              ? (
                <Grid
                  item
                  sx={{
                    marginLeft: '20px',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Typography
                    variant='body16Semi'
                    sx={{ color: theme.palette.brand[600] }}
                  >
                    {code}
                  </Typography>
                  <FCButton
                    variant='text'
                    startIcon={<Undo redo />}
                    sx={{ padding: '10px 12px !important' }}
                    onClick={() => generateCode()}
                  />
                </Grid>)
              : (
                <Grid />)}
          </Grid>
        </Grid>
        <Grid item>
          <FCTabs
            value={page || initialPage}
            onChange={(event, newValue) => {
              if ((page || initialPage) === 0 && !unsavedPageType) {
                setPage(newValue)
              } else {
                setNextPage(newValue)
                if (unsavedPageType === PageType.CustomerProfile) {
                  setProfileUnsavedOpen(true)
                } else if (unsavedPageType === PageType.Audiogram) {
                  setAudiogramUnsavedOpen(true)
                } else {
                  setPage(newValue)
                }
              }
            }}
            textColor='secondary'
            TabIndicatorProps={{
              children: <span className='MuiTabs-indicatorSpanNone' />
            }}
          >
            <FCTab label={t('console_appointment_tab_client')} />
            <FCTab label={t('audiogram_tab')} />
            <FCTab label={t('device_info_tab')} />
            <FCTab label={t('fine-tuning_tab')} />
          </FCTabs>
        </Grid>
        <Grid item xs={3}>
          <Grid container alignItems='center' justifyContent='flex-end'>
            {selectedCustomerEmail ? <CounterDownTimer /> : <></>}
            <Grid item justifyContent='flex-end'>
              <FCButton
                variant='contained'
                size='navRight'
                disabled={!selectedCustomerEmail}
                onClick={() => setSessionEndDialogOpen(true)}
              >
                {t('end_session_button')}
              </FCButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Outlet />
      <SessionEndDialog
        open={sessionEndDialogOpen}
        setOpen={setSessionEndDialogOpen}
        nextPage={nextPage}
        page={page}
        handleEndSession={handleEndSession}
      />
      <ProfileUnsavedDialog
        open={
          unsavedPageType === PageType.CustomerProfile && profileUnsavedOpen
        }
        setOpen={setProfileUnsavedOpen}
        nextPage={nextPage}
        handleLeave={handleLeave}
      />
      <AudiogramUnsavedDialog
        open={unsavedPageType === PageType.Audiogram && audiogramUnsavedOpen}
        setOpen={setAudiogramUnsavedOpen}
        nextPage={nextPage}
        handleLeave={handleLeave}
      />
    </div>
  )
}

export default InClinicFitting
