import React, { useEffect, useState } from 'react'
import { Typography, Grid, Box } from '@mui/material'
import { styled } from '@mui/material/styles'

import FCButton from 'components/Button/FCButton'
import { OrkaAudiogramDisplayWc } from 'components/Audiogram'
import OrkaDevice from './OrkaDevice'
import { FormulaSettingDialog } from './FormulaSettingDialog'
import { useCustomerContext } from 'contexts/Customer'
import { generateGainTable, handleAudiogramData } from 'services/customers'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGaintableContext } from 'contexts/Gaintable'
import { DeviceModel, FittingRange } from 'config'
import { useTranslation } from 'react-i18next'
import ErrorHintDialog from './ErrorHintDialog'
import { useGlobalContext } from 'contexts/Global'

const Root = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.white[0],
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.03)',
  borderRadius: '12px',
  minWidth: '1135px',
  [theme.breakpoints.up('xl')]: {
    minWidth: '1384px'
  },
  height: '720px',
  '& h5': {
    padding: '24px 0 16px 24px'
  },
  '& .audiogram-device-info': {
    width: '326px',
    height: '244px'
  }
}))

const DeviceContainer = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  marginTop: '16px',
  justifyContent: 'space-evenly'
}))

const ButtonGenerate = styled(Grid)(({ theme }) => ({
  marginTop: '70px',
  textAlign: 'center'
}))
const DeviceInfoSection = props => {
  const navigate = useNavigate()
  const location = useLocation()
  const currentPage = location.pathname.split('/')[1]
  const [open, setOpen] = useState(false)
  const [openErrorDialog, setOpenErrorDialog] = useState(false)
  const [leftDomeType, setLeftDomeType] = useState('')
  const [leftDomeSize, setLeftDomeSize] = useState('')
  const [rightDomeType, setRightDomeType] = useState('')
  const [rightDomeSize, setRightDomeSize] = useState('')
  const {
    setRightGainData,
    setLeftGainData,
    setLeftMPO,
    setRightMPO,
    deviceModel,
    leftAudiogramData,
    setLeftAudiogramData,
    rightAudiogramData,
    setRightAudiogramData,
    setLeftGainDataView,
    setRightGainDataView,
    setLeftGainDataOrigin,
    setRightGainDataOrigin,
    setGainTableEditHistory,
    setEditingHistoryIndex,
    previousSession,
    setPreviousSession,
    currentSession,
    setCurrentSession,
    setFittingData
  } = useGaintableContext()
  const { fittingType } = useGlobalContext()

  const { selectedCustomerEmail } = useCustomerContext()
  const [generateGainLoading, setGenerateGainLoading] = useState(false)
  const { t } = useTranslation()
  const handlegainGenerate = async version => {
    setGenerateGainLoading(true)
    setPreviousSession([...previousSession])
    await generateGainTable(
      version,
      leftAudiogramData,
      rightAudiogramData,
      leftDomeType,
      rightDomeType,
      setLeftGainData,
      setRightGainData,
      setLeftMPO,
      setRightMPO,
      setLeftGainDataView,
      setRightGainDataView,
      setLeftGainDataOrigin,
      setRightGainDataOrigin,
      setGainTableEditHistory,
      setEditingHistoryIndex,
      currentSession,
      setCurrentSession,
      setFittingData,
      fittingType
    )
    setGenerateGainLoading(false)
    setOpen(false)
    navigate('/' + currentPage + '/fine-tuning')
  }
  const handleCheckDomes = () => {
    if (!leftDomeType || !rightDomeType) {
      setOpenErrorDialog(true)
      return
    }
    setOpen(true)
  }
  useEffect(() => {
    const fetchInfo = async () => {
      const results = await handleAudiogramData(selectedCustomerEmail)
      setLeftAudiogramData(results.leftAudiogramData)
      setRightAudiogramData(results.rightAudiogramData)
    }

    if (selectedCustomerEmail) {
      fetchInfo()
    }
  }, [selectedCustomerEmail])

  return (
    <Root>
      <Box>
        <Typography variant='h5'>{t('device_info_tab_device_info_section_title')}</Typography>
      </Box>
      {selectedCustomerEmail
        ? (
          <Box>
            <DeviceContainer container spacing={2} wrap='nowrap'>
              <Grid item>
                <Box className='audiogram-device-info'>
                  <OrkaAudiogramDisplayWc
                    acData={[rightAudiogramData[0]]}
                    deviceInfo
                    fittingRange={
                      deviceModel === DeviceModel.OrkaOnePro
                        ? FittingRange.OrkaOnePro
                        : FittingRange.OrkaOne
                    }
                  />
                </Box>
              </Grid>
              <Grid item>
                <OrkaDevice
                  deviceModel={
                    deviceModel === DeviceModel.OrkaOnePro
                      ? 'Orka One Pro'
                      : 'Orka One'
                  }
                  leftDomeType={leftDomeType}
                  rightDomeType={rightDomeType}
                  leftDomeSize={leftDomeSize}
                  rightDomeSize={rightDomeSize}
                  setLeftDomeSize={setLeftDomeSize}
                  setRightDomeSize={setRightDomeSize}
                  setLeftDomeType={setLeftDomeType}
                  setRightDomeType={setRightDomeType}
                />
              </Grid>
              <Grid item>
                <Box className='audiogram-device-info'>
                  <OrkaAudiogramDisplayWc
                    acData={[leftAudiogramData[0]]}
                    isLeft
                    deviceInfo
                    fittingRange={
                      deviceModel === DeviceModel.OrkaOnePro
                        ? FittingRange.OrkaOnePro
                        : FittingRange.OrkaOne
                    }
                  />
                </Box>
              </Grid>
            </DeviceContainer>
            <ButtonGenerate>
              <FCButton
                variant='contained'
                size='large'
                onClick={handleCheckDomes}
              >
                {t('device_info_section_generate_gain_button')}
              </FCButton>
            </ButtonGenerate>
            <FormulaSettingDialog
              open={open}
              setOpen={setOpen}
              generateGainLoading={generateGainLoading}
              setGenerateGainLoading={setGenerateGainLoading}
              handlegainGenerate={handlegainGenerate}
            />
            <ErrorHintDialog open={openErrorDialog} setOpen={setOpenErrorDialog} />
          </Box>)
        : (
          <Box sx={{ position: 'relative', height: 'calc(100% - 64px)' }}>
            <Typography
              variant='h2'
              sx={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)'
              }}
            >
              {t('device_info_section_no_device_connected')}
            </Typography>
          </Box>)}
    </Root>
  )
}

export default DeviceInfoSection
