import React from 'react'
import { Box, Fade, Modal, styled } from '@mui/material'

const ModalBase = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '472px',
  background: theme.palette.white[0],
  borderRadius: '12px',
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.03)',
  '& .dialog-img': {
    textAlign: 'center',
    marginTop: '40px',
    marginBottom: '8px'
  },
  '& .dialog-title': {
    textAlign: 'center'
  },
  '& .dialog-description': {
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.black[600],
    margin: '8px 48px 0px 48px'
  },
  '& .dialog-button': {
    textAlign: 'center',
    marginBottom: '40px',
    '& > button': {
      width: '120px',
      marginTop: '40px'
    },
    '& > button:nth-of-type(1)': {
      marginRight: '80px'
    }
  }
}))

const FCDialog = (props) => {
  const { open } = props

  return (
    <Modal
      open={open}
      closeAfterTransition
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <ModalBase>
          {props.children}
        </ModalBase>
      </Fade>
    </Modal>
  )
}

export default FCDialog
