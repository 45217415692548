import React from 'react'
import moment from 'moment'
import { Box, Grid, styled, Typography } from '@mui/material'
import FCDialog from 'components/Dialog'
import FCButton from 'components/Button'
import GainIcon from 'assets/imgs/GainIcon.svg'
import { FCInput } from 'components/Input'
import { useTranslation } from 'react-i18next'
import { useGlobalContext } from 'contexts/Global'

const ConfirmModal = styled(Box)(({ theme }) => ({
  '& .in-clinic-date': {
    marginTop: '24px',
    marginLeft: '56px'
  },
  '& .fitting-record-lable': {
    marginLeft: '56px'
  },
  '& .fitting-record-box': {
    width: '360px',
    margin: '3px auto 2px',
    borderRadius: '6px'
  },
  '& .fitting-record-helper-text': {
    color: theme.palette.black[550],
    marginLeft: '56px'
  }
}))

export const FittingRecordDialog = props => {
  const { open, setOpen, setFittingNote, handleSaveNote, fittingNote } = props
  const { fittingType } = useGlobalContext()
  const { t } = useTranslation()
  return (
    <FCDialog open={open}>
      <ConfirmModal>
        <Grid container direction='column' wrap='nowrap'>
          <Grid item className='dialog-img'>
            <img src={GainIcon} alt='' />
          </Grid>
          <Grid item className='dialog-title'>
            <Typography variant='h5'>Add fitting record</Typography>
          </Grid>
          <Grid item className='dialog-description'>
            <Typography variant='body16Reg'>
              You can save multiple drafts of fitting data in the current
              session. The last sent data will be saved as the final fitting
              record of the session.
            </Typography>
          </Grid>
          <Grid item className='in-clinic-date'>
            <Typography variant='body14Med'>
              {`${fittingType} ${moment().format('DD/MM/YY hh:mm:ss')}`}
            </Typography>
          </Grid>
          <Grid item className='fitting-record-lable'>
            <Typography variant='body14Med'>Note</Typography>
          </Grid>
          <Grid item className='fitting-record-box'>
            <FCInput
              value={fittingNote}
              inputProps={{ maxLength: 24 }}
              onChange={e => {
                const note = e.target.value
                setFittingNote(note)
              }}
            />
          </Grid>
          <Grid item className='fitting-record-helper-text'>
            <Typography variant='body12Med'>
              no more than 24 characters
            </Typography>
          </Grid>
          <Grid item className='dialog-button'>
            <FCButton
              variant='outlined'
              size='medium'
              onClick={() => setOpen()}
            >
              cancel
            </FCButton>
            <FCButton
              variant='contained'
              size='medium'
              onClick={() => handleSaveNote()}
            >
              save
            </FCButton>
          </Grid>
        </Grid>
      </ConfirmModal>
    </FCDialog>
  )
}
