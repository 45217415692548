import client from './client'

export const getAudiologistInfo = async () => {
  try {
    const res = await client().get('/audiologists', {
      params: {
        is_audiologist: 'True'
      }
    })
    return res.data[0]
  } catch (e) {
    console.log('Error: ', e)
  }
}

export const getCalendar = async () => {
  try {
    const res = await client().get('/audiologists/getgooglecalendar', {
      params: {
        is_audiologist: 'True'
      }
    })
    return res.data
  } catch (e) {
    console.log('Error: ', e)
  }
}

export const endClinicSession = async code => {
  try {
    await client().patch(
      '/clinic/endappointment/',
      {},
      {
        params: {
          is_audiologist: 'True',
          code
        }
      }
    )
  } catch (e) {
    console.log('Error: ', e)
  }
}
