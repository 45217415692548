import React from 'react'
import { Grid, Container, Box } from '@mui/material'

import FittingHistory from 'pages/sections/CustomerProfile/FittingHistory'
import Profile from 'pages/sections/CustomerProfile/Profile'
import Audiogram from 'pages/sections/CustomerProfile/Audiogram'
import Gain from 'pages/sections/CustomerProfile/Gain'

const Customer = () => {
  return (
    <Box sx={{ marginTop: 'clamp(16px,calc((100vh - 826px)/2),48px)' }}>
      <Container
        fixed
        sx={{
          maxWidth: { xl: '1552px', xs: '1407px' },
          padding: { xs: '0' }
        }}
      >
        <Grid
          container
          spacing={2}
          flexWrap='nowrap'
        >
          <Grid item>
            <Profile containerWidth='456px' />
          </Grid>
          <Grid item container direction='column' spacing={2}>
            <Grid item container spacing={2}>
              <Grid item>
                <FittingHistory />
              </Grid>
              <Grid item>
                <Audiogram containerWidth={{
                  xs: '624px',
                  xl: '768px'
                }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Gain containerWidth={{
                xs: '936px',
                xl: '1080px'
              }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Customer
