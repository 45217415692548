import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { Box, Grid, styled, Typography } from '@mui/material'
import { FCMenuItem } from 'components/Menu'
import { useCustomerContext } from 'contexts/Customer'
import { getCustomerHistory } from 'services/customers'
import { useTheme } from '@emotion/react'
import { useTranslation } from 'react-i18next'

const Root = styled(Box)(({ theme }) => ({
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.03)',
  borderRadius: '12px',
  width: '296px',
  maxHeight: '296px',
  backgroundColor: theme.palette.white[0],
  overflow: 'hidden',
  '& .fitting-history-time': {
    color: theme.palette.black[550],
    '& > div': {
      display: 'flex'
    }
  },
  '& .fitting-history-content': {
    height: '200px',
    '& > li': {
      margin: '0 8px 2px 0',
      padding: '10px 8px'
    }
  }
}))
const FittingHistory = () => {
  const { selectedCustomer } = useCustomerContext()
  const [history, setHistory] = useState(null)
  const { selectedHistory, setSelectedHistory } = useCustomerContext()
  const theme = useTheme()
  const { t } = useTranslation()

  useEffect(() => {
    const fetchInfo = async () => {
      setHistory(null)
      const result = await getCustomerHistory(selectedCustomer.email)
      setHistory(result)
      if (result) {
        setSelectedHistory(result[0])
      }
    }
    if (selectedCustomer) {
      fetchInfo()
    }
    if (selectedCustomer === null) {
      setHistory(null)
    }
  }, [selectedCustomer])

  return (
    <Root>
      <Grid container justifyContent='center'>
        <Grid
          item
          container
          spacing={3}
          direction='column'
          sx={{ padding: '24px' }}
        >
          <Grid item>
            <Typography variant='h5'>{t('client_tab_fitting_history_section_title')}</Typography>
          </Grid>
          <Grid item>
            <Box overflow='auto'>
              <Box className='fitting-history-content'>
                {history &&
                  history.map((item, index) => (
                    <FCMenuItem
                      key={index}
                      onClick={() => {
                        setSelectedHistory(item)
                      }}
                      sx={{
                        backgroundColor: item === selectedHistory || (!selectedHistory && index === 0) ? theme.palette.gray[300] : '',
                        ':hover': { backgroundColor: item === selectedHistory || (!selectedHistory && index === 0) ? theme.palette.gray[300] : '' }
                      }}
                    >
                      <Grid container alignItems='center'>
                        <Grid item xs={6}>
                          <Typography variant='body14Med' sx={{ textTransform: 'capitalize' }}>
                            {item.type
                              .split('_')[0] === 'review'
                              ? 'backend'
                              : (item.type
                                  .split('_')[0] === 'auto'
                                  ? 'hearing test'
                                  : item.type
                                    .split('_')[0])}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          xs
                          alignItems='flex-end'
                          direction='column'
                          className='fitting-history-time'
                        >
                          <Grid item>
                            <Typography variant='body14Med'>
                              {' '}
                              {moment(item.start_time).format('MM/DD/YYYY')}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant='body12Med'>
                              {' '}
                              {moment(item.start_time).format('HH:mm')}-
                              {moment(item.end_time).format('HH:mm')}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </FCMenuItem>
                  ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Root>
  )
}

export default FittingHistory
