import { createTheme } from '@mui/material'
import palette from './palette'
import typographyOptions from './typography'

const fittingConsoleTheme = createTheme({
  palette,
  typography: typographyOptions,
  components: {
    MuiMenu: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            marginTop: '-5px'
          },
          '& .MuiMenuItem-root': {
            ...typographyOptions.body14Med
          },
          '& .MuiMenu-list': {
            padding: '4px 0 0 0'
          }
        }
      }
    }
  }
})

export default fittingConsoleTheme
