import React, { useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import theme from 'styles/theme'
import { GainBand } from 'config'

// style of table with hover & focus effects
const TableCellBase = styled(TableCell)(({ theme }) => ({
  ...theme.typography.body12Semi,
  color: theme.palette.black[800],
  textAlign: 'center',
  boxSizing: 'border-box',
  border: `1px solid ${theme.palette.gray[500]}`,
  padding: '6.4px 0px',
  width: '46px',
  userSelect: 'none'
}))

const TitleCellBase = styled(TableCellBase)(({ theme }) => ({
  background: theme.palette.gray[400],
  '&:hover': {
    background: theme.palette.gray[500],
    cursor: 'pointer'
  }
}))

const NumberCellBase = styled(TableCellBase)(({ theme }) => ({
  background: theme.palette.white[0],
  '&:hover': {
    background: theme.palette.gray[200],
    cursor: 'pointer'
  }
}))

// style of customer table display only
const TableCellDisplay = styled(TableCell)(({ theme }) => ({
  ...theme.typography.body12Semi,
  color: theme.palette.black[600],
  textAlign: 'center',
  boxSizing: 'border-box',
  border: `1px solid ${theme.palette.gray[500]}`,
  padding: '4.4px 0px',
  width: '38px'
}))

const TitleCellDisplay = styled(TableCellDisplay)(({ theme }) => ({
  background: theme.palette.gray[400]
}))

const NumberCellDisplay = styled(TableCellDisplay)(({ theme }) => ({
  background: theme.palette.white[0]
}))

const FCTable = props => {
  const {
    size,
    mpo,
    gainTable,
    setSelectedItem,
    setSelectedMpoCell,
    displayOnly,
    highlightGainCell,
    setHighlightGainCell,
    highlightMpoCell,
    setHighlightMpoCell,
    band = 8
  } = props

  const { t } = useTranslation()

  const Band8ToBand3Converter = data => {
    if (data) {
      const convertedData = []
      convertedData.push(Math.round((data[1] + data[2]) / 2))
      convertedData.push(data[3])
      convertedData.push(Math.round((data[6] + data[7]) / 2))
      return convertedData
    }
    return new Array(3).fill(0)
  }

  const TABLE_HEADERS = GainBand[band]
  const g80 =
    band === 3
      ? Band8ToBand3Converter(gainTable[2])
      : gainTable[2]
        ? gainTable[2]
        : new Array(8).fill(0)
  const g65 =
    band === 3
      ? Band8ToBand3Converter(gainTable[1])
      : gainTable[1]
        ? gainTable[1]
        : new Array(8).fill(0)
  const g50 =
    band === 3
      ? Band8ToBand3Converter(gainTable[0])
      : gainTable[0]
        ? gainTable[0]
        : new Array(8).fill(0)

  const convertedMpo =
    band === 3
      ? mpo && mpo.length > 0
        ? Band8ToBand3Converter(mpo)
        : null
      : mpo

  const [mouseIndex, setMouseIndex] = useState(null)

  // judge the position of mouseDown and update the selectedItem
  const handleMouseDownTable = e => {
    e.stopPropagation()
    const selected = new Array(4).fill(0).map(() => new Array(9).fill(0))
    const highlight = new Array(4)
      .fill(0)
      .map(() => new Array(band + 1).fill(0))
    const row = Math.floor(e.target.id / 10)
    const column = e.target.id % 10

    highlight[row][column] = 1

    if (band === 3) {
      if (column === 1) {
        for (let i = 1; i < 4; i++) {
          selected[row][i] = 1
        }
      } else if (column === 2) {
        for (let i = 3; i < 6; i++) {
          selected[row][i] = 1
        }
      } else if (column === 3) {
        for (let i = 6; i < 9; i++) {
          selected[row][i] = 1
        }
      }
    } else {
      selected[row][column] = 1
    }

    // check selceted item again
    if (column === 0) {
      if (row === 0) {
        for (let i = 0; i < 4; i++) {
          selected[i].fill(1)
          highlight[i].fill(1)
        }
      } else {
        selected[row].fill(1)
        highlight[row].fill(1)
      }
    }
    if (row === 0 && column > 0) {
      for (let i = 0; i < 4; i++) {
        if (band === 3) {
          if (column === 1) {
            for (let j = 1; j < 4; j++) {
              selected[i][j] = 1
            }
          } else if (column === 2) {
            for (let j = 3; j < 6; j++) {
              selected[i][j] = 1
            }
          } else if (column === 3) {
            for (let j = 6; j < 9; j++) {
              selected[i][j] = 1
            }
          }
        } else if (band === 8) {
          selected[i][column] = 1
        }
        highlight[i][column] = 1
      }
    }

    setMouseIndex([row, column])
    setSelectedItem(selected)
    setHighlightGainCell(highlight)
  }

  // judge the position of mouseOver and update the selectedItem
  const handleMouseOverTable = e => {
    e.stopPropagation()
    if (mouseIndex) {
      const selected = new Array(4).fill(0).map(() => new Array(9).fill(0))
      const highlight = new Array(4)
        .fill(0)
        .map(() => new Array(band + 1).fill(0))
      const row = Math.floor(e.target.id / 10)
      const column = e.target.id % 10

      if (mouseIndex[0] < row) {
        if (mouseIndex[1] < column) {
          for (let i = mouseIndex[0]; i <= row; i++) {
            for (let j = mouseIndex[1]; j <= column; j++) {
              highlight[i][j] = 1
            }
          }
        } else {
          for (let i = mouseIndex[0]; i <= row; i++) {
            for (let j = column; j <= mouseIndex[1]; j++) {
              highlight[i][j] = 1
            }
          }
        }
      } else {
        if (mouseIndex[1] < column) {
          for (let i = row; i <= mouseIndex[0]; i++) {
            for (let j = mouseIndex[1]; j <= column; j++) {
              highlight[i][j] = 1
            }
          }
        } else {
          for (let i = row; i <= mouseIndex[0]; i++) {
            for (let j = column; j <= mouseIndex[1]; j++) {
              highlight[i][j] = 1
            }
          }
        }
      }

      // check selceted item again
      for (let i = 0; i < 4; i++) {
        if (highlight[0][0]) {
          for (let j = 0; j < 4; j++) {
            highlight[j].fill(1)
          }
          break
        }
        if (highlight[i][0]) {
          highlight[i].fill(1)
        }
        for (let j = 0; j < band + 1; j++) {
          if (highlight[0][j]) {
            highlight[i][j] = 1
          }
        }
      }

      if (band === 3) {
        for (let i = 1; i < 4; i++) {
          for (let j = 1; j < 4; j++) {
            if (highlight[i][j] === 1) {
              if (j === 1) {
                for (let k = 1; k < 4; k++) {
                  selected[i][k] = 1
                }
              } else if (j === 2) {
                for (let k = 3; k < 6; k++) {
                  selected[i][k] = 1
                }
              } else if (j === 3) {
                for (let k = 6; k < 9; k++) {
                  selected[i][k] = 1
                }
              }
            }
          }
        }
        setSelectedItem(selected)
      } else if (band === 8) {
        setSelectedItem(highlight)
      }
      setHighlightGainCell(highlight)
    }
  }

  const handleMouseDownMpo = e => {
    e.stopPropagation()
    const selected = new Array(9).fill(0)
    const highlight = new Array(band + 1).fill(0)
    const column = e.target.id.substring(3) % 10

    highlight[column] = 1

    if (band === 3) {
      if (column === 1) {
        for (let i = 1; i < 4; i++) {
          selected[i] = 1
        }
      } else if (column === 2) {
        for (let i = 3; i < 6; i++) {
          selected[i] = 1
        }
      } else if (column === 3) {
        for (let i = 6; i < 9; i++) {
          selected[i] = 1
        }
      }
    } else {
      selected[column] = 1
    }

    // check selceted item again
    if (column === 0) {
      selected.fill(1)
      highlight.fill(1)
    }

    setHighlightMpoCell(highlight)
    setMouseIndex([column])
    setSelectedMpoCell(selected)
  }

  const handleMouseOverMpo = e => {
    e.stopPropagation()
    if (mouseIndex) {
      const selected = new Array(9).fill(0)
      const highlight = new Array(band + 1).fill(0)
      const column = e.target.id.substring(3) % 10

      if (mouseIndex[0] < column) {
        for (let j = mouseIndex[0]; j <= column; j++) {
          highlight[j] = 1
        }
      } else {
        for (let j = column; j <= mouseIndex[0]; j++) {
          highlight[j] = 1
        }
      }

      if (band === 3) {
        for (let i = 1; i < 4; i++) {
          if (highlight[i] === 1) {
            if (i === 1) {
              for (let k = 1; k < 4; k++) {
                selected[k] = 1
              }
            } else if (i === 2) {
              for (let k = 3; k < 6; k++) {
                selected[k] = 1
              }
            } else if (i === 3) {
              for (let k = 6; k < 9; k++) {
                selected[k] = 1
              }
            }
          }
        }
        setSelectedMpoCell(selected)
      } else {
        setSelectedMpoCell(highlight)
      }
      setHighlightMpoCell(highlight)
    }
  }

  // when mouseUp, clear the position of mouse
  const handleMouseUpTable = e => {
    setMouseIndex(null)
  }

  return displayOnly
    ? (
      <div style={{ width: '342px', height: '96px' }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TitleCellDisplay id={0}>Input</TitleCellDisplay>
                {TABLE_HEADERS.map((item, index) => (
                  <TitleCellDisplay key={index} id={index + 1}>
                    {item}
                  </TitleCellDisplay>
                ))}
              </TableRow>
              <TableRow>
                <TitleCellDisplay id={10}>G80</TitleCellDisplay>
                {g80.map((item, index) => (
                  <NumberCellDisplay key={index} id={index + 11}>
                    {item}
                  </NumberCellDisplay>
                ))}
              </TableRow>
              <TableRow>
                <TitleCellDisplay id={20}>G65</TitleCellDisplay>
                {g65.map((item, index) => (
                  <NumberCellDisplay key={index} id={index + 21}>
                    {item}
                  </NumberCellDisplay>
                ))}
              </TableRow>
              <TableRow>
                <TitleCellDisplay id={30}>G50</TitleCellDisplay>
                {g50.map((item, index) => (
                  <NumberCellDisplay key={index} id={index + 31}>
                    {item}
                  </NumberCellDisplay>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>)
    : (
      <div style={{ width: size === 'big' ? '486px' : '414px', height: '140px' }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                {convertedMpo && convertedMpo.length
                  ? (
                    <TitleCellBase
                      id='mpo0'
                      onMouseDown={handleMouseDownMpo}
                      onMouseOver={handleMouseOverMpo}
                      onMouseUp={handleMouseUpTable}
                      style={{
                        backgroundColor: highlightMpoCell[0]
                          ? theme.palette.gray[500]
                          : null
                      }}
                    >
                      MPO
                    </TitleCellBase>)
                  : null}
                {convertedMpo &&
                convertedMpo.map((item, index) => (
                  <NumberCellBase
                    key={index}
                    id={`mpo${index + 1}`}
                    onMouseDown={handleMouseDownMpo}
                    onMouseOver={handleMouseOverMpo}
                    onMouseUp={handleMouseUpTable}
                    style={{
                      backgroundColor: highlightMpoCell[index + 1]
                        ? theme.palette.gray[500]
                        : null
                    }}
                  >
                    {item}
                  </NumberCellBase>
                ))}
              </TableRow>
              <TableRow>
                <TitleCellBase
                  id={0}
                  onMouseDown={handleMouseDownTable}
                  onMouseOver={handleMouseOverTable}
                  onMouseUp={handleMouseUpTable}
                  style={{
                    backgroundColor: highlightGainCell[0][0]
                      ? theme.palette.gray[500]
                      : null
                  }}
                >
                  {t('fine-tuning_gain_table_title_input')}
                </TitleCellBase>
                {TABLE_HEADERS.map((item, index) => (
                  <TitleCellBase
                    key={index}
                    id={index + 1}
                    onMouseDown={handleMouseDownTable}
                    onMouseOver={handleMouseOverTable}
                    onMouseUp={handleMouseUpTable}
                    style={{
                      backgroundColor: highlightGainCell[0][index + 1]
                        ? theme.palette.gray[500]
                        : null,
                      width:
                      band === 3 ? (size === 'big' ? '144px' : '100px') : null
                    }}
                  >
                    {band === 3 ? t(item) : item}
                  </TitleCellBase>
                ))}
              </TableRow>
              <TableRow>
                <TitleCellBase
                  id={10}
                  onMouseDown={handleMouseDownTable}
                  onMouseOver={handleMouseOverTable}
                  onMouseUp={handleMouseUpTable}
                  style={{
                    backgroundColor: highlightGainCell[1][0]
                      ? theme.palette.gray[500]
                      : null
                  }}
                >
                  G80
                </TitleCellBase>
                {g80.map((item, index) => (
                  <NumberCellBase
                    key={index}
                    id={index + 11}
                    onMouseDown={handleMouseDownTable}
                    onMouseOver={handleMouseOverTable}
                    onMouseUp={handleMouseUpTable}
                    style={{
                      backgroundColor: highlightGainCell[1][index + 1]
                        ? theme.palette.gray[500]
                        : null
                    }}
                  >
                    {item}
                  </NumberCellBase>
                ))}
              </TableRow>
              <TableRow>
                <TitleCellBase
                  id={20}
                  onMouseDown={handleMouseDownTable}
                  onMouseOver={handleMouseOverTable}
                  onMouseUp={handleMouseUpTable}
                  style={{
                    backgroundColor: highlightGainCell[2][0]
                      ? theme.palette.gray[500]
                      : null
                  }}
                >
                  G65
                </TitleCellBase>
                {g65.map((item, index) => (
                  <NumberCellBase
                    key={index}
                    id={index + 21}
                    onMouseDown={handleMouseDownTable}
                    onMouseOver={handleMouseOverTable}
                    onMouseUp={handleMouseUpTable}
                    style={{
                      backgroundColor: highlightGainCell[2][index + 1]
                        ? theme.palette.gray[500]
                        : null
                    }}
                  >
                    {item}
                  </NumberCellBase>
                ))}
              </TableRow>
              <TableRow>
                <TitleCellBase
                  id={30}
                  onMouseDown={handleMouseDownTable}
                  onMouseOver={handleMouseOverTable}
                  onMouseUp={handleMouseUpTable}
                  style={{
                    backgroundColor: highlightGainCell[3][0]
                      ? theme.palette.gray[500]
                      : null
                  }}
                >
                  G50
                </TitleCellBase>
                {g50.map((item, index) => (
                  <NumberCellBase
                    key={index}
                    id={index + 31}
                    onMouseDown={handleMouseDownTable}
                    onMouseOver={handleMouseOverTable}
                    onMouseUp={handleMouseUpTable}
                    style={{
                      backgroundColor: highlightGainCell[3][index + 1]
                        ? theme.palette.gray[500]
                        : null
                    }}
                  >
                    {item}
                  </NumberCellBase>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>)
}

export default FCTable
