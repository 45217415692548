import React from 'react'
import { Checkbox } from '@mui/material'
import { styled } from '@mui/material/styles'
import CheckboxOutline from '../Icon/CheckboxOutline'
import Checked from '../Icon/Checked'

const CheckboxBase = styled(Checkbox)(({ theme }) => ({
  padding: 0,
  borderRadius: '4px',
  '&.Mui-disabled': {
    backgroundColor: theme.palette.gray[400],
    'svg rect': {
      stroke: theme.palette.gray[400]
    }
  }
}))

const FCCheckbox = (props) => {
  return (
    <CheckboxBase
      {...props}
      disableRipple
      icon={<CheckboxOutline />}
      checkedIcon={<Checked />}
    />
  )
}

export default FCCheckbox
