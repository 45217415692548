const Eye = props => {
  const { on } = props

  return on
    ? (
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M10.0001 14.6742C11.919 14.6742 13.4746 13.1186 13.4746 11.1997C13.4746 9.28076 11.919 7.72515 10.0001 7.72515C8.08117 7.72515 6.52556 9.28076 6.52556 11.1997C6.52556 13.1186 8.08117 14.6742 10.0001 14.6742Z'
          fill='#1A1A1A'
        />
        <path
          d='M1.36369 12.2004C1.5306 12.4728 4.43642 17.0135 9.96697 17.0317C15.5397 17.0499 18.4786 12.4571 18.6364 12.2004'
          stroke='#1A1A1A'
          strokeWidth='1.5'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M17.048 4.98696L15.7244 6.77387'
          stroke='#FFA90C'
          strokeWidth='1.5'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M1.36369 10.1986C1.5306 9.92734 4.43642 5.38552 9.96697 5.36734C15.5397 5.34916 18.4786 9.94189 18.6364 10.1986'
          stroke='#1A1A1A'
          strokeWidth='1.5'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M3.31609 4.98696L4.63972 6.77387'
          stroke='#1A1A1A'
          strokeWidth='1.5'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M10.0001 4.98697V2.96842'
          stroke='#1A1A1A'
          strokeWidth='1.5'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
      </svg>
      )
    : (
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M1.4185 7.81346C1.58432 7.54364 4.47305 3.03091 9.96759 3.01346C15.505 2.99528 18.425 7.55891 18.5821 7.81346'
          stroke='#1A1A1A'
          strokeWidth='1.5'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M17.0039 14.9815L15.6886 13.2058'
          stroke='#FFA90C'
          strokeWidth='1.5'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M1.4184 9.80291C1.58421 10.0724 4.47294 14.5855 9.96749 14.6029C15.5049 14.6211 18.4249 10.0575 18.582 9.80291'
          stroke='#1A1A1A'
          strokeWidth='1.5'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M3.35844 14.9815L4.67372 13.2058'
          stroke='#1A1A1A'
          strokeWidth='1.5'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M10.0002 14.9815V16.9873'
          stroke='#1A1A1A'
          strokeWidth='1.5'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
      </svg>
      )
}

export default Eye
