const Undo = props => {
  const { redo } = props
  const transform = 'rotateY(180deg)'

  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
      style={{ transform: redo ? transform : 'none' }}
    >
      <path d='M3.96566 4.59425C3.725 4.92971 3.54674 4.9968 3.54674 4.70926V4.18211C3.57983 3.81987 3.47775 3.45833 3.26296 3.17703C3.04816 2.89572 2.73824 2.7177 2.40138 2.68211C2.06451 2.64652 1.7283 2.75629 1.4667 2.98726C1.20509 3.21824 1.03954 3.5515 1.00644 3.91374C0.997853 3.99014 0.997853 4.06737 1.00644 4.14377V8.83067C1.00644 9.19418 1.14073 9.5428 1.37976 9.79983C1.6188 10.0569 1.943 10.2013 2.28105 10.2013H6.63966C6.9777 10.2013 7.3019 10.0569 7.54094 9.79983C7.77997 9.5428 7.91426 9.19418 7.91426 8.83067C7.91664 8.65078 7.88559 8.47219 7.82294 8.30537C7.76028 8.13856 7.66728 7.98687 7.5494 7.85921C7.43151 7.73155 7.29111 7.63049 7.13642 7.56195C6.98173 7.49341 6.81587 7.45877 6.64857 7.46006H6.16725C5.90877 7.46006 5.83746 7.14377 6.06029 6.77955C6.57898 5.92846 7.29432 5.23683 8.13629 4.77236C8.97825 4.3079 9.91795 4.08654 10.8634 4.12995C11.8088 4.17337 12.7275 4.48008 13.5296 5.02004C14.3317 5.56001 14.9896 6.31471 15.4389 7.21025C15.8882 8.10579 16.1135 9.11147 16.0928 10.1288C16.0721 11.1461 15.806 12.1402 15.3206 13.0137C14.8352 13.8872 14.1472 14.6102 13.3239 15.1118C12.5005 15.6135 11.5702 15.8766 10.6239 15.8754C10.2386 15.8754 9.86904 16.04 9.59657 16.333C9.32411 16.626 9.17104 17.0233 9.17104 17.4377C9.17104 17.852 9.32411 18.2494 9.59657 18.5424C9.86904 18.8354 10.2386 19 10.6239 19C12.0487 19.0012 13.4501 18.6112 14.6955 17.8671C15.9409 17.123 16.9891 16.0493 17.7407 14.7478C18.4923 13.4462 18.9225 11.9599 18.9905 10.4296C19.0585 8.89926 18.762 7.37561 18.1293 6.00293C17.4965 4.63025 16.5484 3.45398 15.3746 2.58555C14.2009 1.71711 12.8404 1.18526 11.422 1.04035C10.0036 0.895443 8.57436 1.14229 7.26952 1.7575C5.96468 2.37271 4.82749 3.33592 3.96566 4.55591V4.59425Z' />
    </svg>
  )
}

export default Undo
