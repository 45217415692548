import React, { useEffect, useRef, useState } from 'react'
import { Typography, Grid, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import FCButton from 'components/Button/FCButton'
import FineTuningControl from 'pages/sections/FineTuningSection/FineTuningControl'
import OrkaGainChartWc from 'components/GainChart'
import OrkaOutputChartWc from 'components/OutputChart'
import FCTable from 'components/Table'
import FCFab from 'components/Fab'
import { Undo } from 'components/Icon'
import { SendCheckDialog } from './SendCheckDialog'
import { useCustomerContext } from 'contexts/Customer'
import {
  postGaintableBackend,
  postReviewGaintable
} from 'services/apis/v1/gaintable'
import { useGaintableContext } from 'contexts/Gaintable'
import { Threshold, OutputThreshold, DeviceModel, AfcThreshold } from 'config'
import { useWebSocketFittingContext } from 'contexts/WebSocket'
import { useDeviceContext } from 'contexts/Device'
import { fetchDeviceInfo, handleAudiogramData } from 'services/customers'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FCToggleButton, FCToggleButtonGroup } from 'components/ToggleButton'

const Root = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.white[0],
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.03)',
  borderRadius: '12px',
  minWidth: '1135px',
  [theme.breakpoints.up('xl')]: {
    minWidth: '1384px'
  },
  height: '720px',
  '& h5': {
    padding: '24px 0 16px 24px'
  },
  '& .gainChart-medium-right': {
    width: '448px',
    height: '328px'
  },
  '& .gainChart-medium-left': {
    width: '448px',
    height: '328px'
  },
  '& .gain-table': {
    marginLeft: '20px',
    marginTop: '24px'
  }
}))

const InputContainer = styled(Grid)({
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '16px'
})

const ButtonContainer = styled(Grid)(({ theme }) => ({
  marginTop: '16px',
  justifyContent: 'center',
  '& > div: nth-of-type(2)': {
    margin: '0 16px'
  }
}))

const FineTuningSection = props => {
  const { isRemote } = props
  const [combine, setCombine] = useState(true)
  const GraphType = { GAIN: 'gain', OUTPUT: 'output' }
  const [graphType, setGraphType] = useState(GraphType.GAIN)
  const [open, setOpen] = useState(false)
  const [fittingNote, setFittingNote] = useState('')
  const [sendGainTableLoading, setSendGainTableLoading] = useState(false)

  const { selectedCustomerEmail } = useCustomerContext()
  const { wsFitting } = useWebSocketFittingContext()
  const { setDeviceInfo } = useDeviceContext()
  const rightGainTableRef = useRef(null)
  const leftGainTableRef = useRef(null)
  const middleControlRef = useRef(null)

  const { t, i18n } = useTranslation()

  const {
    rightGainData,
    setRightGainData,
    leftGainData,
    setLeftGainData,
    leftMPO,
    setLeftMPO,
    rightMPO,
    setRightMPO,
    deviceModel,
    gainTableEditHistory,
    setGainTableEditHistory,
    rightOutputData,
    setRightOutputData,
    leftOutputData,
    setLeftOutputData,
    leftGainDataView,
    setLeftGainDataView,
    rightGainDataView,
    setRightGainDataView,
    leftGainDataOrigin,
    setLeftGainDataOrigin,
    rightGainDataOrigin,
    setRightGainDataOrigin,
    currentSession,
    setCurrentSession,
    editingHistoryIndex,
    setEditingHistoryIndex,
    previousSession,
    setPreviousSession,
    gainLevel,
    setGainLevel,
    fittingData,
    setFittingData
  } = useGaintableContext()

  const location = useLocation()
  const currentPage = location.pathname.split('/')[1]
  const [selectedRightItem, setSelectedRightItem] = useState(
    new Array(4).fill(0).map(() => new Array(9).fill(0))
  )
  const [selectedLeftItem, setSelectedLeftItem] = useState(
    new Array(4).fill(0).map(() => new Array(9).fill(0))
  )

  const [selectedLeftMpoCell, setSelectedLeftMpoCell] = useState(
    new Array(9).fill(0)
  )
  const [selectedRightMpoCell, setSelectedRightMpoCell] = useState(
    new Array(9).fill(0)
  )

  const [modifyTableDisabled, setModifyTableDisabled] = useState(false)

  const [band, setBand] = useState(8)
  const [highlightGainCellRight, setHighlightGainCellRight] = useState(
    new Array(4).fill(0).map(() => new Array(band + 1).fill(0))
  )
  const [highlightGainCellLeft, setHighlightGainCellLeft] = useState(
    new Array(4).fill(0).map(() => new Array(band + 1).fill(0))
  )
  const [highlightMpoCellRight, setHighlightMpoCellRight] = useState(
    Array(band + 1).fill(0)
  )
  const [highlightMpoCellLeft, setHighlightMpoCellLeft] = useState(
    Array(band + 1).fill(0)
  )

  const handleGainLevelChange = (level) => {
    // deep copy
    const resultLeft = structuredClone(leftGainData)
    const resultRight = structuredClone(rightGainData)
    resultLeft.forEach(
      (row, rowIndex) => {
        row.forEach((item, columnIndex) => {
          resultLeft[rowIndex][columnIndex] = Math.round(item * level)
        })
      }
    )
    resultRight.forEach(
      (row, rowIndex) => {
        row.forEach((item, columnIndex) => {
          resultRight[rowIndex][columnIndex] = Math.round(item * level)
        })
      }
    )
    setLeftGainDataView(resultLeft)
    setRightGainDataView(resultRight)
  }

  useEffect(() => {
    if (wsFitting && wsFitting.readyState === WebSocket.OPEN) {
      wsFitting.send(
        JSON.stringify({
          type: 'command',
          data: {
            name: 'get-device-info',
            values: ''
          }
        })
      )
      wsFitting.onmessage = e => {
        const deviceResults = JSON.parse(e.data)
        fetchDeviceInfo(deviceResults, setDeviceInfo)
      }
    }
  }, [wsFitting])

  const handleGainTableSend = async () => {
    if (selectedCustomerEmail) {
      setSendGainTableLoading(true)
      if (currentPage === 'backend-fitting') {
        const results = await handleAudiogramData(selectedCustomerEmail)
        await postReviewGaintable(
          selectedCustomerEmail,
          leftGainDataView,
          rightGainDataView,
          results.leftAudiogramData,
          results.rightAudiogramData,
          leftMPO,
          rightMPO,
          fittingNote
        )
      } else {
        await postGaintableBackend(
          selectedCustomerEmail,
          leftGainDataView,
          rightGainDataView,
          leftMPO,
          rightMPO,
          fittingNote,
          isRemote
        )
        wsFitting &&
          wsFitting.send(
            JSON.stringify({
              type: 'command',
              data: {
                name: 'set-gaintable-new',
                values: {
                  'left-ear':
                    leftGainDataView,
                  'right-ear':
                    rightGainDataView,
                  'left-mpo': leftMPO.length > 0 ? leftMPO : null,
                  'right-mpo': rightMPO.length > 0 ? rightMPO : null
                }
              }
            })
          )
      }
      setSendGainTableLoading(false)
      setOpen(false)
    }
  }

  const handleEdit = value => {
    // record the edit index when doing redo/undo functions
    const editingHistoryIndexTemp = editingHistoryIndex + value
    // setGainLevel(1)
    setRightGainDataView(
      gainTableEditHistory[editingHistoryIndexTemp].rightGainData.map(row => row.map(ele => ele * gainLevel))
    )
    setLeftGainDataView(
      gainTableEditHistory[editingHistoryIndexTemp].leftGainData.map(row => row.map(ele => ele * gainLevel))
    )
    setLeftGainData(gainTableEditHistory[editingHistoryIndexTemp].leftGainData)
    setRightGainData(gainTableEditHistory[editingHistoryIndexTemp].rightGainData)
    setRightOutputData(gainTableEditHistory[editingHistoryIndexTemp].rightOutputData)
    setLeftOutputData(gainTableEditHistory[editingHistoryIndexTemp].leftOutputData)
    setLeftMPO(gainTableEditHistory[editingHistoryIndexTemp].leftMPO)
    setRightMPO(gainTableEditHistory[editingHistoryIndexTemp].rightMPO)
    setEditingHistoryIndex(editingHistoryIndexTemp)
  }

  // unselect if clicked on outside of element
  const handleClickOutside = e => {
    if (
      (middleControlRef.current &&
        !middleControlRef.current.contains(e.target)) ||
      (rightGainTableRef.current &&
        !rightGainTableRef.current.contains(e.target)) ||
      (leftGainTableRef.current && !leftGainTableRef.current.contains(e.target))
    ) {
      setSelectedRightItem(new Array(4).fill(0).map(() => new Array(9).fill(0)))
      setSelectedLeftItem(new Array(4).fill(0).map(() => new Array(9).fill(0)))
      setSelectedRightMpoCell(new Array(9).fill(0))
      setSelectedLeftMpoCell(new Array(9).fill(0))
      setHighlightGainCellRight(
        new Array(4).fill(0).map(() => new Array(band + 1).fill(0))
      )
      setHighlightGainCellLeft(
        new Array(4).fill(0).map(() => new Array(band + 1).fill(0))
      )
      setHighlightMpoCellRight(new Array(band + 1).fill(0))
      setHighlightMpoCellLeft(new Array(band + 1).fill(0))
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.addEventListener('mousedown', handleClickOutside)
    }
  }, [rightGainTableRef, leftGainTableRef, middleControlRef])

  return (
    <Root>
      <Typography variant='h5'>{t('fine-tuning_tab_title')}</Typography>
      <InputContainer container spacing={2} wrap='nowrap'>
        <Grid item>
          <Grid container direction='column'>
            <Box
              className='gainChart-medium-right'
              sx={{ width: isRemote ? '448px' : '528px !important' }}
            >
              {graphType === GraphType.GAIN
                ? (
                  <OrkaGainChartWc
                    gainData={rightGainDataView}
                    gainDataOrigin={rightGainDataOrigin}
                    afc={
                      deviceModel === DeviceModel.OrkaOnePro
                        ? AfcThreshold.OrkaOnePro
                        : []
                    }
                    threshold={
                    deviceModel === DeviceModel.OrkaOnePro
                      ? Threshold.OrkaOnePro
                      : Threshold.OrkaOne
                  }
                    lang={i18n.language}
                  />
                  )
                : (
                  <OrkaOutputChartWc
                    outputData={rightOutputData}
                    threshold={OutputThreshold}
                    mpo={rightMPO}
                    lang={i18n.language}
                  />
                  )}
            </Box>
            <Box
              className='gain-table'
              sx={{ marginLeft: isRemote ? '20px' : '28px !important' }}
            >
              <FCToggleButtonGroup
                exclusive
                value={band}
                onChange={(event, newValue) => {
                  if (newValue != null) setBand(newValue)
                }}
                aria-label='config mode'
                style={{ marginBottom: '20px' }}
              >
                <FCToggleButton value={3} aria-label='3'>
                  3
                </FCToggleButton>
                <FCToggleButton value={8} aria-label='8'>
                  8
                </FCToggleButton>
              </FCToggleButtonGroup>
              <FCTable
                ref={rightGainTableRef}
                gainTable={rightGainData}
                mpo={rightMPO}
                size={isRemote ? 'medium' : 'big'}
                setSelectedItem={setSelectedRightItem}
                setSelectedMpoCell={setSelectedRightMpoCell}
                band={band}
                highlightGainCell={highlightGainCellRight}
                setHighlightGainCell={setHighlightGainCellRight}
                highlightMpoCell={highlightMpoCellRight}
                setHighlightMpoCell={setHighlightMpoCellRight}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid
          item
          sx={{ margin: { xs: '0', xl: '0 46px' } }}
          ref={middleControlRef}
        >
          <FineTuningControl
            selectedLeftItem={selectedLeftItem}
            selectedRightItem={selectedRightItem}
            setSelectedLeftItem={setSelectedLeftItem}
            setSelectedRightItem={setSelectedRightItem}
            selectedRightMpoCell={selectedRightMpoCell}
            selectedLeftMpoCell={selectedLeftMpoCell}
            setSelectedRightMpoCell={setSelectedRightMpoCell}
            setSelectedLeftMpoCell={setSelectedLeftMpoCell}
            leftGainData={leftGainData}
            rightGainData={rightGainData}
            rightGainDataView={rightGainDataView}
            leftGainDataView={leftGainDataView}
            setLeftGainDataView={setLeftGainDataView}
            setRightGainDataView={setRightGainDataView}
            leftMPO={leftMPO}
            rightMPO={rightMPO}
            setLeftMPO={setLeftMPO}
            setRightMPO={setRightMPO}
            graphType={graphType}
            threshold={
              deviceModel === DeviceModel.OrkaOnePro
                ? Threshold.OrkaOnePro
                : Threshold.OrkaOne
            }
            combine={combine}
            previousSession={previousSession}
            currentSession={currentSession}
            setCombine={setCombine}
            setLeftGainData={setLeftGainData}
            setRightGainData={setRightGainData}
            setGraphType={setGraphType}
            setCurrentSession={setCurrentSession}
            gainTableEditHistory={gainTableEditHistory}
            setGainTableEditHistory={setGainTableEditHistory}
            editingHistoryIndex={editingHistoryIndex}
            setEditingHistoryIndex={setEditingHistoryIndex}
            fittingNote={fittingNote}
            setFittingNote={setFittingNote}
            handleGainLevelChange={handleGainLevelChange}
            gainLevel={gainLevel}
            setGainLevel={setGainLevel}
            leftOutputData={leftOutputData}
            rightOutputData={rightOutputData}
            setLeftOutputData={setLeftOutputData}
            setRightOutputData={setRightOutputData}
            modifyTableDisabled={modifyTableDisabled}
            setModifyTableDisabled={setModifyTableDisabled}
            selectedCustomerEmail={selectedCustomerEmail}
            fittingData={fittingData}
            setFittingData={setFittingData}
            leftGainDataOrigin={leftGainDataOrigin}
            setLeftGainDataOrigin={setLeftGainDataOrigin}
            setRightGainDataOrigin={setRightGainDataOrigin}
          />
        </Grid>

        <Grid item>
          <Grid container direction='column'>
            <Box
              className='gainChart-medium-left'
              sx={{ width: isRemote ? '448px' : '528px !important' }}
            >
              {graphType === GraphType.GAIN
                ? (
                  <OrkaGainChartWc
                    isLeft
                    gainData={leftGainDataView}
                    gainDataOrigin={leftGainDataOrigin}
                    afc={
                      deviceModel === DeviceModel.OrkaOnePro
                        ? AfcThreshold.OrkaOnePro
                        : []
                    }
                    threshold={
                    deviceModel === DeviceModel.OrkaOnePro
                      ? Threshold.OrkaOnePro
                      : Threshold.OrkaOne
                  }
                    lang={i18n.language}
                  />
                  )
                : (
                  <OrkaOutputChartWc
                    isLeft
                    outputData={leftOutputData}
                    threshold={OutputThreshold}
                    mpo={leftMPO}
                    lang={i18n.language}
                  />
                  )}
            </Box>
            <Box
              className='gain-table'
              sx={{ marginLeft: isRemote ? '20px' : '28px !important' }}
            >
              <Grid sx={{ height: '46px' }} />
              <FCTable
                ref={leftGainTableRef}
                gainTable={
                  leftGainData
                }
                mpo={leftMPO}
                size={isRemote ? 'medium' : 'big'}
                setSelectedItem={
                  combine ? setSelectedRightItem : setSelectedLeftItem
                }
                setSelectedMpoCell={
                  combine ? setSelectedRightMpoCell : setSelectedLeftMpoCell
                }
                band={band}
                highlightGainCell={
                  combine ? highlightGainCellRight : highlightGainCellLeft
                }
                setHighlightGainCell={
                  combine ? setHighlightGainCellRight : setHighlightGainCellLeft
                }
                highlightMpoCell={
                  combine ? highlightMpoCellRight : highlightMpoCellLeft
                }
                setHighlightMpoCell={
                  combine ? setHighlightMpoCellRight : setHighlightMpoCellLeft
                }
              />
            </Box>
          </Grid>
        </Grid>
      </InputContainer>
      <ButtonContainer container wrap='nowrap'>
        <Grid item>
          <FCFab
            onClick={() => handleEdit(-1)}
            disabled={editingHistoryIndex === 0}
          >
            <Undo />
          </FCFab>
        </Grid>
        <Grid item>
          <FCButton
            variant='contained'
            size='large'
            disabled={!selectedCustomerEmail}
            loading={sendGainTableLoading}
            onClick={() => {
              if (currentPage === 'backend-fitting') {
                setOpen(true)
              } else {
                handleGainTableSend()
              }
            }}
          >
            {t('fine-tuning_section_send_button')}
          </FCButton>
        </Grid>
        <Grid item>
          <FCFab
            onClick={() => handleEdit(1)}
            disabled={
              editingHistoryIndex === gainTableEditHistory.length - 1 ||
              gainTableEditHistory.length < 2
            }
          >
            <Undo redo />
          </FCFab>
        </Grid>
      </ButtonContainer>
      <SendCheckDialog
        open={open}
        setOpen={setOpen}
        handleSend={handleGainTableSend}
        sendGainTableLoading={sendGainTableLoading}
      />
    </Root>
  )
}
export default FineTuningSection
