import * as React from 'react'
import { createComponent } from '@lit-labs/react'
import { OrkaGainChart } from '@evocolabs/orka-charts'

const OrkaGainChartWc = createComponent(
  React,
  'orka-gain',
  OrkaGainChart
)

export default OrkaGainChartWc
