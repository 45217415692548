import { createContext, useContext, useState } from 'react'

const WebSocketContext = createContext({})
const WebSocketFittingContext = createContext({})

const useWebSocketContext = () => {
  return useContext(WebSocketContext)
}

const useWebSocketFittingContext = () => {
  return useContext(WebSocketFittingContext)
}

const useWebSocketStates = () => {
  const [ws, setWs] = useState(null)
  const createWebSocket = (audiologist, messageListener, reconnection = 0) => {
    const url = process.env.REACT_APP_WEBSOCK_HOST + '/' + audiologist + '/'
    const wss = new WebSocket(url)
    wss.onopen = () => {
      console.log('ws connected')
      wss.onmessage = e => {
        messageListener(e)
      }
    }
    wss.onclose = e => {
      if (e.code === 1000) {
        console.log('ws connection closed')
      } else {
        if (reconnection < process.env.REACT_APP_WEBSOCKET_RETRY) {
          console.log('ws: ' + url + ' try reconnection: ' + (reconnection + 1))
          createWebSocket(audiologist, messageListener, reconnection + 1)
        } else {
          console.error(
            url +
              ' Reach maxium websocket reconnection: ' +
              (reconnection + 1) +
              '. Please check the network'
          )
        }
      }
    }
    setWs(wss)
  }
  return { ws, setWs, createWebSocket }
}

const useWebSocketFittingStates = () => {
  const [wsFitting, setWsFitting] = useState(null)
  const createWebSocketFitting = (audiologist, customer, reconnection = 0) => {
    const url =
      process.env.REACT_APP_WEBSOCK_HOST +
      '/audiologist/' +
      audiologist +
      '_' +
      customer +
      '/'
    const wss = new WebSocket(url)
    wss.onopen = () => {
      console.log('wsFitting connected')
      setWsFitting(wss)
    }
    wss.onclose = e => {
      if (e.code === 1000) {
        console.log('wsFitting connection closed')
      } else {
        if (reconnection < process.env.REACT_APP_WEBSOCKET_RETRY) {
          console.log('ws: ' + url + ' try reconnection: ' + (reconnection + 1))
          createWebSocketFitting(audiologist, customer, reconnection + 1)
        } else {
          console.error(
            url +
              ' Reach maxium websocket reconnection: ' +
              (reconnection + 1) +
              '. Please check the network'
          )
        }
      }
    }
  }
  return { wsFitting, setWsFitting, createWebSocketFitting }
}

export {
  WebSocketContext,
  WebSocketFittingContext,
  useWebSocketContext,
  useWebSocketFittingContext,
  useWebSocketStates,
  useWebSocketFittingStates
}
