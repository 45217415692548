import React from 'react'
import { Grid, Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import moment from 'moment'
import FCAppointmentCard from 'components/AppointmentCard'
import { getCustomerList, handleApprochingAppointment } from 'services/customers'
import { useAppointmentContext } from 'contexts/Appointment'
import { useCustomerContext } from 'contexts/Customer'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Root = styled(Box)(({ theme }) => ({
  '& .information-visual': {
    width: '672px',
    height: '48px',
    background: theme.palette.white[0],
    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.03)',
    borderRadius: '12px',
    border: '1px solid rgba(0, 0,0,0)',
    marginBottom: '8px',
    '& .information-visual-item': {
      margin: '13px 0',
      height: '22px',
      borderRadius: '20px',
      '&>span': {
        lineHeight: '22px'
      }
    },
    '& .item-current-time': {
      marginLeft: '24px'
    },
    '& .item-completed': {
      textAlign: 'center',
      marginRight: '36px',
      marginLeft: '8px',
      padding: '0 16px',
      display: 'flex',
      background: theme.palette.black[400],
      color: theme.palette.white[0]
    },
    '& .item-upcoming': {
      textAlign: 'center',
      padding: '0 16px',
      display: 'flex',
      background: theme.palette.brand[700],
      color: theme.palette.white[0]
    },
    '& .item-appointment': {
      textAlign: 'center',
      marginLeft: '8px',
      padding: '0 16px',
      display: 'flex',
      background: theme.palette.brand[500],
      color: theme.palette.white[0]
    },
    '& .item-available': {
      textAlign: 'center',
      marginLeft: '8px',
      padding: '0 16px',
      display: 'flex',
      background: theme.palette.green[400],
      color: theme.palette.white[0]
    }
  }
}))
const AppointmentList = (props) => {
  const { parsedAppointments, selectedDate } = props
  const { setCurrentAppointment } = useAppointmentContext()
  const { allCustomers, setAllCustomers, setSelectedCustomer, setSelectedCustomerEmail } = useCustomerContext()
  const { t } = useTranslation()

  const navigate = useNavigate()
  const location = useLocation()
  const currentPage = location.pathname.split('/')[1]

  const setTargetCustomer = async (email) => {
    if (allCustomers) {
      setSelectedCustomer(allCustomers.filter(item => item.email === email)[0])
    } else {
      const result = await getCustomerList()
      setAllCustomers(result)
      setSelectedCustomer(result.filter(item => item.email === email)[0])
    }
    navigate('/' + currentPage + '/customer')
  }

  const handleNavigateToFitting = () => {
    navigate('/' + currentPage + '/audiogram')
  }
  return (
    <Root>
      <Grid
        container
        direction='column'
        spacing={2}
        wrap='nowrap'
      >
        <Grid item>
          <Grid
            className='information-visual'
            container
            justifyContent='space-between'
            wrap='nowrap'
          >
            <Grid item className='information-visual-item item-current-time'>
              <Typography variant='button2'>{selectedDate.format('DD/MM/YYYY')}</Typography>&nbsp;&nbsp;
              {selectedDate.isSame(moment(), 'day') ? <Typography variant='button2'>{t('appointment_list_today')}</Typography> : <Typography variant='button2' />}
            </Grid>
            <Grid item>
              <Grid
                container
                wrap='nowrap'
              >
                <Grid item className='information-visual-item item-upcoming'>
                  <Typography variant='body12Semi'>{t('appointment_list_upcoming_label')}</Typography>
                </Grid>
                <Grid item className='information-visual-item item-appointment'>
                  <Typography variant='body12Semi'>{t('appointment_list_appointment_label')}</Typography>
                </Grid>
                <Grid item className='information-visual-item item-available'>
                  <Typography variant='body12Semi'>{t('appointment_list_availabel_label')}</Typography>
                </Grid>
                <Grid item className='information-visual-item item-completed'>
                  <Typography variant='body12Semi'>{t('appointment_list_completed_label')}</Typography>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </Grid>
        <Grid item>
          <Box width='700px' sx={{ overflowY: 'auto' }}>
            <Grid
              container
              direction='column'
              spacing={2}
              maxHeight='554px'
              wrap='nowrap'
            >
              {parsedAppointments && parsedAppointments.map((item, index) => (
                <Grid key={`appointmentInfo-${index}`} item>
                  <FCAppointmentCard
                    appointmentState={item.appointmentState}
                    appointmentInfo={item}
                    setTargetCustomer={() => setTargetCustomer(item.email)}
                    handleNavigateToFitting={() => handleNavigateToFitting()}
                    handleApprochingAppointment={() => handleApprochingAppointment(item.email, item.startTime, selectedDate)}
                    setCurrentAppointment={setCurrentAppointment}
                    setSelectedCustomerEmail={setSelectedCustomerEmail}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>

    </Root>
  )
}

export default AppointmentList
