import React, { useState, useEffect } from 'react'
import { Typography, Grid, Box } from '@mui/material'
import { styled } from '@mui/material/styles'

import FCButton from 'components/Button/FCButton'
import { OrkaAudiogramWc } from 'components/Audiogram'
import Arrow from 'components/Icon/Arrow'
import FCFab from 'components/Fab'
import SpeechSpectrum from 'components/Icon/SpeechSpectrum'
import { postAudiogramData } from 'services/apis/v1/audiogram'
import { useCustomerContext } from 'contexts/Customer'
import { generateGainTable } from 'services/customers'
import { useGlobalContext } from 'contexts/Global'
import { usePageContext } from 'contexts/Page'
import { useGaintableContext } from 'contexts/Gaintable'
import { useLocation, useNavigate } from 'react-router-dom'
import { FormulaSettingDialog } from '../DeviceInfoSection/FormulaSettingDialog'
import { DomeType } from 'config'
import { useTranslation } from 'react-i18next'

const Root = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.white[0],
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.03)',
  borderRadius: '12px',
  minWidth: '1135px',
  [theme.breakpoints.up('xl')]: {
    minWidth: '1384px'
  },
  height: '720px',
  '& h5': {
    padding: '24px 0 16px 24px'
  },
  '& > button:nth-of-type(1)': {
    marginLeft: '24px'
  },
  '& .audiogram': {
    width: '436px',
    height: '360px'
  }
}))
const TableContainer = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '58px'
}))
const ButtonContainer = styled('div')(({ theme }) => ({
  textAlign: 'center',
  margin: '0 30px'
}))
const FCButtonItem = styled(FCButton)(({ theme }) => ({
  margin: '8px 16px'
}))
const ButtonSave = styled(Grid)(({ theme }) => ({
  marginTop: '85px',
  textAlign: 'center'
}))

const AudiogramSection = props => {
  const { isRemote } = props
  const navigate = useNavigate()
  const location = useLocation()
  const currentPage = location.pathname.split('/')[1]
  const [disabledSave, setDisabledSave] = useState(true)
  const [frequencySpectrumOn, setFrequencySpectrumOn] = useState(false)
  const ActivateSeries = { AC: 0, BC: 1, UCL: 2, MCL: 3 }
  const [activateSeries, setActivateSeries] = useState(ActivateSeries.AC)
  const { selectedCustomerEmail } = useCustomerContext()
  const {
    setRightGainData,
    setLeftGainData,
    setLeftMPO,
    setRightMPO,
    leftAudiogramData,
    setLeftAudiogramData,
    rightAudiogramData,
    setRightAudiogramData,
    setLeftGainDataView,
    setRightGainDataView,
    setLeftGainDataOrigin,
    setRightGainDataOrigin,
    setGainTableEditHistory,
    setEditingHistoryIndex,
    setFittingData,
    currentSession,
    setCurrentSession,
    setPreviousSession,
    previousSession
  } = useGaintableContext()
  const { fittingType } = useGlobalContext()

  const { setUnsavedPageType, PageType } = usePageContext()

  const [postAudiogramLoading, setPostAudiogramLoading] = useState(false)

  const { t, i18n } = useTranslation()

  const TransferDescription = {
    RightToLeft: 0,
    LeftToRight: 1
  }

  const [formulaSettingDialogOpen, setFormulaSettingDialogOpen] = useState(
    false
  )
  const [generateGainLoading, setGenerateGainLoading] = useState(false)
  const handlegainGenerate = async version => {
    setGenerateGainLoading(true)
    setPreviousSession([...previousSession])
    await generateGainTable(
      version,
      leftAudiogramData,
      rightAudiogramData,
      DomeType.Vented,
      DomeType.Vented,
      setLeftGainData,
      setRightGainData,
      setLeftMPO,
      setRightMPO,
      setLeftGainDataView,
      setRightGainDataView,
      setLeftGainDataOrigin,
      setRightGainDataOrigin,
      setGainTableEditHistory,
      setEditingHistoryIndex,
      currentSession,
      setCurrentSession,
      setFittingData,
      fittingType
    )
    setGenerateGainLoading(false)
    setFormulaSettingDialogOpen(false)
    navigate('/' + currentPage + '/fine-tuning')
  }

  const handleAudiogramSave = async () => {
    setPostAudiogramLoading(true)
    await postAudiogramData(
      selectedCustomerEmail,
      leftAudiogramData,
      rightAudiogramData,
      isRemote
    )
    setPostAudiogramLoading(false)
    setDisabledSave(true)
    setUnsavedPageType(null)
    // navigate to the next page
    if (currentPage === 'backend-fitting') {
      setFormulaSettingDialogOpen(true)
    } else {
      navigate('/' + currentPage + '/device')
    }
  }

  const copyAudiogramData = (index, transferDescription) => {
    if (selectedCustomerEmail) {
      setDisabledSave(false)
      setUnsavedPageType(PageType.Audiogram)
    }
    if (transferDescription === TransferDescription.LeftToRight) {
      const transferData = rightAudiogramData.slice(0)
      transferData[index] = leftAudiogramData[index].slice(0)
      setRightAudiogramData(transferData)
    } else {
      const transferData = leftAudiogramData.slice(0)
      transferData[index] = rightAudiogramData[index].slice(0)
      setLeftAudiogramData(transferData)
    }
  }

  useEffect(() => {
    if ((leftAudiogramData && leftAudiogramData.length > 0) || (rightAudiogramData && rightAudiogramData.length > 0)) {
      setDisabledSave(false)
    }
  }, [leftAudiogramData, rightAudiogramData])

  return (
    <Root>
      <Typography variant='h5'>
        {t('audiogram_tab_audiogram_section_title')}
      </Typography>
      <FCButton variant='outlined' size='small'>
        {t('audiogram_tab_audiogram_section_uploaded_audiogram_button')}
      </FCButton>
      <TableContainer container spacing={2} wrap='nowrap'>
        <Grid item>
          <Box
            className='audiogram'
            sx={{ width: isRemote ? '436px' : '530px !important' }}
          >
            <OrkaAudiogramWc
              acData={rightAudiogramData[0]}
              bcData={rightAudiogramData[1]}
              uclData={rightAudiogramData[2]}
              mclData={rightAudiogramData[3]}
              frequencySpectrumOn={frequencySpectrumOn}
              activateSeries={activateSeries}
              lang={i18n.language}
              onClick={() => {
                if (selectedCustomerEmail) {
                  setDisabledSave(false)
                  setUnsavedPageType(PageType.Audiogram)
                }
                setRightAudiogramData(rightAudiogramData.slice(0))
              }}
            />
          </Box>
        </Grid>
        <Grid item>
          <ButtonContainer>
            {activateSeries === ActivateSeries.AC
              ? (
                <FCFab
                  size='small'
                  onClick={() => {
                    copyAudiogramData(
                      ActivateSeries.AC,
                      TransferDescription.LeftToRight
                    )
                  }}
                >
                  <Arrow />
                </FCFab>)
              : (
                <div />)}
            <FCButtonItem
              variant={
                activateSeries === ActivateSeries.AC ? 'contained' : 'outlined'
              }
              size='audiogram'
              onClick={() => setActivateSeries(ActivateSeries.AC)}
            >
              ac
            </FCButtonItem>
            {activateSeries === ActivateSeries.AC
              ? (
                <FCFab
                  size='small'
                  onClick={() => {
                    copyAudiogramData(
                      ActivateSeries.AC,
                      TransferDescription.RightToLeft
                    )
                  }}
                >
                  <Arrow right />
                </FCFab>)
              : (
                <div />)}

            {activateSeries === ActivateSeries.BC
              ? (
                <FCFab
                  size='small'
                  onClick={() => {
                    copyAudiogramData(
                      ActivateSeries.BC,
                      TransferDescription.LeftToRight
                    )
                  }}
                >
                  <Arrow />
                </FCFab>)
              : (
                <div />)}
            <FCButtonItem
              variant={
                activateSeries === ActivateSeries.BC ? 'contained' : 'outlined'
              }
              size='audiogram'
              onClick={() => setActivateSeries(ActivateSeries.BC)}
            >
              bc
            </FCButtonItem>
            {activateSeries === ActivateSeries.BC
              ? (
                <FCFab
                  size='small'
                  onClick={() => {
                    copyAudiogramData(
                      ActivateSeries.BC,
                      TransferDescription.RightToLeft
                    )
                  }}
                >
                  <Arrow right />
                </FCFab>)
              : (
                <div />)}

            {activateSeries === ActivateSeries.UCL
              ? (
                <FCFab
                  size='small'
                  onClick={() => {
                    copyAudiogramData(
                      ActivateSeries.UCL,
                      TransferDescription.LeftToRight
                    )
                  }}
                >
                  <Arrow />
                </FCFab>)
              : (
                <div />)}
            <FCButtonItem
              variant={
                activateSeries === ActivateSeries.UCL ? 'contained' : 'outlined'
              }
              size='audiogram'
              onClick={() => setActivateSeries(ActivateSeries.UCL)}
            >
              ucl
            </FCButtonItem>
            {activateSeries === ActivateSeries.UCL
              ? (
                <FCFab
                  size='small'
                  onClick={() => {
                    copyAudiogramData(
                      ActivateSeries.UCL,
                      TransferDescription.RightToLeft
                    )
                  }}
                >
                  <Arrow right />
                </FCFab>)
              : (
                <div />)}

            {activateSeries === ActivateSeries.MCL
              ? (
                <FCFab
                  size='small'
                  onClick={() => {
                    copyAudiogramData(
                      ActivateSeries.MCL,
                      TransferDescription.LeftToRight
                    )
                  }}
                >
                  <Arrow />
                </FCFab>)
              : (
                <div />)}
            <FCButtonItem
              variant={
                activateSeries === ActivateSeries.MCL ? 'contained' : 'outlined'
              }
              size='audiogram'
              onClick={() => setActivateSeries(ActivateSeries.MCL)}
            >
              mcl
            </FCButtonItem>
            {activateSeries === ActivateSeries.MCL
              ? (
                <FCFab
                  size='small'
                  onClick={() => {
                    copyAudiogramData(
                      ActivateSeries.MCL,
                      TransferDescription.RightToLeft
                    )
                  }}
                >
                  <Arrow right />
                </FCFab>)
              : (
                <div />)}
            <div />
            <FCButtonItem
              variant={frequencySpectrumOn ? 'contained' : 'outlined'}
              size='audiogram'
              icon
              onClick={() => setFrequencySpectrumOn(!frequencySpectrumOn)}
            >
              <SpeechSpectrum />
            </FCButtonItem>
            <div />
          </ButtonContainer>
        </Grid>
        <Grid item>
          <Box
            className='audiogram'
            sx={{ width: isRemote ? '436px' : '530px !important' }}
          >
            <OrkaAudiogramWc
              isLeft
              acData={leftAudiogramData[0]}
              bcData={leftAudiogramData[1]}
              uclData={leftAudiogramData[2]}
              mclData={leftAudiogramData[3]}
              frequencySpectrumOn={frequencySpectrumOn}
              activateSeries={activateSeries}
              lang={i18n.language}
              onClick={() => {
                if (selectedCustomerEmail) {
                  setDisabledSave(false)
                  setUnsavedPageType(PageType.Audiogram)
                }
                setLeftAudiogramData(leftAudiogramData.slice(0))
              }}
            />
          </Box>
        </Grid>
      </TableContainer>
      <ButtonSave>
        <FCButton
          variant='contained'
          size='large'
          onClick={() => handleAudiogramSave()}
          disabled={selectedCustomerEmail ? disabledSave : true}
          loading={postAudiogramLoading}
        >
          {t('audiogram_section_save_button')}
        </FCButton>
      </ButtonSave>
      <FormulaSettingDialog
        open={formulaSettingDialogOpen}
        setOpen={setFormulaSettingDialogOpen}
        generateGainLoading={generateGainLoading}
        setGenerateGainLoading={setGenerateGainLoading}
        handlegainGenerate={handlegainGenerate}
      />
    </Root>
  )
}

export default AudiogramSection
