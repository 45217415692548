import React from 'react'
import { Fab } from '@mui/material'
import { styled } from '@mui/material/styles'

const FabBase = styled(Fab)(({ theme }) => ({
  minHeight: 0,
  width: '52px',
  height: '52px',
  padding: '16px',
  boxShadow: 'none',
  backgroundColor: theme.palette.black[700],
  fill: theme.palette.white[0],
  ':hover': {
    backgroundColor: theme.palette.black[800]
  },
  ':disabled': {
    color: theme.palette.white[0],
    backgroundColor: theme.palette.black[400]
  },
  '&.MuiFab-sizeSmall': {
    width: '32px',
    height: '32px',
    padding: '6px'
  }
}))

const FabBaseContrast = styled(Fab)(({ theme }) => ({
  minHeight: 0,
  width: '54px',
  height: '54px',
  padding: '16px',
  boxShadow: 'none',
  border: `2px solid ${theme.palette.black[700]}`,
  backgroundColor: theme.palette.white[0],
  fill: theme.palette.black[700],
  stroke: theme.palette.black[700],
  ':disabled': {
    backgroundColor: theme.palette.white[0]
  },
  '&.MuiFab-sizeSmall': {
    width: '34px',
    height: '34px',
    padding: '6px'
  }
}))
const FCFab = (props) => {
  const { contrast } = props
  return contrast
    ? <FabBaseContrast {...props}>{props.children}</FabBaseContrast>
    : <FabBase {...props}>{props.children}</FabBase>
}

export default FCFab
