const Battery = props => {
  const { level } = props

  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.25 10C3.25 8.48122 4.48122 7.25 6 7.25H23C24.5188 7.25 25.75 8.48122 25.75 10V21C25.75 22.5188 24.5188 23.75 23 23.75H6C4.48122 23.75 3.25 22.5188 3.25 21V10Z'
        fill='white'
        strokeWidth='2.5'
      />
      <rect
        x='7' y='11' width={(level / 20) * 3} height='9' rx='1'
      />
      <path
        d='M27 12H29C29.5523 12 30 12.4477 30 13V18C30 18.5523 29.5523 19 29 19H27V12Z'
      />
    </svg>
  )
}

export default Battery
