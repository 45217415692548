import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { styled, useTheme } from '@mui/material/styles'
import { Typography, Grid, Box, Stack } from '@mui/material'
import { useLocation } from 'react-router-dom'

import FCSlider from 'components/Slider'
import FCSelect from 'components/Select'
import { FCMenuItem } from 'components/Menu'
import FCButton from 'components/Button'
import { Plus, Combine } from 'components/Icon'
import Add from 'components/Icon/Add'
import { FittingRecordDialog } from './FittingRecordDialog'
import { useDeviceContext } from 'contexts/Device'
import { useWebSocketFittingContext } from 'contexts/WebSocket'
import { AfcAlertDailog } from './AfcAlertDailog'
import { useGaintableContext } from 'contexts/Gaintable'
import { useGlobalContext } from 'contexts/Global.js'
import { AfcThreshold, DeviceModel, OutputThreshold } from 'config'
import { fetchOutputData } from 'services/customers'
import { useTranslation } from 'react-i18next'
const Root = styled(Box)(({ theme }) => ({
  '& .fine-tuning-volume': {
    '&>div: nth-of-type(2)': {
      height: '18px',
      marginTop: '10px',
      '& > span:first-of-type': {
        marginRight: '2px'
      },
      '& > span:last-of-type': {
        marginLeft: '10px'
      }
    },
    '& > div:nth-of-type(3)': {
      height: '18px',
      marginTop: '10px',
      marginBottom: '24px',
      '& > span:first-of-type': {
        marginRight: '2px'
      },
      '& > span:last-of-type': {
        marginLeft: '10px'
      }
    }
  },
  '& .fine-tuning-select': {
    marginBottom: '16px',
    '&>div: nth-of-type(2)': {
      marginTop: '4px',
      width: '208px'
    }
  },
  '& .fine-tuning-table-button': {
    alignItems: 'center',
    marginTop: '32px',
    '& > div': {
      display: 'inline-grid',
      '& > div': {
        display: 'inline-grid',
        margin: '0 5px',
        '& > button': {
          marginBottom: '4px',
          ':active': {
            borderColor: theme.palette.black[700],
            background: theme.palette.black[700],
            stroke: theme.palette.white[0]
          }
        }
      },
      '& > button': {
        marginBottom: '4px',
        ':active': {
          borderColor: theme.palette.black[700],
          background: theme.palette.black[700],
          stroke: theme.palette.white[0]
        }
      }
    },
    '& > button:nth-of-type(1)': {
      marginBottom: '8px',
      ':active': {
        borderColor: theme.palette.black[700],
        background: theme.palette.black[700],
        stroke: theme.palette.white[0]
      }
    }
  }
}))

const FineTuningControl = props => {
  const GraphType = { GAIN: 'gain', OUTPUT: 'output' }
  const {
    selectedLeftItem,
    selectedRightItem,
    leftGainData,
    rightGainData,
    selectedRightMpoCell,
    selectedLeftMpoCell,
    leftMPO,
    rightMPO,
    leftOutputData,
    rightOutputData,
    graphType,
    threshold,
    combine,
    previousSession,
    currentSession,
    gainTableEditHistory,
    editingHistoryIndex,
    fittingNote,
    gainLevel,
    modifyTableDisabled,
    selectedCustomerEmail,
    rightGainDataView,
    leftGainDataView
  } = props
  const {
    setLeftGainData,
    setRightGainData,
    setSelectedLeftItem,
    setSelectedRightItem,
    setSelectedRightMpoCell,
    setSelectedLeftMpoCell,
    setLeftMPO,
    setRightMPO,
    setGraphType,
    setCombine,
    setCurrentSession,
    setGainTableEditHistory,
    setEditingHistoryIndex,
    setFittingNote,
    setLeftGainDataView,
    setRightGainDataView,
    setGainLevel,
    handleGainLevelChange,
    setLeftOutputData,
    setRightOutputData,
    setModifyTableDisabled,
    fittingData,
    setFittingData,
    setLeftGainDataOrigin,
    setRightGainDataOrigin
  } = props
  const theme = useTheme()
  const { deviceInfo, setDeviceInfo } = useDeviceContext()
  const { wsFitting } = useWebSocketFittingContext()
  const [rightVolume, setRightVolume] = useState(0)
  const [leftVolume, setLeftVolume] = useState(0)
  const { deviceModel } = useGaintableContext()
  const { fittingType } = useGlobalContext()
  const [fittingRecordDialogIsOpen, setFittingRecordDialogIsOpen] = useState(
    false
  )
  const [afcAlertDailogIsOpen, setAfcAlertDailogIsOpen] = useState(false)
  // Do not show this AFC alert dialog during this session.
  const [afcAlertDailogShowDisabled, setAfcAlertDailogShowDisabled] = useState(
    false
  )

  const location = useLocation()
  const currentPage = location.pathname.split('/')[1]
  const { t } = useTranslation()

  useEffect(() => {
    if (deviceInfo) {
      setRightVolume(deviceInfo.rightVolume)
      setLeftVolume(deviceInfo.leftVolume)
    }
  }, [deviceInfo])

  const handleSetGraphType = async e => {
    setGraphType(e.target.value)
    setSelectedLeftItem(new Array(4).fill(0).map(() => new Array(9).fill(0)))
    setSelectedRightItem(new Array(4).fill(0).map(() => new Array(9).fill(0)))
    setSelectedLeftMpoCell(new Array(9).fill(0))
    setSelectedRightMpoCell(new Array(9).fill(0))
    if (selectedCustomerEmail && e.target.value === GraphType.OUTPUT) {
      setModifyTableDisabled(true)
      const leftOuput = await fetchOutputData(
        gainTableEditHistory[editingHistoryIndex].leftGainData
      )
      const rightOutput = await fetchOutputData(
        gainTableEditHistory[editingHistoryIndex].rightGainData
      )
      setLeftOutputData(leftOuput)
      setRightOutputData(rightOutput)
      setModifyTableDisabled(false)
    }
  }

  const handleSaveNote = () => {
    const currentSessionTemp = structuredClone(currentSession)
    const recordNow = `${fittingType} ${moment().format('DD/MM/YY hh:mm:ss')}`
    currentSessionTemp.push({
      record: recordNow,
      fittingNote,
      leftGainData: structuredClone(leftGainData),
      rightGainData: structuredClone(rightGainData),
      leftMPO: structuredClone(leftMPO),
      rightMPO: structuredClone(rightMPO)
    })
    setFittingData(recordNow)
    setCurrentSession(currentSessionTemp)
    setFittingRecordDialogIsOpen(false)
  }

  const chooseFittingData = value => {
    if (value !== 'add record') {
      setFittingData(value)
    } else {
      setFittingNote('')
      setFittingRecordDialogIsOpen(true)
    }
  }

  const sendUpdateVolumeRequest = (leftVolume, rightVolume) => {
    wsFitting &&
      wsFitting.send(
        JSON.stringify({
          type: 'command',
          data: {
            name: 'set-device-volume',
            values: {
              'right-volume': rightVolume,
              'left-volume': leftVolume,
              'denoise-mode': 1
            }
          }
        })
      )
  }

  const modifyGainTable = (
    selectedItems,
    gainTableData,
    gainDataView,
    value
  ) => {
    selectedItems.forEach((row, rowIndex) => {
      row.forEach((item, columnIndex) => {
        if (item && rowIndex !== 0 && columnIndex !== 0) {
          // index reverse is the index in the gain data get from backend, index is the order show in front end
          const rowIndexReverse = gainTableData.length - rowIndex
          gainTableData[rowIndexReverse][columnIndex - 1] += value
          gainDataView[rowIndexReverse][columnIndex - 1] += value
          if (
            deviceModel === DeviceModel.OrkaOnePro &&
            gainTableData[rowIndexReverse][columnIndex - 1] >
            AfcThreshold.OrkaOnePro[columnIndex - 1] &&
            graphType === GraphType.GAIN
          ) {
            if (!afcAlertDailogShowDisabled) {
              setAfcAlertDailogIsOpen(true)
              gainTableData[rowIndexReverse][columnIndex - 1] -= value
              gainDataView[rowIndexReverse][columnIndex - 1] -= value
            }
          }
          // -5 <= g80 <= g65 <= g50 <= threshold
          gainTableData[rowIndexReverse][columnIndex - 1] = Math.max(
            -5,
            Math.min(
              gainTableData[rowIndexReverse][columnIndex - 1],
              threshold[columnIndex - 1]
            )
          )
          gainDataView[rowIndexReverse][columnIndex - 1] = Math.max(
            -5,
            Math.min(
              gainDataView[rowIndexReverse][columnIndex - 1],
              threshold[columnIndex - 1]
            )
          )
          if (rowIndexReverse > 0) {
            //
            // graph order   table order
            // g50          g80
            // g80          g65
            // g65          g50
            // line below will push the line above
            for (let i = gainTableData.length - 1; i > 0; i--) {
              gainTableData[i - 1][columnIndex - 1] = Math.max(
                gainTableData[i - 1][columnIndex - 1],
                gainTableData[rowIndexReverse][columnIndex - 1]
              )
              gainDataView[i - 1][columnIndex - 1] = Math.max(
                gainDataView[i - 1][columnIndex - 1],
                gainDataView[rowIndexReverse][columnIndex - 1]
              )
            }
          }

          if (rowIndexReverse < gainTableData.length - 1) {
            // line above will press line below
            for (let i = rowIndexReverse; i < gainTableData.length - 1; i++) {
              gainTableData[i + 1][columnIndex - 1] = Math.min(
                gainTableData[i + 1][columnIndex - 1],
                gainTableData[rowIndexReverse][columnIndex - 1]
              )
              gainDataView[i + 1][columnIndex - 1] = Math.min(
                gainDataView[i + 1][columnIndex - 1],
                gainDataView[rowIndexReverse][columnIndex - 1]
              )
            }
          }
        }
      })
    })
  }

  const modifyMpo = (selectedMpoCells, mpo, value, outputData) => {
    selectedMpoCells.forEach((cell, index) => {
      if (cell && index > 0) {
        // higher than g50
        // less than output limit
        mpo[index - 1] = Math.max(
          Math.min(OutputThreshold[index - 1], mpo[index - 1] + value),
          outputData[0][index - 1]
        )
      }
    })
  }

  // when click the gain table, +/- values
  const handleModifyTable = async (value, selectTableRight) => {
    let leftOutPut, rightOutput
    setModifyTableDisabled(true)
    const modifiedGainLeft = structuredClone(leftGainData)
    const modifiedGainRight = structuredClone(rightGainData)
    const modifiedMpoLeft = structuredClone(leftMPO)
    const modifiedMpoRight = structuredClone(rightMPO)
    const modifiedLeftView = structuredClone(leftGainDataView)
    const modifiedRightView = structuredClone(rightGainDataView)
    // deep copy
    const gainHistoryTemp = structuredClone(
      gainTableEditHistory.slice(0, editingHistoryIndex + 1)
    )
    if (combine) {
      modifyGainTable(
        selectedRightItem,
        modifiedGainRight,
        modifiedRightView,
        value
      )
      setRightGainData(modifiedGainRight)
      modifyGainTable(
        selectedRightItem,
        modifiedGainLeft,
        modifiedLeftView,
        value
      )
      setLeftGainData(modifiedGainLeft)
      leftOutPut = await fetchOutputData(modifiedGainLeft)
      rightOutput = await fetchOutputData(modifiedGainRight)
      modifyMpo(selectedRightMpoCell, modifiedMpoRight, value, rightOutput)
      modifyMpo(selectedRightMpoCell, modifiedMpoLeft, value, leftOutPut)
      setRightGainDataView(modifiedRightView)
      setLeftGainDataView(modifiedLeftView)
      setRightMPO(modifiedMpoRight)
      setLeftMPO(modifiedMpoLeft)
      setLeftOutputData(rightOutput)
      setRightOutputData(rightOutput)
    } else {
      if (selectTableRight) {
        modifyGainTable(
          selectedRightItem,
          modifiedGainRight,
          modifiedRightView,
          value
        )
        setRightGainData(modifiedGainRight)
        setRightGainDataView(modifiedRightView)
        rightOutput = await fetchOutputData(modifiedGainRight)
        modifyMpo(selectedRightMpoCell, modifiedMpoRight, value, rightOutput)
        setRightOutputData(rightOutput)
        setRightMPO(modifiedMpoRight)
      } else {
        modifyGainTable(
          selectedLeftItem,
          modifiedGainLeft,
          modifiedLeftView,
          value
        )
        setLeftGainData(modifiedGainLeft)
        setLeftGainDataView(modifiedLeftView)
        leftOutPut = await fetchOutputData(modifiedGainLeft)
        modifyMpo(selectedLeftMpoCell, modifiedMpoLeft, value, leftOutPut)
        setLeftOutputData(leftOutPut)
        setLeftMPO(modifiedMpoLeft)
      }
    }
    gainHistoryTemp.push({
      leftGainData: modifiedGainLeft,
      rightGainData: modifiedGainRight,
      leftMPO: modifiedMpoLeft,
      rightMPO: modifiedMpoRight,
      leftOutputData: leftOutPut || leftOutputData,
      rightOutputData: rightOutput || rightOutputData
    })
    let isCurrent = false
    const recordName = `${fittingType} ${moment().format('DD/MM/YY hh:mm:ss')}`
    // modify current session
    const modifiedCurrentSession = currentSession.map((item, index) => {
      if (fittingData === item.record) {
        isCurrent = true
        return {
          record: recordName,
          fittingNote: item.fittingNote,
          leftGainData: modifiedGainLeft,
          rightGainData: modifiedGainRight,
          leftMPO: modifiedMpoLeft,
          rightMPO: modifiedMpoRight
        }
      }
      return item
    })
    setCurrentSession(modifiedCurrentSession)
    if (!isCurrent) {
      // replace current session if current session is a initial empty record, otherwise push a new session
      if (currentSession[0].leftGainData.length !== 0) {
        currentSession.push({
          record: recordName,
          fittingNote: '',
          leftGainData: modifiedGainLeft,
          rightGainData: modifiedGainRight,
          leftMPO: modifiedMpoLeft,
          rightMPO: modifiedMpoRight
        })
        setCurrentSession([...currentSession])
      } else {
        setCurrentSession([{
          record: recordName,
          fittingNote: '',
          leftGainData: modifiedGainLeft,
          rightGainData: modifiedGainRight,
          leftMPO: modifiedMpoLeft,
          rightMPO: modifiedMpoRight
        }])
      }
    }
    setFittingData(recordName)
    setGainTableEditHistory(gainHistoryTemp)
    setEditingHistoryIndex(editingHistoryIndex + 1)
    setModifyTableDisabled(false)
  }

  return (
    <Root>
      {currentPage === 'backend-fitting'
        ? (
          <Grid sx={{ height: '75px' }} />)

        : (
          <Grid className='fine-tuning-volume'>
            <div>
              <Typography variant='body14Med'>
                {t('fine-tuning_tab_volume')}
              </Typography>
            </div>
            <Stack spacing={1} direction='row' alignItems='center'>
              <Typography
                variant='body14Semi'
                style={{ color: theme.palette.red[600] }}
              >
                R
              </Typography>
              <FCSlider
                min={0}
                max={6}
                value={rightVolume}
                onChange={(event, newValue) => {
                  setRightVolume(newValue)
                  sendUpdateVolumeRequest(deviceInfo.leftVolume, newValue)
                  setDeviceInfo({ ...deviceInfo, rightVolume: newValue })
                }}
              />
              <Typography variant='body14Semi'>{rightVolume}</Typography>
            </Stack>

            <Stack spacing={1} direction='row' alignItems='center'>
              <Typography
                variant='body14Semi'
                style={{ color: theme.palette.blue[600] }}
              >
                L
              </Typography>
              <FCSlider
                min={0}
                max={6}
                value={leftVolume}
                onChange={(event, newValue) => {
                  setLeftVolume(newValue)
                  sendUpdateVolumeRequest(newValue, deviceInfo.rightVolume)
                  setDeviceInfo({ ...deviceInfo, leftVolume: newValue })
                }}
              />
              <Typography variant='body14Semi'>{leftVolume}</Typography>
            </Stack>
          </Grid>)}

      <Grid className='fine-tuning-select'>
        <div>
          <Typography variant='body14Med'>
            {t('fine-tuning_tab_graph_type')}
          </Typography>
        </div>
        <FCSelect
          value={graphType}
          onChange={e => {
            handleSetGraphType(e)
          }}
        >
          <FCMenuItem value='gain' sx={{ paddingLeft: '12px' }}>
            {t('fine-tuning_tab_graph_type_gain')}
          </FCMenuItem>
          <FCMenuItem value='output' sx={{ paddingLeft: '12px' }}>
            {t('fine-tuning_tab_graph_type_output')}
          </FCMenuItem>
        </FCSelect>
      </Grid>

      <Grid className='fine-tuning-select'>
        <div>
          <Typography variant='body14Med'>
            {t('fine-tuning_tab_gain_level')}
          </Typography>
        </div>
        <FCSelect
          value={gainLevel}
          onChange={e => {
            setGainLevel(e.target.value)
            handleGainLevelChange(e.target.value)
          }}
        >
          <FCMenuItem value={1.2} sx={{ paddingLeft: '12px' }}>
            {t('fine-tuning_tab_target_level_120')}
          </FCMenuItem>
          <FCMenuItem value={1.1} sx={{ paddingLeft: '12px' }}>
            {t('fine-tuning_tab_target_level_110')}
          </FCMenuItem>
          <FCMenuItem value={1} sx={{ paddingLeft: '12px' }}>
            {t('fine-tuning_tab_target_level_100')}
          </FCMenuItem>
          <FCMenuItem value={0.9} sx={{ paddingLeft: '12px' }}>
            {t('fine-tuning_tab_target_level_90')}
          </FCMenuItem>
          <FCMenuItem value={0.8} sx={{ paddingLeft: '12px' }}>
            {t('fine-tuning_tab_target_level_80')}
          </FCMenuItem>
        </FCSelect>
      </Grid>

      <Grid className='fine-tuning-select'>
        <div>
          <Typography variant='body14Med'>
            {t('fine-tuning_tab_fitting_data_input')}
          </Typography>
        </div>
        <FCSelect
          value={fittingData}
          disabled={fittingData === ''}
          displayEmpty
          renderValue={selected => {
            if (selected === '') {
              return (
                <Typography
                  variant='body14Med'
                  sx={{ color: theme.palette.black[550] }}
                >
                  {t('fine-tuning_tab_fitting_data_input_no_data')}
                </Typography>
              )
            }
            return selected
          }}
          onChange={e => {
            chooseFittingData(e.target.value)
          }}
        >
          {fittingData !== '' && (
            <Box>
              <Typography
                variant='body12Med'
                sx={{ color: theme.palette.black[550], marginLeft: '8px' }}
              >
                Current session
              </Typography>
            </Box>
          )}

          {currentSession.length &&
            fittingData !== '' &&
            currentSession.map((item, index) => (
              <FCMenuItem
                key={index}
                value={item.record}
                onClick={() => {
                  // save sesssion change
                  const newCurrentSession = currentSession.map((element, index) => {
                    if (fittingData === element.record) {
                      return {
                        record: element.record,
                        fittingNote: element.fittingNote,
                        leftGainData,
                        rightGainData,
                        leftMPO,
                        rightMPO
                      }
                    }
                    return element
                  })
                  setCurrentSession(newCurrentSession)
                  setLeftGainData(item.leftGainData)
                  setRightGainData(item.rightGainData)
                  setLeftGainDataOrigin(item.leftGainData)
                  setRightGainDataOrigin(item.rightGainData)
                  setLeftGainDataView(item.leftGainData)
                  setRightGainDataView(item.rightGainData)
                  setLeftMPO(item.leftMPO)
                  setRightMPO(item.rightMPO)
                  setEditingHistoryIndex(0)
                  setGainTableEditHistory([
                    {
                      leftGainData: item.leftGainData,
                      rightGainData: item.rightGainData,
                      leftMPO: item.leftMPO,
                      rightMPO: item.rightMPO
                    }
                  ])
                  setGainLevel(1)
                }}
              >
                <Box>
                  <Box>
                    <Typography
                      variant='body14Med'
                      sx={{ color: theme.palette.black[800] }}
                    >
                      {item.record}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant='body10Semi'
                      sx={{ color: theme.palette.black[700] }}
                    >
                      {item.fittingNote}
                    </Typography>
                  </Box>
                </Box>
              </FCMenuItem>
            ))}
          {fittingData !== '' && (
            <Box>
              <Typography
                variant='body12Med'
                sx={{ color: theme.palette.black[550], marginLeft: '8px' }}
              >
                Previous session
              </Typography>
            </Box>
          )}

          {previousSession.length &&
            fittingData !== '' &&
            previousSession.map((item, index) => (
              <FCMenuItem
                key={index}
                value={item.record}
                sx={{ paddingLeft: '12px' }}
                onClick={() => {
                  setLeftGainDataOrigin(item.leftGainData)
                  setRightGainDataOrigin(item.rightGainData)
                  setLeftGainData(item.leftGainData)
                  setRightGainData(item.rightGainData)
                  setLeftGainDataView(item.leftGainData)
                  setRightGainDataView(item.rightGainData)
                  setLeftMPO(item.leftMPO)
                  setRightMPO(item.rightMPO)
                  setGainLevel(1)
                  setEditingHistoryIndex(0)
                  setGainTableEditHistory([
                    {
                      leftGainData: item.leftGainData,
                      rightGainData: item.rightGainData,
                      leftMPO: item.leftMPO,
                      rightMPO: item.rightMPO
                    }
                  ])
                }}
              >
                <Box>
                  <Box>
                    <Typography
                      variant='body14Med'
                      sx={{ color: theme.palette.black[800] }}
                    >
                      {item.record}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant='body10Semi'
                      sx={{ color: theme.palette.black[700] }}
                    >
                      {item.fittingNote}
                    </Typography>
                  </Box>
                </Box>
              </FCMenuItem>
            ))}
          <Box
            className='fine-tuning-divider'
            sx={{
              height: '1px',
              backgroundColor: theme.palette.black[300],
              margin: '8px 4px'
            }}
          />
          {fittingData !== '' && (
            <FCMenuItem
              value='add record'
              sx={{
                ':hover': { background: theme.palette.white[0] },
                paddingLeft: '12px',
                fill: theme.palette.brand[600]
              }}
            >
              <Add />
              <Typography
                variant='text1'
                sx={{
                  color: theme.palette.brand[600],
                  marginLeft: '4px',
                  ':hover': { textDecoration: 'underline' }
                }}
              >
                Add Record
              </Typography>
            </FCMenuItem>
          )}
        </FCSelect>
      </Grid>

      <Grid
        className='fine-tuning-table-button'
        container
        direction='column'
        wrap='nowrap'
      >
        <FCButton
          variant='outlined'
          size='fineTune'
          icon='true'
          onClick={() => setCombine(!combine)}
          disabled={selectedCustomerEmail ? modifyTableDisabled : true}
        >
          {combine ? <Combine /> : <Combine split />}
        </FCButton>
        {combine
          ? (
            <Box>
              <FCButton
                variant='outlined'
                size='fineTune'
                icon='true'
                onClick={() => handleModifyTable(3)}
                disabled={selectedCustomerEmail ? modifyTableDisabled : true}
              >
                <Plus three />
              </FCButton>
              <FCButton
                variant='outlined'
                size='fineTune'
                icon='true'
                onClick={() => handleModifyTable(1)}
                disabled={selectedCustomerEmail ? modifyTableDisabled : true}
              >
                <Plus />
              </FCButton>
              <FCButton
                variant='outlined'
                size='fineTune'
                icon='true'
                onClick={() => handleModifyTable(-1)}
                disabled={selectedCustomerEmail ? modifyTableDisabled : true}
              >
                <Plus minus />
              </FCButton>
              <FCButton
                variant='outlined'
                size='fineTune'
                icon='true'
                onClick={() => handleModifyTable(-3)}
                disabled={selectedCustomerEmail ? modifyTableDisabled : true}
              >
                <Plus minus three />
              </FCButton>
            </Box>)
          : (
            <Box sx={{ display: 'flex !important', flexDirection: 'row' }}>
              <Box>
                <FCButton
                  variant='outlined'
                  size='fineTune'
                  icon='true'
                  onClick={() => handleModifyTable(3, 1)}
                  disabled={selectedCustomerEmail ? modifyTableDisabled : true}
                >
                  <Plus three />
                </FCButton>
                <FCButton
                  variant='outlined'
                  size='fineTune'
                  icon='true'
                  onClick={() => handleModifyTable(1, 1)}
                  disabled={selectedCustomerEmail ? modifyTableDisabled : true}
                >
                  <Plus />
                </FCButton>
                <FCButton
                  variant='outlined'
                  size='fineTune'
                  icon='true'
                  onClick={() => handleModifyTable(-1, 1)}
                  disabled={selectedCustomerEmail ? modifyTableDisabled : true}
                >
                  <Plus minus />
                </FCButton>
                <FCButton
                  variant='outlined'
                  size='fineTune'
                  icon='true'
                  onClick={() => handleModifyTable(-3, 1)}
                  disabled={selectedCustomerEmail ? modifyTableDisabled : true}
                >
                  <Plus minus three />
                </FCButton>
              </Box>
              <Box>
                <FCButton
                  variant='outlined'
                  size='fineTune'
                  icon='true'
                  onClick={() => handleModifyTable(3, 0)}
                  disabled={selectedCustomerEmail ? modifyTableDisabled : true}
                >
                  <Plus three />
                </FCButton>
                <FCButton
                  variant='outlined'
                  size='fineTune'
                  icon='true'
                  onClick={() => handleModifyTable(1, 0)}
                  disabled={selectedCustomerEmail ? modifyTableDisabled : true}
                >
                  <Plus />
                </FCButton>
                <FCButton
                  variant='outlined'
                  size='fineTune'
                  icon='true'
                  onClick={() => handleModifyTable(-1, 0)}
                  disabled={selectedCustomerEmail ? modifyTableDisabled : true}
                >
                  <Plus minus />
                </FCButton>
                <FCButton
                  variant='outlined'
                  size='fineTune'
                  icon='true'
                  onClick={() => handleModifyTable(-3, 0)}
                  disabled={selectedCustomerEmail ? modifyTableDisabled : true}
                >
                  <Plus minus three />
                </FCButton>
              </Box>
            </Box>)}
      </Grid>
      <FittingRecordDialog
        fittingType={fittingType}
        leftGainData={leftGainData}
        rightGainData={rightGainData}
        open={fittingRecordDialogIsOpen}
        setOpen={setFittingRecordDialogIsOpen}
        currentSession={currentSession}
        setFittingNote={setFittingNote}
        fittingNote={fittingNote}
        handleSaveNote={handleSaveNote}
      />
      <AfcAlertDailog
        open={afcAlertDailogIsOpen}
        setOpen={setAfcAlertDailogIsOpen}
        showDisabled={afcAlertDailogShowDisabled}
        setShowDisabled={setAfcAlertDailogShowDisabled}
      />
    </Root>
  )
}

export default FineTuningControl
