const CheckboxOutline = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect
      x='1'
      y='1'
      width='18'
      height='18'
      rx='3'
      stroke='#B1B7C2'
      strokeWidth='2'
    />
  </svg>
)

export default CheckboxOutline
