const SpeechSpectrum = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14.4444 17.1676C14.2003 17.1676 13.9752 17.1184 13.8116 17.0472C13.5067 16.892 13.2513 16.7101 13.0176 16.4122C12.7816 16.1115 12.5639 15.6879 12.3436 15.0439C11.8844 13.6756 11.0212 13.0265 10.2108 12.4172L10.1846 12.3975L10.1846 12.3975L10.1776 12.3922C9.47898 11.8666 8.76746 11.3312 8.14929 10.2367L8.14923 10.2366C7.6852 9.41749 7.43333 8.52478 7.43333 7.73917C7.43333 5.37141 9.34167 3.50808 11.7778 3.50808C14.2139 3.50808 16.1222 5.37141 16.1222 7.73917V7.83917H16.2222H18H18.1V7.73917C18.1 4.27729 15.3239 1.57565 11.7778 1.57565C8.23167 1.57565 5.45556 4.27729 5.45556 7.73917C5.45556 8.85007 5.79896 10.0709 6.41964 11.1667C7.23921 12.6148 8.20448 13.344 8.97998 13.926L8.98021 13.9262C9.70108 14.4639 10.1917 14.8374 10.4652 15.6535L10.4653 15.6538C11.0047 17.2483 11.7035 18.1562 12.9413 18.786L12.9413 18.7861L12.9464 18.7884C13.4127 18.9933 13.9242 19.1 14.4444 19.1C16.4617 19.1 18.1 17.5071 18.1 15.5351V15.4351H18H16.2222H16.1222V15.5351C16.1222 16.4303 15.3694 17.1676 14.4444 17.1676ZM6.19402 2.30186L6.26795 2.23025L6.19424 2.15841L4.93201 0.928382L4.86222 0.860371L4.79243 0.928382C3.00528 2.66995 1.9 5.07747 1.9 7.73917C1.9 10.4009 3.00528 12.8084 4.79243 14.55L4.86222 14.618L4.93201 14.55L6.18535 13.3286L6.25861 13.2572L6.18557 13.1856C4.76357 11.7913 3.87778 9.86758 3.87778 7.73917C3.87778 5.61075 4.76356 3.68728 6.19402 2.30186ZM9.45556 7.73917C9.45556 8.9922 10.4983 10.0047 11.7778 10.0047C13.0572 10.0047 14.1 8.9922 14.1 7.73917C14.1 6.48614 13.0572 5.47363 11.7778 5.47363C10.4983 5.47363 9.45556 6.48614 9.45556 7.73917Z'
      strokeWidth='0.2'
    />
  </svg>
)

export default SpeechSpectrum
