import { createContext, useContext, useState } from 'react'

const CustomerContext = createContext({})

const useCustomerContext = () => {
  return useContext(CustomerContext)
}

const useCustomerStates = () => {
  const [allCustomers, setAllCustomers] = useState(null)
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [selectedHistory, setSelectedHistory] = useState(null)
  const [selectedCustomerEmail, setSelectedCustomerEmail] = useState(null)
  return {
    allCustomers,
    setAllCustomers,
    selectedCustomer,
    setSelectedCustomer,
    selectedHistory,
    setSelectedHistory,
    selectedCustomerEmail,
    setSelectedCustomerEmail
  }
}

export { CustomerContext, useCustomerContext, useCustomerStates }
