import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { Filter, Search, Close } from 'components/Icon'
import { FCInput } from 'components/Input'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

const InputBase = styled(FCInput)(({ theme }) => ({
  height: '32px',
  padding: '6px 12px',
  ...theme.typography.body14Med,
  color: theme.palette.black[800],
  border: `1px solid ${theme.palette.brand[600]}`,
  background: theme.palette.white[0],
  caretColor: theme.palette.brand[600],
  fill: theme.palette.black[700],
  '& > input': {
    marginLeft: '8px'
  },
  ':hover': {
    '&& fieldset': {
      border: `1px solid ${theme.palette.brand[600]}`,
      boxShadow: `0 0 0 2px ${theme.palette.brand[300]}`
    }
  },
  '&.Mui-focused': {
    '&& fieldset': {
      border: `1px solid ${theme.palette.brand[600]}`,
      boxShadow: `0 0 0 2px ${theme.palette.brand[300]}`
    }
  }
}))

const FilterBase = styled(Box)(({ theme }) => ({
  position: 'absolute',
  zIndex: '999',
  maxWidth: '88px',
  top: '36px',
  background: theme.palette.white[0],
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  borderRadius: '6px',
  '& label': {
    minWidth: '64px',
    margin: '4px',
    padding: '6px 8px',
    ':hover': {
      background: theme.palette.gray[200],
      borderRadius: '6px'
    },
    ':focus': {
      background: theme.palette.gray[300],
      borderRadius: '6px'
    },
    '& .MuiFormControlLabel-label': {
      ...theme.typography.body14Med,
      marginLeft: '8px'
    }
  }

}))

const FCSearchBar = (props) => {
  const [open, setOpen] = useState(false)
  const { searchQuery, setSearchQuery } = props
  const clearSearchQuery = () => {
    if (searchQuery !== '') {
      setSearchQuery('')
    }
  }
  const { t } = useTranslation()
  return (
    <Box sx={{ position: 'relative' }}>
      <InputBase
        startAdornment={<Box sx={{ height: '20px', cursor: 'pointer' }} onClick={() => setOpen(!open)}><Filter /></Box>}
        endAdornment={<Box sx={{ height: '20px', cursor: 'pointer' }} onClick={() => clearSearchQuery()}>{searchQuery === '' ? <Search /> : <Close />}</Box>}
        placeholder={t('search_bar_placeholder')}
        value={searchQuery}
        onChange={(e) => {
          setSearchQuery(e.target.value)
        }}
      />
      <FilterBase>
        {open ? props.children : ''}
      </FilterBase>
    </Box>
  )
}

export default FCSearchBar
