const Plus = props => {
  const { minus, three } = props

  const transform = 'rotate(180deg)'

  return three
    ? (
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        style={{ transform: minus ? transform : 'none' }}
      >
        <path
          d='M17 11L10 3L3 11M17 17L10 9L3 17'
          strokeWidth='2.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
      )
    : (
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        style={{ transform: minus ? transform : 'none' }}
      >
        <path
          d='M3 13L10 5L17 13'
          strokeWidth='2.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
      )
}

export default Plus
