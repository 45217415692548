import React from 'react'
import { Grid, Typography } from '@mui/material'
import FCDialog from 'components/Dialog'
import FCButton from 'components/Button'
import GainIcon from 'assets/imgs/GainIcon.svg'
import { useTranslation } from 'react-i18next'

export const AudiogramUnsavedDialog = (props) => {
  const { open, setOpen, nextPage, handleLeave } = props
  const { t } = useTranslation()
  return (
    <FCDialog open={open}>
      <Grid container direction='column' wrap='nowrap'>
        <Grid item className='dialog-img'>
          <img src={GainIcon} alt='' />
        </Grid>
        <Grid item className='dialog-title'>
          <Typography variant='h5'>{t('audiogram_unsaved_dialog_title')}</Typography>
        </Grid>
        <Grid item className='dialog-description'>
          <Typography variant='body16Reg'>
            {t('audiogram_unsaved_dialog_description')}
          </Typography>
        </Grid>
        <Grid item className='dialog-button'>
          <FCButton
            variant='outlined'
            size='medium'
            onClick={() => handleLeave(nextPage)}
          >
            {t('audiogram_unsaved_dialog_leave_button')}
          </FCButton>
          <FCButton
            variant='contained'
            size='medium'
            onClick={() => setOpen(false)}
          >
            {t('audiogram_unsaved_dialog_stay_button')}
          </FCButton>
        </Grid>
      </Grid>
    </FCDialog>
  )
}
