import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { useAppointmentContext } from 'contexts/Appointment'
import { useTheme } from '@emotion/react'
import { useClinicFittingContext } from 'contexts/ClinicFitting'

export const CounterDownTimer = () => {
  const { currentAppointment } = useAppointmentContext()
  const { clinicFitting } = useClinicFittingContext()
  const theme = useTheme()

  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)
  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
      if (minutes < 0) {
        setMinutes(0)
      }
      if (seconds === 0) {
        if (minutes <= 0) {
          clearInterval(myInterval)
        } else {
          setMinutes(minutes - 1)
          setSeconds(59)
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval)
    }
  })
  useEffect(() => {
    if (currentAppointment || clinicFitting) {
      const currentSession = currentAppointment || {
        userName: clinicFitting.username,
        email: clinicFitting.user,
        startTime: clinicFitting.start_time,
        endTime: clinicFitting.end_time
      }
      if (new Date(currentSession.startTime) - new Date() <= 0) {
        if (currentSession.endTime) {
          const second =
            60 +
            (new Date(currentSession.startTime).getSeconds() -
              new Date().getSeconds())
          setSeconds(second)
          setMinutes(
            (new Date(currentSession.endTime).getDate() -
              new Date().getDate()) *
              24 *
              60 +
              (new Date(currentSession.endTime).getHours() -
                new Date().getHours()) *
                60 +
              (new Date(currentSession.endTime).getMinutes() -
                new Date().getMinutes())
          )
        } else {
          setSeconds(0)
          setMinutes(60)
        }
      }
    }
  }, [currentAppointment, clinicFitting])
  return (
    <Grid item sx={{ marginRight: '20px' }}>
      <Typography
        variant='body16Semi'
        sx={{
          color:
            minutes < 15 ? theme.palette.red[700] : theme.palette.brand[600]
        }}
      >
        {minutes}
      </Typography>
      <Typography variant='body16Semi'>min </Typography>
      <Typography
        variant='body16Semi'
        sx={{
          color:
            minutes < 15 ? theme.palette.red[700] : theme.palette.brand[600]
        }}
      >
        {seconds < 10 ? `0${seconds}` : seconds}
      </Typography>
      <Typography variant='body16Semi'>s</Typography>
    </Grid>
  )
}
