const Microphone = props => {
  const { on } = props

  return on
    ? (
      <svg width='36' height='36' viewBox='0 0 36 36' xmlns='http://www.w3.org/2000/svg'>
        <path d='M26.9811 19.0543C27.0062 18.9351 27.0063 18.8119 26.9815 18.6927C26.9567 18.5734 26.9075 18.4607 26.8371 18.3619C26.7667 18.2631 26.6767 18.1803 26.5728 18.1189C26.4689 18.0575 26.3534 18.0188 26.2339 18.0053C26.1144 17.9919 25.9934 18.0039 25.8787 18.0408C25.764 18.0776 25.6583 18.1383 25.5682 18.219C25.4781 18.2998 25.4057 18.3988 25.3557 18.5096C25.3056 18.6204 25.279 18.7407 25.2777 18.8625V19.0543C25.2777 21.0126 24.5099 22.8907 23.1433 24.2755C21.7767 25.6602 19.9232 26.4381 17.9906 26.4381C16.0579 26.4381 14.2044 25.6602 12.8378 24.2755C11.4712 22.8907 10.7035 21.0126 10.7035 19.0543C10.7285 18.9351 10.7286 18.8119 10.7038 18.6927C10.679 18.5734 10.6298 18.4607 10.5594 18.3619C10.489 18.2631 10.399 18.1803 10.2951 18.1189C10.1912 18.0575 10.0758 18.0188 9.95625 18.0053C9.83671 17.9919 9.71571 18.0039 9.60105 18.0408C9.48639 18.0776 9.3806 18.1383 9.29052 18.219C9.20043 18.2998 9.12804 18.3988 9.078 18.5096C9.02797 18.6204 9.0014 18.7407 9 18.8625V19.0543C8.99838 21.3002 9.82077 23.4666 11.3074 25.1324C12.794 26.7983 14.8387 27.8447 17.0442 28.0683V30.2739H14.0158C13.8021 30.2714 13.5952 30.3494 13.4352 30.4929C13.2752 30.6363 13.1735 30.8349 13.1499 31.0501C13.1263 31.2653 13.1825 31.4817 13.3075 31.6572C13.4326 31.8328 13.6175 31.9549 13.8265 32H21.7761C21.993 31.9552 22.1876 31.8347 22.326 31.6594C22.4643 31.4842 22.5376 31.2654 22.5332 31.0411C22.5332 30.6575 22.1546 30.3698 21.7761 30.2739H18.7477V28.0683C20.9799 27.8707 23.0596 26.8382 24.5817 25.1718C26.1037 23.5055 26.9591 21.3247 26.9811 19.0543Z' />
        <path d='M21.182 5.81802C20.3381 4.97411 19.1935 4.5 18 4.5C16.8065 4.5 15.6619 4.97411 14.818 5.81802C13.9741 6.66193 13.5 7.80653 13.5 9V19C13.5 20.1935 13.9741 21.3381 14.818 22.182C15.6619 23.0259 16.8065 23.5 18 23.5C19.1935 23.5 20.3381 23.0259 21.182 22.182C22.0259 21.3381 22.5 20.1935 22.5 19V9C22.5 7.80653 22.0259 6.66193 21.182 5.81802Z' strokeMiterlimit='10' />
      </svg>
      )
    : (
      <svg width='36' height='36' viewBox='0 0 36 36' xmlns='http://www.w3.org/2000/svg'>
        <path d='M26.9811 19.0543C27.0062 18.9351 27.0063 18.8119 26.9815 18.6927C26.9567 18.5734 26.9075 18.4607 26.8371 18.3619C26.7667 18.2631 26.6767 18.1803 26.5728 18.1189C26.4689 18.0575 26.3534 18.0188 26.2339 18.0053C26.1144 17.9919 25.9934 18.0039 25.8787 18.0408C25.764 18.0776 25.6583 18.1383 25.5682 18.219C25.4781 18.2998 25.4057 18.3988 25.3557 18.5096C25.3056 18.6204 25.279 18.7407 25.2777 18.8625V19.0543C25.2777 21.0126 24.5099 22.8907 23.1433 24.2755C21.7767 25.6602 19.9232 26.4381 17.9906 26.4381C16.0579 26.4381 14.2044 25.6602 12.8378 24.2755C11.4712 22.8907 10.7035 21.0126 10.7035 19.0543C10.7285 18.9351 10.7286 18.8119 10.7038 18.6927C10.679 18.5734 10.6298 18.4607 10.5594 18.3619C10.489 18.2631 10.399 18.1803 10.2951 18.1189C10.1912 18.0575 10.0758 18.0188 9.95625 18.0053C9.83671 17.9919 9.71571 18.0039 9.60105 18.0408C9.48639 18.0776 9.3806 18.1383 9.29052 18.219C9.20043 18.2998 9.12804 18.3988 9.078 18.5096C9.02797 18.6204 9.0014 18.7407 9 18.8625V19.0543C8.99838 21.3002 9.82077 23.4666 11.3074 25.1324C12.794 26.7983 14.8387 27.8447 17.0442 28.0683V30.2739H14.0158C13.8021 30.2714 13.5952 30.3494 13.4352 30.4929C13.2752 30.6363 13.1735 30.8349 13.1499 31.0501C13.1263 31.2653 13.1825 31.4817 13.3075 31.6572C13.4326 31.8328 13.6175 31.9549 13.8265 32H21.7761C21.993 31.9552 22.1876 31.8347 22.326 31.6594C22.4643 31.4842 22.5376 31.2654 22.5332 31.0411C22.5332 30.6575 22.1546 30.3698 21.7761 30.2739H18.7477V28.0683C20.9799 27.8707 23.0596 26.8382 24.5817 25.1718C26.1037 23.5055 26.9591 21.3247 26.9811 19.0543Z' />
        <path d='M21.182 5.81802C20.3381 4.97411 19.1935 4.5 18 4.5C16.8065 4.5 15.6619 4.97411 14.818 5.81802C13.9741 6.66193 13.5 7.80653 13.5 9V19C13.5 20.1935 13.9741 21.3381 14.818 22.182C15.6619 23.0259 16.8065 23.5 18 23.5C19.1935 23.5 20.3381 23.0259 21.182 22.182C22.0259 21.3381 22.5 20.1935 22.5 19V9C22.5 7.80653 22.0259 6.66193 21.182 5.81802Z' strokeMiterlimit='10' />
        <path d='M5.59199 5.59396C5.97341 5.21353 6.48938 5 7.0272 5C7.56503 5 8.08099 5.21353 8.46242 5.59396L30.408 27.5209C30.7872 27.9036 31 28.4213 31 28.9609C31 29.5006 30.7872 30.0182 30.408 30.4009C30.2189 30.5909 29.9944 30.7415 29.7473 30.8443C29.5001 30.9471 29.2352 31 28.9677 31C28.7002 31 28.4353 30.9471 28.1881 30.8443C27.941 30.7415 27.7165 30.5909 27.5274 30.4009L5.59199 8.47398C5.21282 8.09128 5 7.57359 5 7.03397C5 6.49436 5.21282 5.97666 5.59199 5.59396Z' fill='#FFBF3D' />
      </svg>

      )
}

export default Microphone
