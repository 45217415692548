import React from 'react'

import { MenuItem } from '@mui/material'
import { styled } from '@mui/material/styles'

const MenuItemBase = styled(MenuItem)(({ theme }) => ({
  borderRadius: '6px',
  padding: '6px 8px',
  margin: '0px 4px 4px 4px',
  maxHeight: '40px',
  ':hover': {
    backgroundColor: theme.palette.gray[200]
  },
  ':active': {
    backgroundColor: theme.palette.gray[300]
  },
  '&.Mui-selected': {
    // For Component Select
    backgroundColor: theme.palette.gray[300],
    ':hover': {
      backgroundColor: theme.palette.gray[300]
    },
    ':active': {
      backgroundColor: theme.palette.gray[300]
    },
    '&.Mui-focusVisible': {
      backgroundColor: theme.palette.gray[300],
      ':hover': {
        backgroundColor: theme.palette.gray[300]
      }
    }
  },
  '&.Mui-focusVisible': {
    // https://github.com/mui/material-ui/issues/23747
    backgroundColor: theme.palette.white[0],
    ':hover': {
      backgroundColor: theme.palette.gray[300]
    }
  }
}))

const FCMenuItem = (props) => {
  return (
    <MenuItemBase disableRipple {...props}>
      {props.children}
    </MenuItemBase>
  )
}

export default FCMenuItem
