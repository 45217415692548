import client from './client'

export const postAudiogramData = async (
  userName,
  leftAudiogramData,
  rightAudiogramData,
  isRemote
) => {
  try {
    await client().post(
      'audiologists/postaudiogramdata/', {
        left_audiogram: leftAudiogramData,
        right_audiogram: rightAudiogramData
      }, {
        params: {
          is_audiologist: 'True',
          clinic: isRemote ? 'False' : 'True',
          username: userName

        }
      }
    )
  } catch (e) {
    console.log('Error: ', e)
  }
}

export const patchSetAudiograms = async (
  selectedAppointment,
  leftData,
  rightData
) => {
  try {
    await client().patch(
      '/appointments/' + selectedAppointment.id, {
        left_audiogram: leftData,
        right_audiogram: rightData
      }, {
        params: {
          is_audiologist: 'True'
        }
      }
    )
  } catch (e) {
    console.log('Error: ', e)
  }
}

export const patchAudiogramClinic = async (id, leftAudiogram, rightAudiogram) => {
  await client().patch('/clinic/' + id, {
    left_audiogram: leftAudiogram,
    right_audiogram: rightAudiogram
  }, {
    params: {
      is_audiologist: 'True'
    }
  })
}

export const getAudiogram = async (userName) => {
  try {
    const res = await client().get('/audiologists/getaudiogram', {
      params: {
        is_audiologist: 'True',
        username: userName
      }
    })
    return res.data
  } catch (e) {
    console.log('Error: ', e)
  }
}

export const getAudiogramData = async (userName) => {
  try {
    const res = await client().get('/audiologists/getaudiogramdata', {
      params: {
        is_audiologist: 'True',
        username: userName
      }
    })
    return res.data
  } catch (e) {
    console.log('Error: ', e)
  }
}
