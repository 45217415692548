import React from 'react'
import { Box, styled, Typography, useTheme } from '@mui/material'
import FemaleIcon from 'assets/imgs/FemaleIcon.svg'
import MaleIcon from 'assets/imgs/MaleIcon.svg'
import FCMessageInput from 'components/MessageInput'
import { useAppointmentContext } from 'contexts/Appointment'
import { useCustomerContext } from 'contexts/Customer'

const Root = styled(Box)(({ theme }) => ({
  position: 'absolute',
  background: theme.palette.white[0],
  height: '720px',
  width: '360px',
  right: '376px',
  borderRadius: '12px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  [theme.breakpoints.down('xl')]: {
    height: '512px',
    width: '256px',
    right: '272px'
  },
  '.chat-box-header': {
    width: '100%',
    height: '88px',
    backgroundColor: theme.palette.black[700],
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 999,
    boxShadow: ' 2px 0px 40px rgba(0, 0, 0, 0.05)',
    img: {
      marginLeft: '32px',
      [theme.breakpoints.down('xl')]: {
        marginLeft: '8px'
      }
    },

    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      color: theme.palette.white[0],
      marginLeft: '8px',
      overflow: 'hidden',
      maxWidth: '254px',
      [theme.breakpoints.down('xl')]: {
        maxWidth: '180px'
      }
    }
  },
  '.chart-scrollable-field': {
    height: 'calc(100% - 152px)',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    '.char-box-message': {
      borderRadius: '12px',
      width: 'fit-content',
      maxWidth: '70%',
      padding: '15px 24px',
      wordWrap: 'break-word',
      marginTop: '24px'
    }
  },
  '.chat-box-input': {
    boxShadow: ' -2px 0px 40px rgba(0, 0, 0, 0.05)',
    padding: '16px 16px',
    [theme.breakpoints.down('xl')]: {
      height: '40px',
      padding: '16px 16px'
    }
  }
}))

export const ChatBox = props => {
  const {
    chatOn,
    dialogues,
    message,
    setMessage,
    handleSelectImage,
    handleSendMessage,
    Role
  } = props
  const theme = useTheme()
  const { currentAppointment } = useAppointmentContext()
  const { selectedCustomerEmail, selectedCustomer } = useCustomerContext()
  const Gender = {
    Female: 'Female',
    Male: 'Male'
  }
  return (
    <Root sx={{ display: chatOn ? 'block' : 'none' }}>
      <Box className='chat-box-header'>
        <img src={selectedCustomer && selectedCustomer.gender === Gender.Female ? FemaleIcon : MaleIcon} />
        <Box>
          <Typography variant='h3'>{currentAppointment && currentAppointment.userName}</Typography>
          <Typography variant='body12Med'>{selectedCustomerEmail}</Typography>
        </Box>
      </Box>
      <Box className='chart-scrollable-field'>
        {dialogues &&
          dialogues.map(item =>
            item.role === Role.User
              ? (
                <Box>
                  <Box
                    className='char-box-message'
                    style={{
                      backgroundColor: theme.palette.black[700],
                      marginLeft: '16px'
                    }}
                  >
                    {!item.message.image
                      ? (
                        <Typography
                          variant='body14Semi'
                          sx={{ color: theme.palette.white[0] }}
                        >
                          {item.message}
                        </Typography>
                        )
                      : (
                        <img
                          src={item.message.image}
                          resizeMode='contain'
                          style={{
                            width: 200,
                            marginTop: 10,
                            marginRight: 20,
                            padding: '5px 10px'
                          }}
                        />
                        )}
                  </Box>
                </Box>
                )
              : (
                <Box
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end'
                  }}
                >
                  <Box
                    className='char-box-message'
                    style={{
                      backgroundColor: theme.palette.black[200],
                      marginRight: '16px'
                    }}
                  >
                    {!item.message.image
                      ? (
                        <Typography variant='body14Semi'>{item.message}</Typography>
                        )
                      : (
                        <img
                          src={item.message.image}
                          resizeMode='contain'
                          style={{
                            width: 200,
                            marginTop: 10,
                            marginRight: 20,
                            padding: '5px 10px'
                          }}
                        />
                        )}
                  </Box>
                </Box>
                )
          )}
        <Box sx={{ marginTop: '24px' }} />
      </Box>
      <Box class='chat-box-input'>
        <FCMessageInput
          message={message}
          setMessage={setMessage}
          handleSelectImage={handleSelectImage}
          handleSendMessage={handleSendMessage}
        />
      </Box>
    </Root>
  )
}
