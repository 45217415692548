import React, { useEffect, useRef, useState } from 'react'
import { Container, Grid, Box } from '@mui/material'
import FCCalendar from 'components/Calendar'
import AppointmentList from 'pages/sections/AppointmentList'
import { useAudiologistContext } from 'contexts/Audiologist'
import { useAppointmentContext } from 'contexts/Appointment'
import { getCalendar } from 'services/apis/v1/audiologist'
import { arrangeCustomerAppointments, getAllRemoteAppointments, parseAppointmentsInfo } from 'services/customers'

function useSetInterval (callback) {
  const ref = useRef()
  useEffect(() => {
    ref.current = callback
  })

  useEffect(() => {
    const currentRef = () => {
      ref.current()
    }
    const timer = setInterval(currentRef, 1000)
    return () => clearInterval(timer)
  }, [])
}

const Appointment = () => {
  const { audiologistInfo } = useAudiologistContext()

  const [parsedEvents, setParsedEvents] = useState(null)
  const [parsedAppointments, setParsedAppointments] = useState(null)
  const [calendarEvents, setCalendarEvents] = useState(null)
  const {
    remoteAppointments,
    setRemoteAppointments,
    selectedAppointments,
    setSelectedAppointments,
    setNextAppointment,
    selectedDate,
    setSelectedDate,
    remoteNewAppointment,
    setRemoteNewAppointment
  } = useAppointmentContext()

  useSetInterval(() => {
    // judge the appointment state every second
    if (selectedAppointments && selectedAppointments.length) {
      const parseAppointments = parseAppointmentsInfo(selectedAppointments, setNextAppointment)
      setParsedAppointments(arrangeCustomerAppointments(parseAppointments))
    } else {
      setParsedAppointments([])
    }
  })

  useEffect(() => {
    const fetchRemoteAppointments = async () => {
      const appointments = await getAllRemoteAppointments()
      const calendar = await getCalendar()
      setRemoteAppointments(appointments)
      setCalendarEvents(calendar)
    }
    if (audiologistInfo) {
      fetchRemoteAppointments()
    }
  }, [audiologistInfo])

  useEffect(() => {
    // syncronize appointments and google calendar
    if (remoteAppointments && calendarEvents) {
      const resultEvents = []
      for (const event of calendarEvents) {
        event.start_time = new Date(event.start)
        event.end_time = new Date(event.end)
        for (const appointment of remoteAppointments) {
          appointment.start_time = new Date(appointment.start_time)
          appointment.end_time = new Date(appointment.end_time)
          if (
            appointment.user === event.user &&
            appointment.start_time - event.start_time === 0 &&
            appointment.status !== 2
          ) {
            event.username = appointment.username
            event.timezone = appointment.user_timezone
            resultEvents.push(event)
            break
          }
        }
      }
      setParsedEvents(resultEvents)
    }
  }, [remoteAppointments, calendarEvents, remoteNewAppointment])

  useEffect(() => {
    const results = []
    if (parsedEvents && selectedDate) {
      for (const event of parsedEvents) {
        if (
          event.start_time.toDateString() ===
          selectedDate.toDate().toDateString()
        ) {
          results.push(event)
        }
      }
      setSelectedAppointments(results)
    }
  }, [parsedEvents, selectedDate])

  return (
    <Box sx={{ marginTop: 'clamp(16px,calc((100vh - 826px)/2),104px)' }}>
      <Container
        fixed
        sx={{
          maxWidth: { xs: '1372px' },
          padding: { xs: '0' }
        }}
      >
        <Grid container spacing={2} wrap='nowrap'>
          <Grid item>
            <FCCalendar
              appointments={remoteAppointments}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              newAppointment={remoteNewAppointment}
              setNewAppointment={setRemoteNewAppointment}
            />
          </Grid>
          <Grid item>
            <AppointmentList
              selectedDate={selectedDate}
              parsedAppointments={parsedAppointments}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Appointment
