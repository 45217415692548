import client from './client'

export const getHistoricalGaintable = async userName => {
  try {
    const res = await client().get(
      '/audiologists/historicalgain', {
        params: {
          is_audiologist: 'True',
          username: userName
        }
      }
    )
    return res.data
  } catch (e) {
    console.log('Error: ', e)
  }
}

export const postGaintableBackend = async (
  userName,
  leftGaintable,
  rightGaintable,
  leftMPO,
  rightMPO,
  fittingNote,
  isRemote
) => {
  try {
    const res = await client().post(
      '/audiologists/postgaintable/', {
        left_gaintable: leftGaintable,
        right_gaintable: rightGaintable,
        left_mpo: leftMPO.length > 0 ? leftMPO : null,
        right_mpo: rightMPO.length > 0 ? rightMPO : null,
        note: fittingNote
      }, {
        params: {
          is_audiologist: 'True',
          clinic: isRemote ? 'False' : 'True',
          username: userName
        }
      }
    )
    return res.data.Gaintable
  } catch (e) {
    console.log('Error: ', e)
  }
}

export const patchGaintableClinic = async (id, leftGaintable, rightGaintable) => {
  await client().patch('/clinic' + id, {
    left_gaintable: leftGaintable,
    right_gaintable: rightGaintable
  }, {
    params: {
      is_audiologist: 'True'
    }
  })
}

export const postReviewGaintable = async (
  userName,
  leftGaintable,
  rightGaintable,
  leftAudiogramData,
  rightAudiogramData,
  leftMPO,
  rightMPO,
  fittingNote
) => {
  try {
    await client().post(
      '/audiologists/reviewgaintables/', {
        user: userName,
        left_audiogram: leftAudiogramData,
        right_audiogram: rightAudiogramData,
        left_gaintable: leftGaintable,
        right_gaintable: rightGaintable,
        left_mpo: leftMPO.length > 0 ? leftMPO : null,
        right_mpo: rightMPO.length > 0 ? rightMPO : null,
        note: fittingNote
      }, {
        params: {
          is_audiologist: 'True'
        }
      }
    )
  } catch (e) {
    console.log('Error: ', e)
  }
}
