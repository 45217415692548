import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { styled, useTheme } from '@mui/material/styles'
import { Box, FormControlLabel, Grid, Typography } from '@mui/material'
import { FCMenuItem } from 'components/Menu'
import FCSearchBar from 'components/SearchBar'
import FCCheckbox from 'components/Checkbox/FCCheckbox'
import { getFilterUser } from 'services/customers'
import { useCustomerContext } from 'contexts/Customer'
import { useTranslation } from 'react-i18next'

const Root = styled(Box)(({ theme }) => ({
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.03)',
  borderRadius: '12px',
  height: '720px',
  backgroundColor: theme.palette.white[0],
  overflow: 'hidden',
  '& .customer-list-search-bar': {
    padding: '7px 13px',
    '& > input': {
      marginLeft: '9px',
      marginRight: '9px'
    }
  },
  '& .customer-list-header': {
    padding: '0 14px 1px 6px',
    '& span': {
      color: theme.palette.black[550]
    }
  },
  '& .custom-list-divider': {
    height: '1px',
    backgroundColor: theme.palette.black[300],
    marginBottom: '7px'
  },
  '& .customer-list-content': {
    height: '550px',
    '& > li': {
      margin: '0 8px 2px 0',
      padding: '10px 6px'
    },
    '& .customer-list-date': {
      color: theme.palette.black[550]
    }
  }
}))

const CustomerList = () => {
  const theme = useTheme()
  const regions = ['US', 'CA', 'CN']

  const [checked, setChecked] = useState(Array(regions.length).fill(true))
  const [searchQuery, setSearchQuery] = useState('')
  const { allCustomers } = useCustomerContext()
  const [queryCustomers, setQueryCustomers] = useState(null)
  const [regionInfo, setRegionInfo] = useState(regions)
  const {
    selectedCustomer,
    setSelectedCustomer,
    setSelectedHistory
  } = useCustomerContext()
  const { t } = useTranslation()

  const handleRegionChange = (e, index) => {
    if (index < 0) {
      setChecked(Array(regions.length).fill(e.target.checked))
      if (e.target.checked) {
        setRegionInfo(regions)
      } else {
        setRegionInfo(['', '', ''])
      }
    } else {
      checked[index] = e.target.checked
      if (e.target.checked) {
        regionInfo[index] = regions[index]
      } else {
        regionInfo[index] = ''
      }
      setRegionInfo(regionInfo.slice(0))
      setChecked(checked.slice(0))
    }
  }
  const children = (
    <Box>
      {regions.map((item, index) => (
        <FormControlLabel
          key={index}
          label={item}
          sx={{
            margin: '0px 4px 4px 4px !important',
            background: checked[index] ? theme.palette.gray[300] : '',
            borderRadius: '6px',
            ':hover': {
              background: checked[index]
                ? `${theme.palette.gray[300]} !important`
                : theme.palette.gray[200]
            }
          }}
          control={
            <FCCheckbox
              checked={checked[index]}
              onChange={e => handleRegionChange(e, index)}
            />
          }
        />
      ))}
    </Box>
  )

  useEffect(() => {
    if (allCustomers) {
      setQueryCustomers(getFilterUser(allCustomers, searchQuery, regionInfo))
    }
  }, [searchQuery, regionInfo, allCustomers])

  return (
    <Root
      sx={{
        width: {
          xs: '256px',
          xl: '344px'
        }
      }}
    >
      <Grid container justifyContent='center'>
        <Grid container spacing={2} direction='column' sx={{ padding: '24px' }}>
          <Grid item>
            <Typography variant='h5'>{t('client_tab_client_list_section_title')}</Typography>
          </Grid>
          <Grid item>
            <FCSearchBar
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <FormControlLabel
                  sx={{
                    background:
                      checked.indexOf(false) === -1
                        ? theme.palette.gray[300]
                        : '',
                    borderRadius: '6px',
                    ':hover': {
                      background:
                        checked.indexOf(false) === -1
                          ? `${theme.palette.gray[300]} !important`
                          : theme.palette.gray[200]
                    }
                  }}
                  control={
                    <FCCheckbox
                      checked={checked.indexOf(false) === -1}
                      onChange={e => handleRegionChange(e, -1)}
                    />
                  }
                  label='ALL'
                />
                {children}
              </Box>
            </FCSearchBar>
          </Grid>
          <Grid item container direction='column'>
            <Grid item container className='customer-list-header'>
              <Grid item xs={6}>
                <Typography variant='body14Med'>{t('search_result_name')}</Typography>
              </Grid>
              <Grid item container xs justifyContent='right'>
                <Grid item>
                  <Typography variant='body14Med'>{t('search_result_date')}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className='custom-list-divider' />
            <Grid item>
              <Box overflow='auto'>
                <Box className='customer-list-content'>
                  {queryCustomers &&
                    queryCustomers.map((item, index) => (
                      <FCMenuItem
                        key={index}
                        onClick={() => {
                          setSelectedCustomer(item)
                          setSelectedHistory(null)
                        }}
                        sx={{
                          backgroundColor: item === selectedCustomer ? theme.palette.gray[300] : '',
                          ':hover': { backgroundColor: item === selectedCustomer ? theme.palette.gray[300] : '' }
                        }}
                      >
                        <Grid container>
                          <Grid item sx={{ overflow: 'hidden' }} xs xl>
                            <Typography variant='body14Med'>
                              {item.user_name}
                            </Typography>
                          </Grid>
                          <Grid item container xs={5} xl={3} justifyContent='right'>
                            <Grid item className='customer-list-date'>
                              <Typography variant='body14Med'>
                                {moment(item.start_time).format('MM/DD/YYYY')}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </FCMenuItem>
                    ))}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Root>
  )
}

export default CustomerList
