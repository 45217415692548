import axios from 'axios'

class RpcClient {
  constructor (rootUrl, secret) {
    this.domain = rootUrl
    this.api_root = rootUrl + '/api/v1/'
    this.secret = secret
    this.inited = false
    this.initing = false
    this.headers = {
      'Content-Type': 'application/json',
      Authorization: `Api-Key ${secret}`
    }
    this.session = axios.create({
      headers: {
        common: this.headers
      }
    })
  }

  async init () {
    if (this.inited === true) return true
    if (this.initing === true) {
      await new Promise(resolve => {
        const intervalCode = setInterval(() => {
          if (this.inited) {
            resolve()
            clearInterval(intervalCode)
          }
        }, 500)
      })
      return true
    } else {
      this.initing = true
      this.methods = await this.session
        .get(this.api_root)
        .then(res => res.data)
        .catch(() => {
          throw Error('Rpc Exception: Failed to acquire Methods')
        })
      this.inited = true
      this.initing = false
      return true
    }
  }

  serialize (obj) {
    const str = []
    for (const p in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
      }
    }
    return str.join('&')
  }

  getQueryStr (q) {
    const query = '?' + this.serialize(q)
    return query
  }

  getMethodUrl (method) {
    const methodurl = this.methods[method]
    if (methodurl) {
      return this.domain + methodurl
    } else {
      throw Error('Rpc Exception: Method does not exist')
    }
  }

  async call (method, kwargs) {
    const url = this.getMethodUrl(method)
    const payload = kwargs
    const res = await this.session.post(url, payload)
    return res.data
  }

  async query (method, kwargs) {
    const url = this.getMethodUrl(method)
    const payload = kwargs
    const res = await this.session
      .get(url + this.getQueryStr(payload))
    return res.data
  }
}

export default RpcClient
