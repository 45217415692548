import {
  getHistory,
  getNote,
  patchApproachingAppointment,
  getRemoteAppointments
} from './apis/v1/appointment'
import { getAudiogramData } from './apis/v1/audiogram'
import { getAllCustomers, getMedicalInfo } from './apis/v1/customer'
import moment from 'moment-timezone'
import {
  getRoom,
  getSpeechToken,
  getTwilioToken,
  generateGaintableV2,
  generateGaintableV4,
  getOutputData
} from './apis/v1/console'
import { Threshold, FREQUENCY, DeviceModel, OutputThreshold } from 'config'

const TIME_LIMIT = 300000
export const getUsername = appointment => {
  if (appointment.username === null && appointment.user_last_name === null) {
    return appointment.user
  } else if (appointment.user_last_name === null) {
    return appointment.username
  } else if (appointment.username === null) {
    return appointment.user_last_name
  } else {
    return appointment.username + ' ' + appointment.user_last_name
  }
}

export const compareTime = (property, desc = false) => {
  return function (time1, time2) {
    const value1 = new Date(time1[property])
    const value2 = new Date(time2[property])
    return desc ? value2 - value1 : value1 - value2
  }
}

export const getCustomerList = async () => {
  const res = await getAllCustomers()
  let customerList = []
  res.forEach(customer => {
    customerList.push({
      user: customer.user,
      start_time: customer.start_time,
      user_name: getUsername(customer),
      age: customer.user_age || '',
      gender: customer.user_gender || '',
      email: customer.user,
      state: customer.user_state,
      country: customer.user_country,
      first_name: customer.username || '',
      last_name: customer.user_last_name || ''
    })
  })
  // sort by start time
  customerList = customerList.sort(compareTime('start_time', true))
  return customerList
}

export const getFilterUser = (list, filterInfo, regionInfo) => {
  let result = list.slice(0)
  if (list && regionInfo) {
    result = result.filter(ele => {
      return ele.country ? regionInfo.includes(ele.country.toUpperCase()) : true
    })
  }
  if (list && filterInfo) {
    result = result.filter(ele =>
      (ele.email + ele.user_name)
        .toLowerCase()
        .includes(filterInfo.toLowerCase())
    )
  }
  return result
}

export const getCustomerMedicalInfo = async username => {
  const medicalInfo = await getMedicalInfo(username)
  const note = await getNote(username)
  let info = {
    chiefComplaint: '',
    experience: '',
    lifeStyle: '',
    note: ''
  }
  if (medicalInfo && note) {
    info = {
      chiefComplaint: medicalInfo.chief_complaint,
      experience: medicalInfo.hearing_aid_wear_experience,
      lifeStyle: medicalInfo.hearing_preference,
      note: note.comment
    }
  }
  return info
}

export const getCustomerHistory = async userName => {
  const res = await getHistory(userName)
  const historyList = res.sort(compareTime('start_time', true))
  return historyList
}

export const getAudiogramDataHistory = selectHistory => {
  const audiogramData = [[], [], [], []]
  const leftAudiogram = JSON.parse(selectHistory.left_audiogram)
  const rightAudiogram = JSON.parse(selectHistory.right_audiogram)
  if (leftAudiogram instanceof Array) {
    if (leftAudiogram.length > audiogramData.length) {
      if (!(leftAudiogram[0][0] instanceof Array)) {
        audiogramData[0].push(leftAudiogram)
        audiogramData[0].push(rightAudiogram)
        return audiogramData
      }
      return audiogramData
    }
    for (let i = 0; i < leftAudiogram.length; i++) {
      audiogramData[i].push(leftAudiogram[i])
      audiogramData[i].push(rightAudiogram[i])
    }
  }
  return audiogramData
}

export const getAllRemoteAppointments = async () => {
  const appointments = await getRemoteAppointments()
  return appointments
}

export const parseAppointmentsInfo = (
  selectedAppointments,
  setNextAppointment
) => {
  const parseAppointments = []
  const AppointmentStates = {
    Upcoming: 'upcoming',
    Available: 'available',
    Appointment: 'appointment',
    Completed: 'completed'
  }

  for (const appointment of selectedAppointments) {
    const now = new Date()
    const parseInfo = {
      clientTime: '',
      locatedTime: '',
      userName: '',
      email: '',
      appointmentState: '',
      startTime: '',
      endTime: ''
    }
    parseInfo.startTime = appointment.start_time
    parseInfo.endTime = appointment.end_time
    parseInfo.userName = appointment.username
    parseInfo.email = appointment.user
    parseInfo.locatedTime = `${appointment.start_time
      .getHours()
      .toString()
      .padStart(2, '0')}:${appointment.start_time
        .getMinutes()
        .toString()
        .padStart(2, '0')}-${appointment.end_time
          .getHours()
          .toString()
          .padStart(2, '0')}:${appointment.end_time
            .getMinutes()
            .toString()
            .padStart(2, '0')}`

    if (appointment.timezone) {
      const clientStartTime = moment
        .tz(appointment.start, appointment.timezone)
        .format('HH:mm')
      const clientEndTime = moment
        .tz(appointment.end, appointment.timezone)
        .format('HH:mm z')
      parseInfo.clientTime = `${clientStartTime} - ${clientEndTime}`
    }
    if (
      appointment.start_time - now <= TIME_LIMIT &&
      appointment.end_time - now > TIME_LIMIT
    ) {
      parseInfo.appointmentState = AppointmentStates.Upcoming
      if (appointment.start_time - now > 0) {
        setNextAppointment(appointment)
      }
    } else if (appointment.start_time - now > TIME_LIMIT) {
      parseInfo.appointmentState = AppointmentStates.Appointment
    } else if (now - appointment.end_time > 0) {
      parseInfo.appointmentState = AppointmentStates.Completed
    } else {
      parseInfo.appointmentState = AppointmentStates.Available
    }
    parseAppointments.push(parseInfo)
  }
  return parseAppointments
}

export const arrangeCustomerAppointments = appointments => {
  const arrangedResults = []
  const appointmentResults = []
  const completedResults = []
  const availableResults = []
  appointments.forEach(item => {
    if (item.appointmentState === 'upcoming') {
      arrangedResults.push(item)
    } else if (item.appointmentState === 'appointment') {
      appointmentResults.push(item)
    } else if (item.appointmentState === 'completed') {
      completedResults.push(item)
    } else {
      availableResults.push(item)
    }
  })
  if (appointmentResults.length) {
    arrangedResults.push(...appointmentResults.sort(compareTime('startTime')))
  }
  if (completedResults.length) {
    arrangedResults.push(...completedResults.sort(compareTime('startTime')))
  }
  if (availableResults.length) {
    arrangedResults.push(...availableResults.sort(compareTime('startTime')))
  }

  return arrangedResults
}

export const handleApprochingAppointment = async (
  user,
  startTime,
  selectedDate
) => {
  startTime = startTime.toISOString().substring(0, 19)
  await patchApproachingAppointment(user, startTime, selectedDate)
}

export const handleAudiogramData = async selectedCustomerEmail => {
  const res = await getAudiogramData(selectedCustomerEmail)
  if (res.count !== 0) {
    const leftAudiogramData = JSON.parse(res.results[0].left_audiogram)
    const rightAudiogramData = JSON.parse(res.results[0].right_audiogram)
    const leftTemp = [[], [], [], []]
    const rightTemp = [[], [], [], []]
    if (leftAudiogramData.length > leftTemp.length) {
      if (!(leftAudiogramData[0][0] instanceof Array)) {
        leftTemp[0].push(leftAudiogramData)
        rightTemp[0].push(rightAudiogramData)
        return {
          leftAudiogramData: leftTemp,
          rightAudiogramData: rightTemp
        }
      }
      return {
        leftAudiogramData: leftTemp,
        rightAudiogramData: rightTemp
      }
    }
    if (leftAudiogramData.length) {
      leftAudiogramData.forEach((item, index) => {
        leftTemp[index] = item
      })
    }

    if (rightAudiogramData.length) {
      rightAudiogramData.forEach((item, index) => {
        rightTemp[index] = item
      })
    }
    return {
      leftAudiogramData: leftTemp,
      rightAudiogramData: rightTemp
    }
  }
}

const transformAudiogramData = audiogramData => {
  const result = []
  for (const f of FREQUENCY) {
    result.push([f, -1])
  }
  for (const x of audiogramData) {
    if (FREQUENCY.indexOf(x[0]) !== -1) {
      result[FREQUENCY.indexOf(x[0])] = x
    }
  }
  let prev = 0
  let next = 0
  while (true) {
    next += 1
    if (result[prev][1] !== -1) {
      if (next < FREQUENCY.length) {
        prev = next
        continue
      } else {
        break
      }
    }
    if (prev === 0) {
      while (result[next][1] === -1) {
        next += 1
      }
      for (let i = prev; i < next; i++) {
        result[i][1] = result[next][1]
      }
      prev = next
    } else if (prev === FREQUENCY.length - 1) {
      result[prev][1] = result[prev - 1][1]
      break
    } else {
      let finish = false
      while (result[next][1] === -1) {
        if (next === FREQUENCY.length - 1) {
          for (let i = prev; i < next + 1; i++) {
            result[i][1] = result[prev - 1][1]
          }
          finish = true
          break
        }
        next += 1
      }
      if (finish) {
        break
      }
      const interval =
        (result[next][1] - result[prev - 1][1]) / (next - prev + 1)
      for (let i = prev; i < next; i++) {
        result[i][1] = parseInt((result[i - 1][1] + interval).toFixed())
      }
      prev = next
    }
  }
  return result
}

export const fetchGainTable = async (
  deviceModel,
  audiogramData,
  domeType,
  setGainTable,
  setMPO,
  setDataView,
  setDataOrigin) => {
  if (audiogramData[0].length) {
    let tempData = ''
    const parsedAudiogramData = transformAudiogramData(audiogramData[0])
    for (const i in parsedAudiogramData) {
      tempData += parsedAudiogramData[i][1] + ', '
    }
    tempData = tempData.slice(0, -2)
    let response
    let threshold
    if (deviceModel === DeviceModel.OrkaOne) {
      response = await generateGaintableV2(tempData)
      threshold = Threshold.OrkaOne
    } else if (deviceModel === DeviceModel.OrkaOnePro) {
      response = await generateGaintableV4(tempData, domeType.toLowerCase())
      threshold = Threshold.OrkaOnePro
    }
    const result = JSON.parse(JSON.stringify(response.Gaintable))
    const gaintable = result.map(i => i.map(Math.round))

    for (const i in gaintable) {
      for (const j in gaintable[0]) {
        if (gaintable[i][j] > threshold[j]) {
          gaintable[i][j] = threshold[j]
        }
      }
    }
    setGainTable(gaintable)
    setDataView(gaintable)
    setDataOrigin(gaintable)
    if (deviceModel === DeviceModel.OrkaOne) {
      setMPO([])
      return { gaintable, mpo: [] }
    } else if (deviceModel === DeviceModel.OrkaOnePro) {
      const mpo = [...response.MPO]
      const parsedMPO = mpo.map(Math.round)
      mpo.forEach((item, index) => {
        parsedMPO[index] =
          parsedMPO[index] > OutputThreshold[index]
            ? OutputThreshold[index]
            : parsedMPO[index]
      })
      setMPO(parsedMPO)
      return { gaintable, mpo: parsedMPO }
    }
  }
}

export const generateGainTable = async (
  deviceModel,
  leftAudiogramData,
  rightAudiogramData,
  leftDomeType,
  rightDomeType,
  setLeftGainData,
  setRightGainData,
  setLeftMPO,
  setRightMPO,
  setLeftGainDataView,
  setRightGainDataView,
  setLeftGainDataOrigin,
  setRightGainDataOrigin,
  setGainTableEditHistory,
  setEditingHistoryIndex,
  currentSession,
  setCurrentSession,
  setFittingData,
  fittingType
) => {
  const resLeft = await fetchGainTable(
    deviceModel,
    leftAudiogramData,
    leftDomeType,
    setLeftGainData,
    setLeftMPO,
    setLeftGainDataView,
    setLeftGainDataOrigin
  )

  const resRight = await fetchGainTable(
    deviceModel,
    rightAudiogramData,
    rightDomeType,
    setRightGainData,
    setRightMPO,
    setRightGainDataView,
    setRightGainDataOrigin
  )
  const recordName = `${fittingType} ${moment().format('DD/MM/YY hh:mm:ss')}`
  setFittingData(recordName)

  // replace current session if current session is a initial empty record, otherwise push a new session
  if (currentSession[0].leftGainData.length !== 0) {
    currentSession.push({
      record: recordName,
      fittingNote: '',
      leftGainData: resLeft.gaintable,
      rightGainData: resRight.gaintable,
      leftMPO: resLeft.mpo,
      rightMPO: resRight.mpo
    })
    setCurrentSession([...currentSession])
  } else {
    setCurrentSession([{
      record: recordName,
      fittingNote: '',
      leftGainData: resLeft.gaintable,
      rightGainData: resRight.gaintable,
      leftMPO: resLeft.mpo,
      rightMPO: resRight.mpo
    }])
  }

  setGainTableEditHistory(
    [
      {
        leftGainData: resLeft.gaintable,
        rightGainData: resRight.gaintable,
        leftMPO: resLeft.mpo,
        rightMPO: resRight.mpo
      }
    ]
  )
  setEditingHistoryIndex(0)
}

export const handleVideo = async (
  roomName,
  audiologist,
  setTwilioRoomId,
  setTwilioToken,
  setMicrosoftSpeechRecToken
) => {
  const twilioRoomId = await getRoom(roomName)
  const twilioToken = await getTwilioToken(roomName, audiologist)
  const speechToken = await getSpeechToken()
  setTwilioRoomId(twilioRoomId)
  setTwilioToken(twilioToken)
  setMicrosoftSpeechRecToken(speechToken)
}

export const fetchOutputData = async (
  gainData
) => {
  const outputData = await getOutputData(gainData)
  return outputData
}

export const fetchDeviceInfo = (deviceResults, setDeviceInfo) => {
  let leftBattery
  let rightBattery
  let leftVolume
  let rightVolume
  let denoiseMode
  if (deviceResults.name === 'device-info') {
    for (const item in deviceResults.values) {
      if (item === 'left-battery') {
        leftBattery = parseInt(deviceResults.values[item])
      } else if (item === 'right-battery') {
        rightBattery = parseInt(deviceResults.values[item])
      } else if (item === 'left-volume') {
        leftVolume = parseInt(deviceResults.values[item])
      } else if (item === 'right-volume') {
        rightVolume = parseInt(deviceResults.values[item])
      } else if (item === 'denoise-mode') {
        denoiseMode = parseInt(deviceResults.values[item])
      }
    }
    setDeviceInfo({
      leftBattery,
      leftVolume,
      rightBattery,
      rightVolume,
      denoiseMode
    })
  }
}
