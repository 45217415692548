const Logout = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M9.66663 1.03366L16.965 1L17 8.10473L9.66663 1.03366Z' fill='#FF3B30' />
      <path fillRule='evenodd' clipRule='evenodd' d='M6.28014 9.94614L13.6135 2.87507L15.0532 4.26331L7.71986 11.3344L6.28014 9.94614Z' fill='#FF3B30' />
      <path fillRule='evenodd' clipRule='evenodd' d='M0 2C0 0.895431 0.895431 0 2 0H8V2H3C2.44772 2 2 2.44772 2 3V15C2 15.5523 2.44772 16 3 16H15C15.5523 16 16 15.5523 16 15V10H18V16C18 17.1046 17.1046 18 16 18H2C0.895431 18 0 17.1046 0 16V2Z' fill='#FF3B30' />
    </svg>
  )
}

export default Logout
