const Chat = props => {
  const { newMessage } = props

  return newMessage
    ? (
      <svg width='36' height='36' viewBox='0 0 36 36' xmlns='http://www.w3.org/2000/svg'>
        <path d='M5 6.94737C5 5.31952 6.2934 4 7.88889 4H19.4444C21.0399 4 22.3333 5.31952 22.3333 6.94737V15.7895C22.3333 17.4173 21.0399 18.7368 19.4444 18.7368H15.1111L7.88889 24.6316L9.33333 18.7368H7.88889C6.2934 18.7368 5 17.4173 5 15.7895V6.94737Z' />
        <path d='M13.6667 23.1579V21.6842H19.4444C22.3333 21.6842 25.2222 20.2105 25.2222 17.2632V11.3684H28.1111C29.7066 11.3684 31 12.6879 31 14.3158V23.1579C31 24.7857 29.7066 26.1053 28.1111 26.1053H26.6667L28.1111 32L20.8889 26.1053H16.5556C14.9601 26.1053 13.6667 24.7857 13.6667 23.1579Z' />
        <rect x='28' y='2' width='6' height='6' rx='3' fill='#FFBF3D' />
      </svg>
      )
    : (
      <svg width='36' height='36' viewBox='0 0 36 36' xmlns='http://www.w3.org/2000/svg'>
        <path d='M5 6.94737C5 5.31952 6.2934 4 7.88889 4H19.4444C21.0399 4 22.3333 5.31952 22.3333 6.94737V15.7895C22.3333 17.4173 21.0399 18.7368 19.4444 18.7368H15.1111L7.88889 24.6316L9.33333 18.7368H7.88889C6.2934 18.7368 5 17.4173 5 15.7895V6.94737Z' />
        <path d='M13.6667 23.1579V21.6842H19.4444C22.3333 21.6842 25.2222 20.2105 25.2222 17.2632V11.3684H28.1111C29.7066 11.3684 31 12.6879 31 14.3158V23.1579C31 24.7857 29.7066 26.1053 28.1111 26.1053H26.6667L28.1111 32L20.8889 26.1053H16.5556C14.9601 26.1053 13.6667 24.7857 13.6667 23.1579Z' />
      </svg>
      )
}

export default Chat
