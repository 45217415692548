import React from 'react'
import { Box, Snackbar, styled } from '@mui/material'
import Close from 'components/Icon/Close'

const BoxBase = styled(Box)(({ theme }) => ({
  position: 'relative',
  background: theme.palette.white[0],
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  borderRadius: '12px',
  minWidth: '272px',
  padding: '24px',
  h4: {
    color: theme.palette.brand[700]
  }
}))

const FCNotification = (props) => {
  const { notificationState, setNotificationState, setValue } = props
  const { vertical, horizontal, open } = notificationState

  const handleClose = () => {
    setValue(null)
    setNotificationState({ ...notificationState, open: false })
  }
  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      key={vertical + horizontal}
    >
      <BoxBase>
        <Box sx={{ position: 'absolute', top: '8px', right: '8px', cursor: 'pointer' }} onClick={handleClose}>
          <Close />
        </Box>
        {props.children}
      </BoxBase>
    </Snackbar>
  )
}

export default FCNotification
