const Edit = props => {
  const { small } = props
  return small
    ? (
      <svg
        width='14'
        height='14'
        viewBox='0 0 14 14'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M8.05115 3.85157L10.1484 5.94885L4.94517 11.1521C4.89611 11.2012 4.8314 11.2315 4.7623 11.2378L2.32348 11.9988C2.13902 12.0155 1.98448 11.861 2.00125 11.6765L2.76223 9.2377C2.76851 9.1686 2.79882 9.10389 2.84789 9.05483L8.05115 3.85157Z' />
        <path d='M9.72897 2.17374C9.96063 1.94209 10.3362 1.94209 10.5679 2.17374L11.8263 3.43211C12.0579 3.66377 12.0579 4.03937 11.8263 4.27103L10.9873 5.10994L8.89006 3.01266L9.72897 2.17374Z' />
      </svg>
      )
    : (
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M11.6818 4.96251L15.0375 8.31816L6.71227 16.6434C6.63377 16.7219 6.53023 16.7704 6.41967 16.7804L2.51757 17.998C2.22243 18.0248 1.97517 17.7776 2.002 17.4824L3.21957 13.5803C3.22962 13.4698 3.27812 13.3662 3.35662 13.2877L11.6818 4.96251Z' />
        <path d='M14.3664 2.27799C14.737 1.90734 15.338 1.90734 15.7086 2.27799L17.722 4.29138C18.0927 4.66204 18.0927 5.26299 17.722 5.63364L16.3797 6.97591L13.0241 3.62025L14.3664 2.27799Z' />
      </svg>
      )
}
export default Edit
