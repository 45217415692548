import { createContext, useContext, useState } from 'react'
import { verifyToken } from 'services/apis/authentication'

const AuthenticationContext = createContext()

const useAuthenticationContext = () => {
  return useContext(AuthenticationContext)
}

const useAuthenticationStates = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null)
  const getAuthenticationState = async () => {
    const verifiedToken = await verifyToken()
    setIsAuthenticated(!!verifiedToken)
    return !!verifiedToken
  }

  return {
    getAuthenticationState,
    isAuthenticated,
    setIsAuthenticated
  }
}

export {
  AuthenticationContext,
  useAuthenticationContext,
  useAuthenticationStates
}
