import { createContext, useContext, useState } from 'react'

const DeviceContext = createContext({})

const useDeviceContext = () => {
  return useContext(DeviceContext)
}
const useDeviceStates = () => {
  const [deviceInfo, setDeviceInfo] = useState(null)

  return { deviceInfo, setDeviceInfo }
}

export { DeviceContext, useDeviceContext, useDeviceStates }
