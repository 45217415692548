import React, { useState } from 'react'
import { Grid, Typography } from '@mui/material'
import FCDialog from 'components/Dialog'
import FCButton from 'components/Button'
import FCRadio from 'components/Radio'
import GainIcon from 'assets/imgs/GainIcon.svg'
import { useTheme } from '@emotion/react'

export const AfcAlertDailog = props => {
  const { open, setOpen, showDisabled, setShowDisabled } = props
  const [isChecked, setIsChecked] = useState(false)
  const theme = useTheme()
  return (
    <FCDialog open={!showDisabled ? open : false} setOpen={setOpen}>
      <Grid container direction='column' wrap='nowrap'>
        <Grid item className='dialog-img'>
          <img src={GainIcon} alt='' />
        </Grid>
        <Grid item className='dialog-title'>
          <Typography variant='h5' sx={{ color: theme.palette.red[600] }}>
            Exceed AFC Threshold
          </Typography>
        </Grid>
        <Grid item className='dialog-description'>
          <Typography variant='body16Reg'>Gains that above AFC threshold may degrade the </Typography>
          <Typography variant='body16Semi'>Whistle Block </Typography>
          <Typography variant='body16Reg'>
            experience. If you still want to increase the gain, please remind
            the user to improve the experience by replacing the dome.
          </Typography>
        </Grid>
        <Grid
          item sx={{
            alignItems: 'center',
            textAlign: 'center',
            color: theme.palette.black[550],
            marginTop: '30px'
          }}
        >
          <FCRadio checked={isChecked} onClick={() => { setIsChecked(!isChecked) }} />
          <Typography variant='body14Med' sx={{ marginLeft: '8px' }}>Do not show this dialog during this session.</Typography>
        </Grid>
        <Grid item className='dialog-button'>
          <FCButton
            variant='contained'
            size='medium'
            onClick={() => {
              setOpen(false)
              setShowDisabled(isChecked)
            }}
            sx={{ marginRight: '0px !important' }}
          >
            confirm
          </FCButton>
        </Grid>
      </Grid>
    </FCDialog>
  )
}
