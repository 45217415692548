import React, { useEffect, useState } from 'react'
import { Badge, Box, styled, Typography, useTheme } from '@mui/material'
import { Camera, Chat, Microphone } from 'components/Icon'
import { ChatBox } from './ChatBox'
import Participant from './Participant'
import { connect } from 'twilio-video'
import createSpeechServicesPonyfill from 'web-speech-cognitive-services'
import { useWebSocketFittingContext } from 'contexts/WebSocket'
import { useAudiologistContext } from 'contexts/Audiologist'
import { useAppointmentContext } from 'contexts/Appointment'
import { handleVideo } from 'services/customers'
import SpeechRecognition, {
  useSpeechRecognition
} from 'react-speech-recognition'

const Root = styled(Box)(({ theme }) => ({
  position: 'relative',
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.03)',
  borderRadius: '12px',
  '.video-patient': {
    height: '720px',
    [theme.breakpoints.down('xl')]: {
      height: '512px'
    },
    position: 'relative',
    borderRadius: '12px',
    width: '100%',
    background: '#000'
  },
  '.video-audiologist': {
    height: '208px',
    width: '104px',
    [theme.breakpoints.down('xl')]: {
      height: '158px',
      width: '84px'
    },
    position: 'absolute',
    borderRadius: '12px',
    background: '#636363',
    top: '16px',
    right: '16px'
  },
  '.video-audiologist-only': {
    height: '720px',
    [theme.breakpoints.down('xl')]: {
      height: '512px'
    },
    position: 'relative',
    borderRadius: '12px',
    width: '100%',
    background: '#636363',
    filter: 'blur(5px)',
    zIndex: -1
  },
  '.video-hint': {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    top: '45%'
  },
  '.icon-container': {
    position: 'absolute',
    bottom: 24,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    '.icon-container-item': {
      width: 64,
      height: 64,
      position: 'relative',
      background: 'rgba(64, 64, 64, 0.7)',
      borderRadius: '32px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0 12px',
      cursor: 'pointer',
      fill: theme.palette.white[0],
      ':hover': {
        background: 'rgba(255, 255, 255, 0.8)',
        fill: theme.palette.black[700]
      }
    }
  }
}))

const VideoSection = () => {
  const theme = useTheme()
  const [videoOn, setVideoOn] = useState(true)
  const [audioOn, setAudioOn] = useState(true)
  const [participants, setParticipants] = useState([])
  const [room, setRoom] = useState(null)
  const [chatDotAlert, setChatDotAlert] = useState(false)
  const [chatOn, setChatOn] = useState(false)
  const [dialogues, setDialogues] = useState([])
  const [inComingDialogues, setIncomingDialogues] = useState([])
  const [selfFullsceen, setSelfFullscreen] = useState(false) // true means self fullscreen
  const [otherfull, setOtherFullscreen] = useState(false)
  const [message, setMessage] = useState('')
  const {
    currentAppointment,
    twilioRoom,
    setTwilioRoom,
    twilioToken,
    setTwilioToken,
    microsoftSpeechRecToken,
    setMicrosoftSpeechRecToken
  } = useAppointmentContext()
  const { transcript } = useSpeechRecognition()
  const { wsFitting } = useWebSocketFittingContext()
  const { audiologistInfo } = useAudiologistContext()
  const Role = {
    User: 'user',
    Audiologist: 'audiologist'
  }

  const startListening = () =>
    SpeechRecognition.startListening({
      continuous: true,
      language: 'en-US'
    })

  const handleSelectImage = () => {}

  useEffect(() => {
    const chatMessageListener = event => {
      const results = JSON.parse(event.data)
      if (results.name === 'send-message') {
        const preChats = JSON.parse(JSON.stringify(inComingDialogues))
        preChats.push({ role: Role.User, message: results.values.message })
        setIncomingDialogues(preChats)
        setChatDotAlert(true)
      }
    }
    if (wsFitting && wsFitting.readyState === WebSocket.OPEN) {
      wsFitting.onmessage = e => {
        chatMessageListener(e)
      }
    }
  }, [wsFitting])

  useEffect(() => {
    if (microsoftSpeechRecToken) {
      console.log(microsoftSpeechRecToken)
      const REGION = 'eastus'
      const {
        SpeechRecognition: AzureSpeechRecognition
      } = createSpeechServicesPonyfill({
        credentials: {
          region: REGION,
          authorizationToken: microsoftSpeechRecToken
        }
      })
      SpeechRecognition.applyPolyfill(AzureSpeechRecognition)
      startListening()
    }
  }, [microsoftSpeechRecToken])

  useEffect(() => {
    if (
      transcript &&
      transcript !== '' &&
      wsFitting &&
      wsFitting.readyState === WebSocket.OPEN
    ) {
      wsFitting.send(
        JSON.stringify({
          type: 'speech-recognition',
          data: {
            name: 'speech-recognition-result',
            values: {
              message: transcript.slice(-30)
            }
          }
        })
      )
    }
  }, [transcript])

  useEffect(() => {
    const connectVideoRoom = async () => {
      const roomName = audiologistInfo.username + '_' + currentAppointment.email
      handleVideo(
        roomName,
        audiologistInfo.username,
        setTwilioRoom,
        setTwilioToken,
        setMicrosoftSpeechRecToken
      )
    }
    if (audiologistInfo && currentAppointment) {
      connectVideoRoom()
    }
  }, [currentAppointment])

  useEffect(() => {
    const preChats = JSON.parse(JSON.stringify(dialogues))
    for (const i in inComingDialogues) {
      preChats.push(inComingDialogues[i])
    }
    setDialogues(preChats)
  }, [inComingDialogues])

  useEffect(() => {
    const participantConnected = participant => {
      setSelfFullscreen(false)
      setOtherFullscreen(true)
      setParticipants(prevParticipants => [...prevParticipants, participant])
      console.log(participants)
      if (room && room.localParticipant) {
        if (videoOn) {
          room.localParticipant.videoTracks.forEach(publication => {
            publication.track.enable()
          })
        } else {
          room.localParticipant.videoTracks.forEach(publication => {
            publication.track.disable()
          })
        }
      }
      if (room && room.localParticipant) {
        if (audioOn) {
          room.localParticipant.audioTracks.forEach(publication => {
            publication.track.enable()
          })
        } else {
          room.localParticipant.audioTracks.forEach(publication => {
            publication.track.disable()
          })
        }
      }
    }

    const participantDisconnected = participant => {
      setSelfFullscreen(true)
      setOtherFullscreen(false)
      setParticipants(prevParticipants =>
        prevParticipants.filter(p => p !== participant)
      )
    }
    if (twilioRoom && twilioToken) {
      connect(twilioToken, {
        name: twilioRoom,
        audio: true,
        video: {
          width: 2000
        }
      }).then(room => {
        setRoom(room)
        console.log(room)
        room.on('participantConnected', participantConnected)
        room.on('participantDisconnected', participantDisconnected)
        room.participants.forEach(participantConnected)
      })
    }

    return () => {
      setRoom(currentRoom => {
        if (currentRoom && currentRoom.localParticipant.state === 'connected') {
          currentRoom.localParticipant.tracks.forEach(function (
            trackPublication
          ) {
            trackPublication.track.stop()
          })
          currentRoom.disconnect()
          return null
        } else {
          return currentRoom
        }
      })
    }
  }, [twilioRoom, twilioToken])

  useEffect(() => {
    if (room && room.localParticipant) {
      if (videoOn) {
        room.localParticipant.videoTracks.forEach(publication => {
          publication.track.enable()
        })
      } else {
        room.localParticipant.videoTracks.forEach(publication => {
          publication.track.disable()
        })
      }
    }
  }, [videoOn])

  useEffect(() => {
    if (room && room.localParticipant) {
      if (audioOn) {
        room.localParticipant.audioTracks.forEach(publication => {
          publication.track.enable()
        })
      } else {
        room.localParticipant.audioTracks.forEach(publication => {
          publication.track.disable()
        })
      }
    }
    if (!audioOn) {
      SpeechRecognition.abortListening()
    }
  }, [audioOn])

  const handleSendMessage = () => {
    const preChats = JSON.parse(JSON.stringify(dialogues))
    if (
      message !== '' &&
      wsFitting &&
      wsFitting.readyState === WebSocket.OPEN
    ) {
      preChats.push({ role: Role.Audiologist, message })
      // send message with webSocket
      wsFitting.send(
        JSON.stringify({
          type: 'message',
          data: {
            name: 'send-message',
            values: {
              role: Role.Audiologist,
              message
            }
          }
        })
      )
      setDialogues(preChats)
      setMessage('')
    }
  }

  return (
    <Root
      sx={{
        minWidth: { xl: '360px', xs: '256px' },
        height: { xl: '720px', xs: '512px' },
        background: theme.palette.black[600]
      }}
    >
      <ChatBox
        chatOn={chatOn}
        dialogues={dialogues}
        message={message}
        setMessage={setMessage}
        handleSelectImage={handleSelectImage}
        handleSendMessage={handleSendMessage}
        Role={Role}
      />
      <Box>
        {room
          ? (
            <Box>
              <Box>
                <Participant
                  key={room.localParticipant.sid}
                  participant={room.localParticipant}
                  fullscreen={selfFullsceen}
                  setFullscreen={setSelfFullscreen}
                  setOtherFullscreen={setOtherFullscreen}
                />
              </Box>
              <Box>
                {participants[0]
                  ? (
                    <Participant
                      key={participants[0].sid}
                      participant={participants[0]}
                      fullscreen={otherfull}
                      setFullscreen={setOtherFullscreen}
                      setOtherFullscreen={setSelfFullscreen}
                    />
                    )
                  : (
                    <Box className='video-hint'>
                      <Typography
                        variant='h5'
                        sx={{ color: theme.palette.white[0] }}
                      >
                        Waiting for connection...
                      </Typography>
                    </Box>
                    )}
              </Box>
            </Box>
            )
          : (
            <Box />
            )}
      </Box>
      <Box
        className='icon-container'
        sx={{ display: participants ? 'flex' : 'none' }}
      >
        <Box
          className='icon-container-item'
          onClick={() => {
            setChatOn(!chatOn)
            setChatDotAlert(false)
          }}
        >
          <Badge
            variant='dot'
            overlap='circular'
            color='warning'
            invisible={!chatDotAlert}
          >
            <Chat />
          </Badge>
        </Box>
        <Box
          className='icon-container-item'
          onClick={() => setVideoOn(!videoOn)}
        >
          <Camera on={videoOn} />
        </Box>
        <Box
          className='icon-container-item'
          onClick={() => setAudioOn(!audioOn)}
        >
          <Microphone on={audioOn} />
        </Box>
      </Box>
    </Root>
  )
}

export default VideoSection
