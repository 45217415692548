import React from 'react'
import { styled } from '@mui/material/styles'
import { Image, Share } from 'components/Icon'
import { FCInput } from 'components/Input'
import { Box } from '@mui/material'
const InputBase = styled(FCInput)(({ theme }) => ({
  height: '32px',
  padding: '6px 12px',
  ...theme.typography.body14Med,
  color: theme.palette.black[800],
  border: `1px solid ${theme.palette.brand[600]}`,
  background: theme.palette.white[0],
  caretColor: theme.palette.brand[600],
  fill: theme.palette.black[700],
  '& > input': {
    marginLeft: '8px'
  },
  ':hover': {
    '&& fieldset': {
      border: `1px solid ${theme.palette.brand[600]}`,
      boxShadow: `0 0 0 2px ${theme.palette.brand[300]}`
    }
  },
  '&.Mui-focused': {
    '&& fieldset': {
      border: `1px solid ${theme.palette.brand[600]}`,
      boxShadow: `0 0 0 2px ${theme.palette.brand[300]}`
    }
  }
}))

const FCMessageInput = (props) => {
  const { message, setMessage, handleSelectImage, handleSendMessage } = props

  const handleKeyPressed = e => {
    if (e.charCode === 13) {
      handleSendMessage()
    }
  }
  return (
    <InputBase
      startAdornment={<Box sx={{ height: '20px', cursor: 'pointer' }} onClick={() => handleSelectImage()}><Image /></Box>}
      endAdornment={<Box sx={{ height: '20px', cursor: 'pointer' }} onClick={() => handleSendMessage()}> <Share /></Box>}
      placeholder='Type here...'
      value={message}
      onKeyPress={(e) => {
        handleKeyPressed(e)
      }}
      onChange={(e) => {
        setMessage(e.target.value)
      }}
    />
  )
}

export default FCMessageInput
