const Menu = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='1' y='3' width='22' height='2.72727' rx='1' />
      <rect x='1' y='10.6364' width='22' height='2.72727' rx='1' />
      <rect x='1' y='18.2727' width='22' height='2.72727' rx='1' />
    </svg>
  )
}

export default Menu
