import React from 'react'
import { Grid } from '@mui/material'

import FittingHistory from 'pages/sections/CustomerProfile/FittingHistory'
import Profile from 'pages/sections/CustomerProfile/Profile'
import Audiogram from 'pages/sections/CustomerProfile/Audiogram'
import Gain from 'pages/sections/CustomerProfile/Gain'

const Customer = () => {
  return (
    <Grid
      container
      spacing={2}
      flexWrap='nowrap'
    >
      <Grid item>
        <Profile />
      </Grid>
      <Grid item container direction='column' spacing={2}>
        <Grid item container spacing={2}>
          <Grid item>
            <FittingHistory />
          </Grid>
          <Grid item>
            <Audiogram />
          </Grid>
        </Grid>
        <Grid item>
          <Gain />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Customer
