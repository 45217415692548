import React, { useState } from 'react'
import { FormControlLabel, Grid, RadioGroup, Typography, useTheme } from '@mui/material'
import FCDialog from 'components/Dialog'
import FCButton from 'components/Button'
import FCRadio from 'components/Radio'
import GainIcon from 'assets/imgs/CheckIcon.svg'
import { useGaintableContext } from 'contexts/Gaintable'
import { DeviceModel } from 'config'
import { useTranslation } from 'react-i18next'

export const FormulaSettingDialog = (props) => {
  const { open, setOpen, handlegainGenerate, generateGainLoading, setGenerateGainLoading } = props
  const { deviceModel, setDeviceModel } = useGaintableContext()
  const [formulaVersion, setFormulaVersion] = useState(deviceModel)
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <FCDialog open={open}>
      <Grid container direction='column' wrap='nowrap'>
        <Grid item className='dialog-img'>
          <img src={GainIcon} alt='' />
        </Grid>
        <Grid item className='dialog-title'>
          <Typography variant='h5'>{t('formulat_setting_dialog_generate_gain_title')}</Typography>
        </Grid>
        <Grid item className='dialog-description'>
          <Typography variant='body16Reg'>
            {t('formula_setting_dialog_select_formula_description')}
          </Typography>
        </Grid>
        <Grid
          item
          container
          justifyContent='center'
          sx={{
            marginTop: '24px',
            paddingLeft: '35px',
            span: { ...theme.typography.body14Med, marginRight: '8px' }
          }}
        >
          <RadioGroup
            value={formulaVersion}
            onChange={e => setFormulaVersion(e.target.value)}
          >
            <FormControlLabel
              value={DeviceModel.OrkaOne}
              control={<FCRadio />}
              label='Orka One'
              sx={{ marginBottom: '14px' }}
            />
            <FormControlLabel
              value={DeviceModel.OrkaOnePro}
              control={<FCRadio />}
              label='Orka One Pro'
            />
          </RadioGroup>
        </Grid>
        <Grid item className='dialog-button'>
          <FCButton
            variant='outlined'
            size='medium'
            onClick={() => {
              setOpen(false)
              setGenerateGainLoading(false)
            }}
          >
            {t('formula_setting_dialog_cancle_button')}
          </FCButton>
          <FCButton
            variant='contained'
            size='medium'
            loading={generateGainLoading}
            onClick={() => {
              setDeviceModel(formulaVersion)
              handlegainGenerate(formulaVersion)
            }}
          >
            {t('formula_setting_dialog_next_button')}
          </FCButton>
        </Grid>
      </Grid>
    </FCDialog>
  )
}
