import { createContext, useContext, useState } from 'react'

const GlobalContext = createContext({})

const useGlobalContext = () => {
  return useContext(GlobalContext)
}

const useGlobalStates = () => {
  const [fittingType, setFittingType] = useState('')
  return {
    fittingType,
    setFittingType
  }
}

export { useGlobalContext, useGlobalStates, GlobalContext }
