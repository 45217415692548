import React from 'react'
import { Tabs } from '@mui/material'
import { styled } from '@mui/material/styles'

const TabsBase = styled(Tabs)(({ theme }) => ({
  minHeight: 0,
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    height: '4px',
    backgroundColor: 'transparent',
    bottom: '6px',
    '& .MuiTabs-indicatorSpan': {
      maxWidth: '40px',
      width: '100%',
      borderRadius: '2px',
      backgroundColor: theme.palette.brand[500]
    }
  }
}))

const FCTabs = props => {
  const { textColor } = props

  return textColor === 'secondary'
    ? (
      <TabsBase
        TabIndicatorProps={{
          style: {
            display: 'none'
          }
        }}
        {...props}
      />
      )
    : (
      <TabsBase
        TabIndicatorProps={{
          children: <span className='MuiTabs-indicatorSpan' />
        }}
        {...props}
      />
      )
}

export default FCTabs
