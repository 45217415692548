import * as React from 'react'
import { createComponent } from '@lit-labs/react'
import { OrkaOutputChart } from '@evocolabs/orka-charts'

const OrkaOutputChartWc = createComponent(
  React,
  'orka-output',
  OrkaOutputChart
)

export default OrkaOutputChartWc
