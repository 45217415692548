import * as React from 'react'
import { createComponent } from '@lit-labs/react'
import { OrkaAudiogramDisplay } from '@evocolabs/orka-charts'

const OrkaAudiogramDisplayWc = createComponent(
  React,
  'orka-audiogram-display',
  OrkaAudiogramDisplay
)

export default OrkaAudiogramDisplayWc
