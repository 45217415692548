const WarningCircle = () => {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M13.4 7C13.4 10.5346 10.5346 13.4 7 13.4C3.46538 13.4 0.6 10.5346 0.6 7C0.6 3.46538 3.46538 0.6 7 0.6C10.5346 0.6 13.4 3.46538 13.4 7Z' stroke='#F03738' strokeWidth='1.2' strokeMiterlimit='10' />
      <path d='M7 3.5V7.58333' stroke='#F03738' strokeWidth='1.2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7.00001 10.8878C7.42956 10.8878 7.77778 10.5395 7.77778 10.11C7.77778 9.68042 7.42956 9.3322 7.00001 9.3322C6.57045 9.3322 6.22223 9.68042 6.22223 10.11C6.22223 10.5395 6.57045 10.8878 7.00001 10.8878Z' fill='#F03738' />
    </svg>
  )
}

export default WarningCircle
