import React from 'react'
import { Tab } from '@mui/material'
import { styled } from '@mui/material/styles'

const TabBase = styled(Tab)(({ theme }) => ({
  ...theme.typography.tab1,
  color: theme.palette.black[550],
  minHeight: 0,
  height: '64px',
  padding: '12px 22px',
  ':hover': {
    color: theme.palette.black[800]
  },
  '&.Mui-selected': {
    color: theme.palette.black[800]
  },
  '&.MuiTab-textColorSecondary': {
    ...theme.typography.tab2,
    height: '40px',
    padding: '10px 26px',
    '&.Mui-selected': {
      color: theme.palette.white[0],
      backgroundColor: theme.palette.black[800]
    }
  }
}))

const FCTab = props => {
  return <TabBase disableRipple {...props} />
}

export default FCTab
