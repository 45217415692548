const palette = {
  brand: {
    800: '#DB872C',
    700: '#F7870D',
    600: '#F9AA1E',
    500: '#FFBF3D',
    400: '#F9CB5A',
    300: '#F9DB87',
    200: '#FCEBB4',
    100: '#FFF9DE'
  },
  gray: {
    800: '#262B37',
    600: '#B1B7C2',
    500: '#CDD2DD',
    400: '#E6EAF1',
    300: '#EFF1F6',
    200: '#F7F8FA',
    100: '#EFF2F633'
  },
  black: {
    900: '#000',
    800: '#1A1A1A',
    700: '#404040',
    600: '#636363',
    550: '#828282',
    500: '#999999',
    400: '#CDCDCD',
    300: '#E6E6E6',
    200: '#F1F1F1',
    100: '#F7F7F7',
    50: '#F9F9F9'
  },
  red: {
    800: '#781C1C',
    700: '#B4292A',
    600: '#FF3B30',
    300: '#FFA1A1',
    200: '#FFDFDF',
    100: '#FDEBEB'
  },
  blue: {
    600: '#004CFF',
    300: '#AEC2F2'
  },
  white: {
    100: '#F7FAFE',
    0: '#FFF'
  },
  illustration: {
    200: '#D3D9D9',
    100: '#E5E2D5'
  },
  green: {
    400: '#A6C95D'
  }
}

export default palette
