import React, { useState } from 'react'

import { Select } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Chevron } from 'components/Icon'

const SelectBase = styled(Select)(({ theme }) => ({
  height: '36px',
  ...theme.typography.body14Med,
  color: theme.palette.black[900],
  '&& fieldset': {
    borderRadius: '6px'
  },
  '&& svg': {
    display: 'inline-block',
    flexShrink: 0,
    position: 'absolute',
    right: '12px',
    pointerEvents: 'none'
  },
  ':hover': {
    '&& fieldset': {
      border: `1px solid ${theme.palette.brand[600]}`,
      boxShadow: `0 0 0 2px ${theme.palette.brand[300]}`
    }
  },
  '&.Mui-focused': {
    '&& fieldset': {
      border: `1px solid ${theme.palette.brand[600]}`,
      boxShadow: `0 0 0 2px ${theme.palette.brand[300]}`
    }
  },
  '&.Mui-disabled': {
    '&& fieldset': {
      border: '1px solid rgba(0, 0, 0, 0.23)',
      boxShadow: 'none'
    }
  }
}))

const FCSelect = (props) => {
  const [openMenu, setOpenMenu] = useState(false)
  return (
    <SelectBase
      {...props}
      IconComponent={() => openMenu ? <Chevron /> : <Chevron down />}
      onOpen={() => setOpenMenu(true)}
      onClose={() => setOpenMenu(false)}
    >
      {props.children}
    </SelectBase>
  )
}

export default FCSelect
