import jwtDecode from 'jwt-decode'

import RpcClient from 'services/utils/RpcClient'

const rpcClient = new RpcClient(
  process.env.REACT_APP_RPC_URL,
  process.env.REACT_APP_RPC_CLIENT_SECRET
)

export const verifyToken = async () => {
  if (
    window &&
    window.localStorage.getItem('token') &&
    window.localStorage.getItem('refreshToken')
  ) {
    const decodeAccess = jwtDecode(window.localStorage.getItem('token'))
    const decodeRefresh = jwtDecode(window.localStorage.getItem('refreshToken'))
    const expireAccessTime = decodeAccess.exp
    const expireRefreshTime = decodeRefresh.exp
    const currTime = Date.now() / 1000

    if (expireRefreshTime < currTime) {
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('refreshToken')
    } else if (expireAccessTime < currTime) {
      await rpcClient.init()
      const res = await rpcClient.call('user-refresh_token', {
        refresh_token: window.localStorage.getItem('refreshToken'),
        client_id: 'orka-frontend-dev'
      })
      if (res) {
        window.localStorage.setItem('token', res.access_token)
        window.localStorage.setItem('refreshToken', res.refresh_token)
      }
    }
  }
  return window.localStorage.getItem('token')
}

export const login = async (username, password) => {
  await rpcClient.init()
  const loginResponse = await rpcClient
    .call('user-log_in', {
      username,
      password,
      client_id: 'orka-frontend-dev'
    })
    .then(e => e)
  if (loginResponse) {
    window.localStorage.setItem('token', loginResponse.access_token)
    window.localStorage.setItem('refreshToken', loginResponse.refresh_token)
  }
  return loginResponse
}

export const logout = () => {
  window.localStorage.removeItem('token')
  window.localStorage.removeItem('refreshToken')
}
