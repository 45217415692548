import React, { useState, useEffect } from 'react'
import { Container, Grid, useTheme, Box } from '@mui/material'
import {
  Outlet,
  Routes,
  Route,
  useNavigate,
  useLocation
} from 'react-router-dom'
import moment from 'moment'

import Appointment from './Appointment'
import Audiogram from './Audiogram'
import Customer from './Customer'
import DeviceInfo from './DeviceInfo'
import FineTuning from './FineTuning'

import { FCTabs, FCTab } from 'components/Tab'
import FCButton from 'components/Button'
import { SessionEndDialog } from 'pages/sections/SessionEndDialog'
import { ProfileUnsavedDialog } from 'pages/sections/CustomerProfile/ProfileUnsavedDialog'
import { AudiogramUnsavedDialog } from 'pages/sections/AudiogramSection/AudiogramUnsavedDialog'
import { usePageContext } from 'contexts/Page'
import { CounterDownTimer } from 'pages/sections/CounterDownTimer'
import { useCustomerContext } from 'contexts/Customer'
import { useAppointmentContext } from 'contexts/Appointment'
import { useGaintableContext } from 'contexts/Gaintable'
import { useWebSocketFittingContext } from 'contexts/WebSocket'
import { useTranslation } from 'react-i18next'
import VideoSection from 'pages/sections/VideoSection'
import { useAudiologistContext } from 'contexts/Audiologist'
import { getHistoricalGaintable } from 'services/apis/v1/gaintable'

const RemoteFitting = () => {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<Appointment />} />
        <Route path='appointment' element={<Appointment />} />
        <Route path='customer' element={<Customer />} />
        <Route path='audiogram' element={<Audiogram />} />
        <Route path='device' element={<DeviceInfo />} />
        <Route path='fine-tuning' element={<FineTuning />} />
      </Route>
    </Routes>
  )
}

const Layout = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const location = useLocation()
  const { t } = useTranslation()
  const pages = [
    'appointment',
    'customer',
    'audiogram',
    'device',
    'fine-tuning'
  ]
  const currentPage = location.pathname.split('/')[2]
  const currentIndex = pages.indexOf(currentPage)
  const initialPage = currentIndex < 0 ? 0 : currentIndex
  const [page, setPage] = useState(null)
  const [nextPage, setNextPage] = useState(null)
  const [sessionEndDialogOpen, setSessionEndDialogOpen] = useState(false)
  const [profileUnsavedOpen, setProfileUnsavedOpen] = useState(false)
  const [audiogramUnsavedOpen, setAudiogramUnsavedOpen] = useState(false)
  const { unsavedPageType, setUnsavedPageType, PageType } = usePageContext()
  const {
    setSelectedCustomerEmail,
    setSelectedCustomer,
    setSelectedHistory
  } = useCustomerContext()
  const { audiologistInfo } = useAudiologistContext()
  const { currentAppointment, setCurrentAppointment } = useAppointmentContext()
  const {
    initialData,
    setCurrentSession,
    setPreviousSession,
    setFittingData,
    setGainTableEditHistory,
    setEditingHistoryIndex
  } = useGaintableContext()
  const { wsFitting, createWebSocketFitting } = useWebSocketFittingContext()

  const handleLeave = nextPage => {
    if (unsavedPageType === PageType.CustomerProfile) {
      setProfileUnsavedOpen(false)
    }
    if (unsavedPageType === PageType.Audiogram) {
      setAudiogramUnsavedOpen(false)
    }
    setUnsavedPageType(null)
    setPage(nextPage)
  }

  const handleEndSession = nextPage => {
    initialData()
    setSessionEndDialogOpen(false)
    setSelectedCustomerEmail(null)
    setCurrentAppointment(null)
    setSelectedCustomer(null)
    setSelectedHistory(null)
    setPage(0)
  }
  useEffect(() => {
    if (page === 0) {
      navigate('appointment')
    } else if (page === 1) {
      navigate('customer')
    } else if (page === 2) {
      navigate('audiogram')
    } else if (page === 3) {
      navigate('device')
    } else if (page === 4) {
      navigate('fine-tuning')
    }
  }, [page])

  useEffect(() => {
    const currentPage = location.pathname.split('/')[2]
    const currentIndex = pages.indexOf(currentPage)
    if (currentIndex >= 0) {
      setPage(currentIndex)
    }
  }, [location])

  useEffect(() => {
    const recordNow = `InClinic ${moment().format('DD/MM/YY hh:mm:ss')}`
    const fetchGainTableInfo = async email => {
      const results = await getHistoricalGaintable(email)
      setCurrentSession([
        {
          record: recordNow,
          fittingNote: '',
          leftGainData: [],
          rightGainData: [],
          leftMPO: [],
          rightMPO: []
        }
      ])
      setFittingData(recordNow)
      setGainTableEditHistory([
        {
          leftGainData: JSON.parse(results[0].left_gaintable),
          rightGainData: JSON.parse(results[0].right_gaintable),
          leftMPO: JSON.parse(results[0].left_mpo),
          rightMPO: JSON.parse(results[0].right_mpo),
          fittingNote: results[0].note || ''
        }
      ])
      setEditingHistoryIndex(0)
    }
    const fetchGainTableHistory = async email => {
      const history = await getHistoricalGaintable(email)
      const previousSessionTemp = []
      if (history.length) {
        history.forEach((item, index) => {
          previousSessionTemp.push({
            record: `${item.source.split('_')[0]} ${moment(item.time).format(
              'DD/MM/YY hh:mm:ss'
            )}`,
            fittingNote: item.note || '',
            leftGainData: JSON.parse(item.left_gaintable),
            rightGainData: JSON.parse(item.right_gaintable),
            leftMPO: item.left_mpo ? JSON.parse(item.left_mpo) : '',
            rightMPO: item.right_mpo ? JSON.parse(item.right_mpo) : ''
          })
        })
        setPreviousSession(previousSessionTemp)
      }
    }
    if (audiologistInfo && currentAppointment && wsFitting === null) {
      createWebSocketFitting(audiologistInfo.username, currentAppointment.email)
    }
    if (audiologistInfo && currentAppointment) {
      fetchGainTableInfo(currentAppointment.email)
      fetchGainTableHistory(currentAppointment.email)
    }
  }, [currentAppointment])

  return (
    <div>
      <Grid
        container
        justifyContent='space-between'
        sx={{
          boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.03)',
          backgroundColor: theme.palette.white[0],
          maxHeight: '40px'
        }}
      >
        <Grid item xs={3} />
        <Grid item>
          <FCTabs
            value={page || initialPage}
            onChange={(event, newValue) => {
              if ((page || initialPage) === 0 && !unsavedPageType) {
                setPage(newValue)
              } else {
                setNextPage(newValue)
                if (unsavedPageType === PageType.CustomerProfile) {
                  setProfileUnsavedOpen(true)
                } else if (unsavedPageType === PageType.Audiogram) {
                  setAudiogramUnsavedOpen(true)
                } else {
                  if (newValue === 0) {
                    setCurrentAppointment(null)
                    setSelectedCustomerEmail(null)
                  }
                  setPage(newValue)
                }
              }
            }}
            textColor='secondary'
          >
            <FCTab label={t('console_appointment_tab_appointment')} />
            <FCTab label={t('console_appointment_tab_client')} />
            <FCTab label={t('audiogram_tab')} />
            <FCTab label={t('device_info_tab')} />
            <FCTab label={t('fine-tuning_tab')} />
          </FCTabs>
        </Grid>

        {(page || initialPage) === 0
          ? (
            <Grid item xs={3} />
            )
          : (
            <Grid item xs={3}>
              <Grid container alignItems='center' justifyContent='flex-end'>
                {currentAppointment ? <CounterDownTimer /> : <></>}
                <Grid item>
                  <FCButton
                    variant='contained'
                    size='navRight'
                    disabled={!currentAppointment}
                    onClick={() => setSessionEndDialogOpen(true)}
                  >
                    {t('end_session_button')}
                  </FCButton>
                </Grid>
              </Grid>
            </Grid>
            )}
      </Grid>
      {(page || initialPage) === 0
        ? (
          <Outlet />
          )
        : (
          <Box sx={{ marginTop: 'clamp(16px,calc((100vh - 826px)/2),48px)' }}>
            <Container
              fixed
              sx={{
                maxWidth: { xl: '1760px', xs: '1407px' },
                padding: { xs: '0' }
              }}
            >
              <Grid container spacing={2} wrap='nowrap'>
                <Grid item>
                  <Outlet />
                </Grid>
                <Grid item>
                  <VideoSection />
                </Grid>
              </Grid>
            </Container>
          </Box>
          )}

      <SessionEndDialog
        open={sessionEndDialogOpen}
        setOpen={setSessionEndDialogOpen}
        nextPage={nextPage}
        page={page}
        handleEndSession={handleEndSession}
      />
      <ProfileUnsavedDialog
        open={
          unsavedPageType === PageType.CustomerProfile && profileUnsavedOpen
        }
        setOpen={setProfileUnsavedOpen}
        nextPage={nextPage}
        handleLeave={handleLeave}
      />
      <AudiogramUnsavedDialog
        open={unsavedPageType === PageType.Audiogram && audiogramUnsavedOpen}
        setOpen={setAudiogramUnsavedOpen}
        nextPage={nextPage}
        handleLeave={handleLeave}
      />
    </div>
  )
}

export default RemoteFitting
