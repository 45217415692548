import React, { useState, useEffect } from 'react'
import {
  Outlet,
  Routes,
  Route,
  useNavigate,
  useLocation
} from 'react-router-dom'
import {
  Grid,
  Box,
  Typography,
  useTheme
} from '@mui/material'
import moment from 'moment'
import {
  getHistoricalGaintable
} from 'services/apis/v1/gaintable'
import { useGaintableContext } from 'contexts/Gaintable'
import { handleAudiogramData } from 'services/customers'
import { FCTabs, FCTab } from 'components/Tab'
import Edit from 'components/Icon/Edit'
import FCButton from 'components/Button'
import Audiogram from './Audiogram'
import Customer from './Customer'
import FineTuning from './FineTuning'
import { EmailEditDialog } from './EmailEditDialog'
import { useCustomerContext } from 'contexts/Customer'
import { usePageContext } from 'contexts/Page'
import { ProfileUnsavedDialog } from 'pages/sections/CustomerProfile/ProfileUnsavedDialog'
import { AudiogramUnsavedDialog } from 'pages/sections/AudiogramSection/AudiogramUnsavedDialog'
import { getConfirmUser } from 'services/apis/v1/customer'
import { useTranslation } from 'react-i18next'

const BackendFitting = () => {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<Customer />} />
        <Route path='customer' element={<Customer />} />
        <Route path='audiogram' element={<Audiogram />} />
        <Route path='fine-tuning' element={<FineTuning />} />
      </Route>
    </Routes>
  )
}

const Layout = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const location = useLocation()
  const { t } = useTranslation()
  const pages = ['customer', 'audiogram', 'fine-tuning']
  const currentPage = location.pathname.split('/')[2]
  const currentIndex = pages.indexOf(currentPage)
  const initialPage = currentIndex < 0 ? 0 : currentIndex
  const [page, setPage] = useState(null)
  const [emailDialog, setEmailDialog] = useState({
    open: false,
    error: false,
    email: ''
  })
  const [userEmail, setUserEmail] = useState('')
  const [nextPage, setNextPage] = useState(null)
  const [profileUnsavedOpen, setProfileUnsavedOpen] = useState(false)
  const [audiogramUnsavedOpen, setAudiogramUnsavedOpen] = useState(false)
  const { setSelectedCustomerEmail, setSelectedCustomer } = useCustomerContext()
  const { unsavedPageType, setUnsavedPageType, PageType } = usePageContext()
  const [confirmUserLoading, setConfirmUserLoading] = useState(false)
  const {
    setGainTableEditHistory,
    setCurrentSession,
    setEditingHistoryIndex,
    setPreviousSession,
    setLeftAudiogramData,
    setRightAudiogramData,
    setFittingData
  } = useGaintableContext()
  const handleLeave = (nextPage) => {
    if (unsavedPageType === PageType.CustomerProfile) {
      setProfileUnsavedOpen(false)
    }
    if (unsavedPageType === PageType.Audiogram) {
      setAudiogramUnsavedOpen(false)
    }
    setUnsavedPageType(null)
    setPage(nextPage)
  }

  const handleSetEmail = async () => {
    if (isValidEmail(emailDialog.email)) {
      setConfirmUserLoading(true)
      const emailIsExist = await getConfirmUser(emailDialog.email)
      setConfirmUserLoading(false)

      if (emailIsExist) {
        // judge the exist user
        setUserEmail(emailDialog.email)
        setEmailDialog({ open: false, email: '', error: false })
        setSelectedCustomer(null)
        setSelectedCustomerEmail(emailDialog.email)
        const recordNow = `Backend ${moment().format('DD/MM/YY hh:mm:ss')}`
        const fetchGainTableInfo = async () => {
          const results = await getHistoricalGaintable(emailDialog.email)
          setCurrentSession([
            {
              record: recordNow,
              fittingNote: '',
              leftGainData: [],
              rightGainData: [],
              leftMPO: [],
              rightMPO: []
            }
          ])
          setFittingData(recordNow)
          setGainTableEditHistory([
            {
              leftGainData: JSON.parse(results[0].left_gaintable),
              rightGainData: JSON.parse(results[0].right_gaintable),
              leftMPO: JSON.parse(results[0].left_mpo),
              rightMPO: JSON.parse(results[0].right_mpo),
              fittingNote: results[0].note || ''
            }
          ])
          setEditingHistoryIndex(0)
        }
        const fetchGainTableHistory = async () => {
          const history = await getHistoricalGaintable(emailDialog.email)
          const previousSessionTemp = []
          if (history.length) {
            history.forEach((item, index) => {
              previousSessionTemp.push({
                record: `${item.source.split('_')[0]} ${moment(item.time).format('DD/MM/YY hh:mm:ss')}`,
                fittingNote: item.note || '',
                leftGainData: JSON.parse(item.left_gaintable),
                rightGainData: JSON.parse(item.right_gaintable),
                leftMPO: item.left_mpo ? JSON.parse(item.left_mpo) : '',
                rightMPO: item.right_mpo ? JSON.parse(item.right_mpo) : ''
              })
            })
            setPreviousSession(previousSessionTemp)
          }
        }
        const fetchInfo = async () => {
          const results = await handleAudiogramData(emailDialog.email)
          setLeftAudiogramData(results.leftAudiogramData)
          setRightAudiogramData(results.rightAudiogramData)
        }
        setGainTableEditHistory([{ leftGainData: [], rightGainData: [] }])
        setEditingHistoryIndex(0)
        fetchGainTableInfo()
        fetchGainTableHistory()
        fetchInfo()
      } else {
        setEmailDialog({ ...emailDialog, error: true })
      }
    } else {
      setEmailDialog({ ...emailDialog, error: true })
    }
  }

  // judge the correct email
  const isValidEmail = email => {
    return /\S+@\S+\.\S+/.test(email)
  }

  useEffect(() => {
    if (page === 0) {
      navigate('customer')
    } else if (page === 1) {
      navigate('audiogram')
    } else if (page === 2) {
      navigate('fine-tuning')
    }
  }, [page])

  useEffect(() => {
    const currentPage = location.pathname.split('/')[2]
    const currentIndex = pages.indexOf(currentPage)
    if (currentIndex >= 0) {
      setPage(currentIndex)
    }
  }, [location])

  return (
    <div>
      <Grid
        container
        justifyContent='space-between'
        sx={{
          boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.03)',
          backgroundColor: theme.palette.white[0],
          maxHeight: '40px'
        }}
      >
        <Grid item xs={4}>
          <Grid container alignItems='center'>
            <Grid item>
              <FCButton
                variant='contained'
                size='navLeft'
                disabled={!!userEmail}
                onClick={() => {
                  setEmailDialog({ ...emailDialog, open: true })
                }}
              >
                user email
              </FCButton>
            </Grid>
            {userEmail
              ? (
                <Grid item sx={{ marginLeft: '20px', display: 'flex' }}>
                  <Box
                    sx={{
                      maxWidth: { xl: '380px', xs: '270px' },
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      lineHeight: '34px'
                    }}
                  >
                    <Typography
                      variant='body16Semi'
                      sx={{ color: theme.palette.brand[600] }}
                    >
                      {userEmail}
                    </Typography>
                  </Box>
                  <FCButton
                    variant='text'
                    startIcon={<Edit small />}
                    sx={{ padding: '10px 12px !important' }}
                    onClick={() => setEmailDialog({ ...emailDialog, open: true })}
                  />
                </Grid>)
              : <></>}
          </Grid>
        </Grid>
        <Grid item>
          <FCTabs
            value={page || initialPage}
            onChange={(event, newValue) => {
              if ((page || initialPage) === 0 && !unsavedPageType) {
                setPage(newValue)
              } else {
                setNextPage(newValue)
                if (unsavedPageType === PageType.CustomerProfile) {
                  setProfileUnsavedOpen(true)
                } else if (unsavedPageType === PageType.Audiogram) {
                  setAudiogramUnsavedOpen(true)
                } else {
                  setPage(newValue)
                }
              }
            }}
            textColor='secondary'
            TabIndicatorProps={{
              children: <span className='MuiTabs-indicatorSpanNone' />
            }}
          >
            <FCTab label={t('console_appointment_tab_client')} />
            <FCTab label={t('audiogram_tab')} />
            <FCTab label={t('fine-tuning_tab')} />
          </FCTabs>
        </Grid>
        <Grid item xs={4} />
      </Grid>
      <Outlet />
      <EmailEditDialog emailDialog={emailDialog} setEmailDialog={setEmailDialog} handleSetEmail={handleSetEmail} confirmUserLoading={confirmUserLoading} />
      <ProfileUnsavedDialog open={unsavedPageType === PageType.CustomerProfile && profileUnsavedOpen} setOpen={setProfileUnsavedOpen} nextPage={nextPage} handleLeave={handleLeave} />
      <AudiogramUnsavedDialog open={unsavedPageType === PageType.Audiogram && audiogramUnsavedOpen} setOpen={setAudiogramUnsavedOpen} nextPage={nextPage} handleLeave={handleLeave} />
    </div>
  )
}

export default BackendFitting
