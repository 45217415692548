import React, { useState, useEffect, useRef } from 'react'

const Participant = ({ participant, fullscreen, setFullscreen, setOtherFullscreen }) => {
  const [videoTracks, setVideoTracks] = useState([])
  const [audioTracks, setAudioTracks] = useState([])

  const videoRef = useRef()
  const audioRef = useRef()

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null)

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks))
    setAudioTracks(trackpubsToTracks(participant.audioTracks))

    const trackSubscribed = (track) => {
      if (track.kind === 'video') {
        setVideoTracks((videoTracks) => [...videoTracks, track])
      } else if (track.kind === 'audio') {
        setAudioTracks((audioTracks) => [...audioTracks, track])
      }
    }

    const trackUnsubscribed = (track) => {
      if (track.kind === 'video') {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track))
      } else if (track.kind === 'audio') {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track))
      }
    }

    participant.on('trackSubscribed', trackSubscribed)
    participant.on('trackUnsubscribed', trackUnsubscribed)

    return () => {
      setVideoTracks([])
      setAudioTracks([])
      participant.removeAllListeners()
    }
  }, [participant])

  useEffect(() => {
    const videoTrack = videoTracks[0]
    if (videoTrack) {
      videoTrack.attach(videoRef.current)
      return () => {
        videoTrack.detach()
      }
    }
  }, [videoTracks])

  useEffect(() => {
    const audioTrack = audioTracks[0]
    if (audioTrack) {
      audioTrack.attach(audioRef.current)
      return () => {
        audioTrack.detach()
      }
    }
  }, [audioTracks])

  return (
    <div style={{ position: 'static' }}>
      {fullscreen
        ? (
          <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
            <video ref={videoRef} autoPlay style={{ position: 'relative', borderRadius: 15, left: '50%', transform: 'translateX(-50%)', height: '100%', width: '100%', objectFit: 'cover' }} />
            <audio ref={audioRef} autoPlay />
          </div>)
        : (
          <div
            onClick={() => { setOtherFullscreen(fullscreen); setFullscreen(!fullscreen) }}
            style={{
              position: 'absolute',
              top: '3%',
              right: 20,
              borderRadius: 15,
              width: '25%',
              height: '25%',
              backgroundColor: 'black',
              zIndex: 100,
              overflow: 'hidden'
            }}
          >
            <video ref={videoRef} autoPlay style={{ position: 'relative', borderRadius: 15, left: '50%', transform: 'translateX(-50%)', height: '100%', width: '100%', objectFit: 'cover' }} />
            <audio ref={audioRef} autoPlay />
          </div>)}
    </div>
  )
}

export default Participant
