import React from 'react'

import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

const HelperTextBase = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.gray[600],
  ...theme.typography.body14Reg,
  margin: '4px 0 0 4px',
  svg: {
    marginRight: '4px'
  }
}))

const HelperTextError = styled(HelperTextBase)(({ theme }) => ({
  color: theme.palette.red[600]
}))

const FCHelperText = props => {
  const { type } = props
  let styledHelperText
  switch (type) {
    case 'error':
      styledHelperText = (
        <HelperTextError>
          {props.children}
        </HelperTextError>
      )
      break
    default:
      styledHelperText = (
        <HelperTextBase>
          {props.children}
        </HelperTextBase>
      )
  }

  return styledHelperText
}

export default FCHelperText
