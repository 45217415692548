import { useLocation, Navigate } from 'react-router-dom'
import { useAuthenticationContext } from 'contexts/Authentication'
import { useEffect, useState } from 'react'
import { useGlobalContext } from 'contexts/Global'
const RequireAuthentication = ({ children }) => {
  const { isAuthenticated, getAuthenticationState } = useAuthenticationContext()
  const location = useLocation()
  const [nextPage, setNextPage] = useState(null)
  const { setFittingType } = useGlobalContext()

  useEffect(() => {
    const routeSection = location.pathname.split('/')[1]
    switch (routeSection) {
      case 'in-clinic-fitting': {
        setFittingType('Inclinic')
        break
      }
      case 'remote-fitting': {
        setFittingType('Remote')
        break
      }
      case 'backend-fitting': {
        setFittingType('Backend')
      }
    }
    const checkAuthentication = async () => {
      await getAuthenticationState()
      if (isAuthenticated !== null) {
        setNextPage(
          isAuthenticated
            ? children
            : <Navigate to='/login' state={{ from: location }} replace />
        )
      }
    }
    checkAuthentication()
  })

  return nextPage
}

export { RequireAuthentication }
