export const Threshold = {
  OrkaOne: [13, 25, 39, 39, 31, 31, 19, 5],
  OrkaOnePro: [35, 47, 48, 43, 44, 40, 40, 44]
}

export const OutputThreshold = [60, 85, 105, 103, 105, 102, 90, 80]

export const AfcThreshold = {
  OrkaOnePro: [26, 39, 48, 41, 40, 40, 30, 25]
}

export const DomeType = {
  Open: 'Open',
  Vented: 'Vented',
  Double: 'Double',
  Customized: 'Customized'
}

export const DomeSize = {
  Large: 'Large',
  Medium: 'Medium',
  Small: 'Small'
}

export const DeviceModel = {
  OrkaOne: 'OrkaOne',
  OrkaOnePro: 'OrkaOnePro'
}

export const FREQUENCY = [
  125,
  250,
  500,
  750,
  1000,
  1500,
  2000,
  3000,
  4000,
  6000,
  8000
]

export const FittingRange = {
  OrkaOne: [
    [125, 25],
    [500, 25],
    [1000, 5],
    [2000, 5],
    [4000, 15],
    [8000, 15]
  ],
  OrkaOnePro: [
    [125, 5],
    [250, 5],
    [500, 5],
    [750, 5],
    [1000, 5],
    [1500, 0],
    [2000, -5],
    [3150, -5],
    [4000, -5],
    [6300, -5],
    [8000, -5]
  ]
}

export const GainBand = {
  3: [
    'fine-tuning_gain_table_title_low',
    'fine-tuning_gain_table_title_med',
    'fine-tuning_gain_table_title_high'
  ],
  8: [250, 500, 1000, 2000, 3150, 4000, 6300, 8000]
}
