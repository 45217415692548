import React from 'react'
import { Grid, Typography, useTheme, Box } from '@mui/material'
import MenuIcon from 'assets/imgs/Menu.svg'
import FCDialog from 'components/Dialog'
import FCButton from 'components/Button'
import { useTranslation } from 'react-i18next'

export default function ErrorHintDialog (props) {
  const { open, setOpen } = props
  const { t } = useTranslation()

  return (
    <FCDialog
      open={open}
    >
      <Grid container direction='column' wrap='nowrap'>
        <Grid item className='dialog-img'>
          <img src={MenuIcon} alt='' />
        </Grid>
        <Grid item className='dialog-title'>
          <Typography variant='h5'>{t('device_info_section_hint_please_select_dome_type')}</Typography>
        </Grid>
        <Grid item className='dialog-description'>
          <Typography variant='body16Reg'>
            {t('device_info_section_hint_need_dome_type')}
          </Typography>
        </Grid>
        <Box sx={{ textAlign: 'center', m: '48px 0px 40px 0px' }}>
          <FCButton
            variant='contained'
            size='medium'
            onClick={() => setOpen(false)}
          >
            {t('device_info_section_dialog_confirm_button')}
          </FCButton>
        </Box>

      </Grid>
    </FCDialog>
  )
}
