import React from 'react'

import { Menu } from '@mui/material'
import { styled } from '@mui/material/styles'

const MenuBase = styled(Menu)({
  '& .MuiPaper-root': {
    // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  }
})

const FCMenu = (props) => {
  return <MenuBase {...props}>{props.children}</MenuBase>
}

export default FCMenu
