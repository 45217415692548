import React from 'react'
import AudiogramSection from 'pages/sections/AudiogramSection'

const Audiogram = () => {
  return (
    <AudiogramSection isRemote />
  )
}

export default Audiogram
