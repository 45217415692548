import { createContext, useContext, useState } from 'react'

const ClinicFittingContext = createContext({})

const useClinicFittingContext = () => {
  return useContext(ClinicFittingContext)
}

const useClinicFittingStates = () => {
  const [clinicFitting, setClinicFitting] = useState(null)
  const [clinicSession, setClinicSession] = useState(false)
  const [code, setCode] = useState(null)

  return {
    clinicFitting,
    setClinicFitting,
    clinicSession,
    setClinicSession,
    code,
    setCode
  }
}

export { ClinicFittingContext, useClinicFittingContext, useClinicFittingStates }
