import React from 'react'
import { Container, Box } from '@mui/material'
import AudiogramSection from 'pages/sections/AudiogramSection'

const Audiogram = () => {
  return (
    <Box sx={{ marginTop: 'clamp(16px,calc((100vh - 826px)/2),48px)' }}>
      <Container
        fixed
        sx={{
          maxWidth: { xl: '1552px', xs: '1407px' },
          padding: { xs: '0' }
        }}
      >
        <AudiogramSection />
      </Container>
    </Box>
  )
}

export default Audiogram
