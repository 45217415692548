import * as React from 'react'
import { createComponent } from '@lit-labs/react'
import { OrkaAudiogram } from '@evocolabs/orka-charts'

const OrkaAudiogramWc = createComponent(
  React,
  'orka-audiogram',
  OrkaAudiogram
)

export default OrkaAudiogramWc
