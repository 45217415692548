import React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import FCButton from 'components/Button'
import { useTranslation } from 'react-i18next'

const AppointmentCardBase = styled('div')(({ theme }) => ({
  width: '672px',
  height: '120px',
  borderRadius: '12px',
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.03)',
  background: theme.palette.white[0],
  display: 'flex',
  position: 'relative',

  '& .appointment-state-line': {
    width: '12px',
    height: '88px',
    margin: '16px 12px 16px 16px',
    borderRadius: '12px'
  },
  '& .appointment-state-line-upcoming': {
    background: theme.palette.brand[700]
  },
  '& .appointment-state-line-appointment': {
    background: theme.palette.brand[500]
  },
  '& .appointment-state-line-available': {
    background: theme.palette.green[400]
  },
  '& .appointment-state-line-completed': {
    background: theme.palette.black[400]
  },
  '& .appointment-information': {
    margin: '16px 0px',
    '& .email': {
      color: theme.palette.black[550],
      height: '18px',
      ...theme.typography.body14Reg
    },
    '& .username': {
      color: theme.palette.black[800],
      height: '20px',
      ...theme.typography.text1,
      ':hover': {
        textDecoration: 'underline',
        cursor: 'pointer'
      }
    },
    '& .client-time': {
      height: '18px',
      ...theme.typography.body14Reg
    },
    '& .located-time': {
      height: '28px',
      ...theme.typography.h1
    },
    '& .available-time': {
      ...theme.typography.body16Med,
      height: '20px',
      marginTop: '22px'
    },
    '& .client-time-upcoming': {
      color: theme.palette.brand[700]
    },
    '& .client-time-appointment': {
      color: theme.palette.black[800]
    },
    '& .client-time-available': {
      color: theme.palette.black[800]
    },
    '& .client-time-completed': {
      color: theme.palette.black[500]
    },
    '& .located-time-upcoming': {
      color: theme.palette.brand[700]
    },
    '& .located-time-appointment': {
      color: theme.palette.black[800]
    },
    '& .located-time-available': {
      color: theme.palette.black[800]
    },
    '& .located-time-completed': {
      color: theme.palette.black[500]
    },
    '& .username-completed': {
      color: theme.palette.black[500]
    }
  },
  '& .appointment-button': {
    position: 'absolute',
    right: 0,
    margin: '40px'
  },
  '& .user-information': {
    marginTop: '4px'
  }
}))

const FCAppointmentCard = props => {
  const theme = useTheme()
  const {
    appointmentState,
    appointmentInfo,
    setTargetCustomer,
    handleApprochingAppointment,
    handleNavigateToFitting,
    setCurrentAppointment,
    setSelectedCustomerEmail
  } = props

  const AppointmentStates = {
    Upcoming: 'upcoming',
    Available: 'available',
    Appointment: 'appointment',
    Completed: 'completed'
  }
  const { t } = useTranslation()

  return (
    <AppointmentCardBase
      style={{
        border:
          appointmentState === AppointmentStates.Upcoming ? `2px solid${theme.palette.brand[700]}` : '',
        boxSizing: 'border-box'
      }}
    >
      <div
        className={`appointment-state-line appointment-state-line-${appointmentState}`}
      />
      <div
        className={`appointment-information appointment-information-${appointmentState}`}
      >
        <div className={`client-time client-time-${appointmentState}`}>
          {appointmentInfo.clientTime}
        </div>
        <div className={`located-time located-time-${appointmentState}`}>
          {appointmentInfo.locatedTime}
        </div>
        {appointmentState === AppointmentStates.Available
          ? (
            <div className='available-time'>Available time</div>
            )
          : (
            <div className='user-information'>
              <div
                className={`username username-${appointmentState}`}
                onClick={() => setTargetCustomer()}
              >
                {appointmentInfo.userName}
              </div>
              <div className='email'>{appointmentInfo.email}</div>
            </div>
            )}
      </div>
      {appointmentState === AppointmentStates.Available
        ? null
        : (
          <div className='appointment-button'>
            <FCButton
              variant='contained'
              size='medium'
              disabled={appointmentState !== AppointmentStates.Upcoming}
              onClick={
                appointmentState === AppointmentStates.Upcoming
                  ? () => {
                      handleApprochingAppointment()
                      handleNavigateToFitting()
                      setCurrentAppointment(appointmentInfo)
                      setSelectedCustomerEmail(appointmentInfo.email)
                    }
                  : null
              }
            >
              {t('appointmend_card_go_fitting_button')}
            </FCButton>
          </div>
          )}
    </AppointmentCardBase>
  )
}

export default FCAppointmentCard
