import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import FCButton from 'components/Button/FCButton'
import { FCHelperText } from 'components/Input'
import { useAuthenticationContext } from 'contexts/Authentication'
import { Grid, Box, Typography, styled, FormControl, TextField, InputLabel, OutlinedInput, InputAdornment, IconButton } from '@mui/material'
import { Eye, OrkaLogo, WarningCircle } from 'components/Icon'
import { login } from 'services/apis/authentication'
import { useAudiologistContext } from 'contexts/Audiologist'

const LoginHeader = styled(Grid)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.white[0],
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.03)',
  padding: '14px 0'
}))

const LoginContent = styled(Grid)(({ theme }) => ({
  padding: '69px 56px 20px 58px',
  background: theme.palette.white[0],
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.03)',
  width: '512px',
  height: '520px',
  borderRadius: '12px',
  caretColor: theme.palette.brand[600],
  fieldset: {
    border: 'none'
  },
  '.MuiInputLabel-outlined': {
    ...theme.typography.body16Med
  },
  '.MuiInputLabel-shrink': {
    ...theme.typography.body12Med,
    color: theme.palette.black[550],
    top: 16
  },
  '.MuiFormHelperText-root': {
    margin: '0'
  },
  '.login-username': {
    '.Mui-focused': {
      color: `${theme.palette.black[550]} !important`
    },
    input: {
      borderRadius: '6px',
      color: theme.palette.black[900],
      backgroundColor: `${theme.palette.white[0]} !important`,
      ...theme.typography.body16Med,
      padding: '20px 16px 7px 16px',
      border: `1px solid ${theme.palette.gray[500]}`,
      ':hover': {
        border: `1px solid ${theme.palette.brand[600]}`,
        boxShadow: `0 0 0 2px ${theme.palette.brand[300]}`
      },
      '&.Mui-focused': {
        border: `1px solid ${theme.palette.brand[600]}`,
        boxShadow: `0 0 0 2px ${theme.palette.brand[300]}`
      }
    }
  },
  '.login-password': {
    marginTop: '16px',
    '.Mui-focused': {
      color: `${theme.palette.black[550]} !important`
    },
    '.MuiInputBase-formControl': {
      borderRadius: '6px',
      input: {
        color: theme.palette.black[900],
        padding: '20px 16px 7px 16px'
      },
      backgroundColor: `${theme.palette.white[0]} !important`,
      ...theme.typography.body16Med,
      // padding: '20px 16px 7px 16px',
      border: `1px solid ${theme.palette.gray[500]}`,
      ':hover': {
        border: `1px solid ${theme.palette.brand[600]}`,
        boxShadow: `0 0 0 2px ${theme.palette.brand[300]}`
      },
      '&.Mui-focused': {
        border: `1px solid ${theme.palette.brand[600]}`,
        boxShadow: `0 0 0 2px ${theme.palette.brand[300]}`
      }
    }
  },
  '.login-password-failure': {
    '.MuiInputBase-formControl': {
      border: `1px solid ${theme.palette.red[600]}`,
      boxShadow: `0 0 0 1px ${theme.palette.red[300]}`,
      ':hover': {
        border: `1px solid ${theme.palette.red[600]}`,
        boxShadow: `0 0 0 2px ${theme.palette.red[300]}`
      }
    }
  },
  '.login-username-failure': {
    input: {
      border: `1px solid ${theme.palette.red[600]}`,
      boxShadow: `0 0 0 1px ${theme.palette.red[300]}`,
      ':hover': {
        border: `1px solid ${theme.palette.red[600]}`,
        boxShadow: `0 0 0 2px ${theme.palette.red[300]}`
      }
    }
  }
}))

const Login = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { getAuthenticationState } = useAuthenticationContext()
  const [loginLoading, setLoginLoading] = useState(false)
  const [loginFailure, setLoginFailure] = useState(false)
  const { fetchAudiologistInfo } = useAudiologistContext()
  const [credentials, setCredential] = useState({
    username: '',
    password: ''
  })
  const [showPassword, setShowPassword] = useState(false)

  const from = location.state?.from?.pathname || '/customer'

  const handleChange = prop => event => {
    setLoginFailure(false)
    setCredential({ ...credentials, [prop]: event.target.value })
  }
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleLogin = async () => {
    try {
      setLoginLoading(true)
      setLoginFailure(false)
      await login(credentials.username, credentials.password)
      await getAuthenticationState()
      await fetchAudiologistInfo()
      setLoginLoading(false)
      navigate(from)
    } catch (e) {
      setLoginFailure(true)
      setLoginLoading(false)
      console.log('userLogin Exception: ', e)
    }
  }

  return (
    <Box>
      <Grid container direction='column'>
        <Grid item>
          <LoginHeader container>
            <Grid item>
              <OrkaLogo />
            </Grid>
            <Grid item sx={{ marginLeft: '20px' }}>
              <Typography variant='h3'>orka fitting console</Typography>
            </Grid>
          </LoginHeader>
        </Grid>
        <Grid
          item
          sx={{ margin: 'clamp(16px,calc((100vh - 520px)/2),104px) auto 0' }}
        >
          <LoginContent fixed container>
            <Grid item>
              <Typography variant='h2'>
                Log in to Orka Fitting Console
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                helperText={
                  loginFailure
                    ? (
                      <FCHelperText type='error'>
                        <WarningCircle />
                        Invaild username.
                      </FCHelperText>
                      )
                    : (
                      <FCHelperText>&nbsp;</FCHelperText>
                      )
                }
                id='outlined-adornment-username'
                fullWidth
                label='Username'
                className={loginFailure ? 'login-username login-username-failure' : 'login-username'}
                error={loginFailure}
                onChange={handleChange('username')}
              />
              <FormControl
                variant='outlined'
                fullWidth
                className={loginFailure ? 'login-password login-password-failure' : 'login-password'}
              >
                <InputLabel htmlFor='outlined-adornment-password'>
                  Password
                </InputLabel>
                <OutlinedInput
                  id='outlined-adornment-password'
                  type={showPassword ? 'text' : 'password'}
                  onChange={handleChange('password')}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        edge='end'
                      >
                        <Eye on={showPassword} />
                      </IconButton>
                    </InputAdornment>
                  }
                  fullWidth
                />
                {loginFailure
                  ? (
                    <FCHelperText type='error'>
                      <WarningCircle />
                      Invaild password.
                    </FCHelperText>
                    )
                  : (
                    <FCHelperText>&nbsp;</FCHelperText>
                    )}
              </FormControl>
            </Grid>
            <Grid item sx={{ margin: 'auto' }}>
              <FCButton
                loading={loginLoading}
                variant='contained'
                size='large'
                sx={{ width: '240px' }}
                onClick={() => handleLogin()}
              >
                log in
              </FCButton>
            </Grid>
          </LoginContent>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Login
