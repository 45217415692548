import { DeviceModel } from 'config'
import { createContext, useContext, useState } from 'react'

const GaintableContext = createContext({})

const useGaintableContext = () => {
  return useContext(GaintableContext)
}

const useGaintableStates = () => {
  const [leftGainData, setLeftGainData] = useState([])
  const [rightGainData, setRightGainData] = useState([])
  const [leftMPO, setLeftMPO] = useState([])
  const [rightMPO, setRightMPO] = useState([])
  const [deviceModel, setDeviceModel] = useState(DeviceModel.OrkaOnePro)
  const [rightOutputData, setRightOutputData] = useState([
    new Array(3).fill(0).map(() => new Array(8).fill(0))
  ])
  const [leftOutputData, setLeftOutputData] = useState([
    new Array(3).fill(0).map(() => new Array(8).fill(0))
  ])
  const [gainTableEditHistory, setGainTableEditHistory] = useState([
    { leftGainData, rightGainData, leftMPO, rightMPO, leftOutputData, rightOutputData }
  ])
  const [leftGainDataView, setLeftGainDataView] = useState(
    leftGainData.slice(0)
  )

  const [rightGainDataView, setRightGainDataView] = useState(
    rightGainData.slice(0)
  )

  const [leftGainDataOrigin, setLeftGainDataOrigin] = useState(
    leftGainData.slice(0)
  )

  const [rightGainDataOrigin, setRightGainDataOrigin] = useState(
    rightGainData.slice(0)
  )
  const [currentSession, setCurrentSession] = useState([])
  const [editingHistoryIndex, setEditingHistoryIndex] = useState(0)
  const [previousSession, setPreviousSession] = useState([])
  const [gainLevel, setGainLevel] = useState(1)

  // audiogram
  const [leftAudiogramData, setLeftAudiogramData] = useState([[], [], [], []])
  const [rightAudiogramData, setRightAudiogramData] = useState([[], [], [], []])
  // fine tuning session
  const [fittingData, setFittingData] = useState('')
  const initialData = () => {
    setLeftAudiogramData([[], [], [], []])
    setRightAudiogramData([[], [], [], []])
    setLeftGainData([])
    setRightGainData([])
    setLeftMPO([])
    setRightMPO([])
    setRightOutputData([new Array(3).fill(0).map(() => new Array(8).fill(0))])
    setLeftOutputData([new Array(3).fill(0).map(() => new Array(8).fill(0))])
    setGainTableEditHistory([{ leftGainData, rightGainData, leftMPO, rightMPO, leftOutputData, rightOutputData }])
    setLeftGainDataView([])
    setLeftGainDataView([])
    setRightGainDataView([])
    setLeftGainDataOrigin([])
    setRightGainDataOrigin([])
    setCurrentSession([])
    setEditingHistoryIndex(0)
    setPreviousSession([])
    setGainLevel(1)
    setFittingData('')
  }
  return {
    rightGainData,
    setRightGainData,
    leftGainData,
    setLeftGainData,
    leftMPO,
    setLeftMPO,
    rightMPO,
    setRightMPO,
    deviceModel,
    setDeviceModel,
    gainTableEditHistory,
    setGainTableEditHistory,
    rightOutputData,
    setRightOutputData,
    leftOutputData,
    setLeftOutputData,
    leftGainDataView,
    setLeftGainDataView,
    rightGainDataView,
    setRightGainDataView,
    leftGainDataOrigin,
    setLeftGainDataOrigin,
    rightGainDataOrigin,
    setRightGainDataOrigin,
    currentSession,
    setCurrentSession,
    editingHistoryIndex,
    setEditingHistoryIndex,
    previousSession,
    setPreviousSession,
    gainLevel,
    setGainLevel,
    leftAudiogramData,
    setLeftAudiogramData,
    rightAudiogramData,
    setRightAudiogramData,
    fittingData,
    setFittingData,
    initialData
  }
}

export { GaintableContext, useGaintableContext, useGaintableStates }
