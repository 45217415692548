import React, { useState, useEffect } from 'react'
import moment from 'moment'
import {
  Box,
  Grid,
  ListItemIcon,
  ThemeProvider,
  Typography,
  useTheme
} from '@mui/material'
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  useNavigate,
  useLocation
} from 'react-router-dom'

import RemoteFitting from './RemoteFitting'
import InClinicFitting from './InClinicFitting'
import BackendFitting from './BackendFitting'
import Login from './Login'
import Customer from './Customer'
import { endClinicSession } from 'services/apis/v1/audiologist'

import {
  AuthenticationContext,
  useAuthenticationStates
} from 'contexts/Authentication'
import fittingConsoleTheme from 'styles/theme'
import 'styles/fonts/inter.css'
import { RequireAuthentication } from 'pages/utils/AuthInterceptor'
import { FCTabs, FCTab } from 'components/Tab'
import { OrkaLogo, Menu } from 'components/Icon'
import { FCMenu, FCMenuItem } from 'components/Menu'
import Logout from 'components/Icon/Logout'
import {
  AudiologistContext,
  useAudiologistContext,
  useAudiologistStates
} from 'contexts/Audiologist'
import { logout } from 'services/apis/authentication'
import {
  CustomerContext,
  useCustomerContext,
  useCustomerStates
} from 'contexts/Customer'
import { useGlobalStates, GlobalContext } from 'contexts/Global'
import {
  AppointmentContext,
  useAppointmentContext,
  useAppointmentStates
} from 'contexts/Appointment'
import FCNotification from 'components/Notification'
import FCButton from 'components/Button'
import { handleApprochingAppointment } from 'services/customers'
import { PageContext, usePageContext, usePageStates } from 'contexts/Page'
import { SessionEndDialog } from './sections/SessionEndDialog'
import { ProfileUnsavedDialog } from './sections/CustomerProfile/ProfileUnsavedDialog'
import { AudiogramUnsavedDialog } from './sections/AudiogramSection/AudiogramUnsavedDialog'
import {
  useWebSocketContext,
  useWebSocketFittingStates,
  useWebSocketStates,
  WebSocketContext,
  WebSocketFittingContext
} from 'contexts/WebSocket'
import {
  ClinicFittingContext,
  useClinicFittingContext,
  useClinicFittingStates
} from 'contexts/ClinicFitting'
import { DeviceContext, useDeviceStates } from 'contexts/Device'
import {
  GaintableContext,
  useGaintableStates,
  useGaintableContext
} from 'contexts/Gaintable'
import { useTranslation } from 'react-i18next'
import CnIcon from 'assets/imgs/Cn.svg'
import EnIcon from 'assets/imgs/En.svg'
const Console = () => {
  const authentication = useAuthenticationStates()
  const audiologist = useAudiologistStates()
  const customer = useCustomerStates()
  const appointment = useAppointmentStates()
  const clinic = useClinicFittingStates()
  const pageContext = usePageStates()
  const ws = useWebSocketStates()
  const wsFitting = useWebSocketFittingStates()
  const device = useDeviceStates()
  const gaintable = useGaintableStates()
  const global = useGlobalStates()
  return (
    <ThemeProvider theme={fittingConsoleTheme}>
      <BrowserRouter>
        <GlobalContext.Provider value={global}>
          <AuthenticationContext.Provider value={authentication}>
            <AudiologistContext.Provider value={audiologist}>
              <CustomerContext.Provider value={customer}>
                <AppointmentContext.Provider value={appointment}>
                  <ClinicFittingContext.Provider value={clinic}>
                    <PageContext.Provider value={pageContext}>
                      <WebSocketContext.Provider value={ws}>
                        <WebSocketFittingContext.Provider value={wsFitting}>
                          <DeviceContext.Provider value={device}>
                            <GaintableContext.Provider value={gaintable}>
                              <Routes>
                                <Route path='/login' element={<Login />} />
                                <Route path='/' element={<Layout />}>
                                  <Route
                                    index
                                    element={
                                      <RequireAuthentication>
                                        <Customer />
                                      </RequireAuthentication>
                                    }
                                  />
                                  <Route
                                    path='/customer/*'
                                    element={
                                      <RequireAuthentication>
                                        <Customer />
                                      </RequireAuthentication>
                                    }
                                  />
                                  <Route
                                    path='/remote-fitting/*'
                                    element={
                                      <RequireAuthentication>
                                        <RemoteFitting />
                                      </RequireAuthentication>
                                    }
                                  />
                                  <Route
                                    path='/in-clinic-fitting/*'
                                    element={
                                      <RequireAuthentication>
                                        <InClinicFitting />
                                      </RequireAuthentication>
                                    }
                                  />
                                  <Route
                                    path='/backend-fitting/*'
                                    element={
                                      <RequireAuthentication>
                                        <BackendFitting />
                                      </RequireAuthentication>
                                    }
                                  />
                                </Route>
                                <Route path='*' element={<h1>404</h1>} />
                              </Routes>
                            </GaintableContext.Provider>
                          </DeviceContext.Provider>
                        </WebSocketFittingContext.Provider>
                      </WebSocketContext.Provider>
                    </PageContext.Provider>
                  </ClinicFittingContext.Provider>
                </AppointmentContext.Provider>
              </CustomerContext.Provider>
            </AudiologistContext.Provider>
          </AuthenticationContext.Provider>
        </GlobalContext.Provider>
      </BrowserRouter>
    </ThemeProvider>
  )
}

const Layout = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const location = useLocation()
  const { t, i18n } = useTranslation()
  const pages = [
    'customer',
    'remote-fitting',
    'in-clinic-fitting',
    'backend-fitting'
  ]
  const currentPage = location.pathname.split('/')[1]
  const currentIndex = pages.indexOf(currentPage)
  const initialPage = currentIndex < 0 ? 0 : currentIndex
  const [page, setPage] = useState(null)
  const [nextPage, setNextPage] = useState(null)
  const [sessionEndDialogOpen, setSessionEndDialogOpen] = useState(false)
  const [profileUnsavedOpen, setProfileUnsavedOpen] = useState(false)
  const [audiogramUnsavedOpen, setAudiogramUnsavedOpen] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)
  const { audiologistInfo, fetchAudiologistInfo } = useAudiologistContext()
  const {
    selectedCustomerEmail,
    selectedCustomer,
    setSelectedCustomerEmail,
    setSelectedCustomer,
    setSelectedHistory,
    setAllCustomers
  } = useCustomerContext()
  const {
    currentAppointment,
    nextAppointment,
    setNextAppointment,
    selectedDate,
    setRemoteNewAppointment,
    setCurrentAppointment
  } = useAppointmentContext()
  const { setClinicFitting, code, setCode } = useClinicFittingContext()
  const { unsavedPageType, setUnsavedPageType, PageType } = usePageContext()
  const { ws, createWebSocket } = useWebSocketContext()
  const [notificationState, setNotificationState] = useState({
    open: true,
    vertical: 'top',
    horizontal: 'right'
  })
  const { initialData } = useGaintableContext()

  const handleEndSession = nextPage => {
    setSessionEndDialogOpen(false)
    setSelectedCustomerEmail(null)
    setCurrentAppointment(null)
    setClinicFitting(null)
    setSelectedCustomer(null)
    setSelectedHistory(null)
    initialData()
    setPage(nextPage)
    if (code) {
      endClinicSession(code)
      setCode(null)
    }
  }

  const wsMessageListener = event => {
    const results = JSON.parse(event.data)
    if (results.appointment) {
      setClinicFitting(results.appointment)
      setSelectedCustomerEmail(results.appointment.user)
    } else if (results.remote_appointment) {
      setRemoteNewAppointment(results.remote_appointment)
    } else {
      console.log('invalid message')
    }
  }

  useEffect(() => {
    if (audiologistInfo === null) {
      const fetchInfo = async () => {
        await fetchAudiologistInfo()
      }
      fetchInfo()
    }
    if (audiologistInfo && ws === null) {
      createWebSocket(audiologistInfo.username, wsMessageListener)
    }
  }, [audiologistInfo])

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const cubicast = window.cubicast
      if (cubicast && cubicast.loaded && audiologistInfo) {
        cubicast.identify(audiologistInfo.username, {
          firstName: audiologistInfo.first_name,
          lastName: audiologistInfo.last_name
        })
      }
    }
  }, [audiologistInfo])

  const handleLogout = () => {
    setAllCustomers(null)
    setSelectedCustomer(null)
    setSelectedCustomerEmail(null)
    setSelectedHistory(null)
    setClinicFitting(null)
    setCurrentAppointment(null)
    logout()
    navigate('/login')
  }

  const handleLanguageSwitch = () => {
    i18n.language === 'en'
      ? i18n.changeLanguage('zh')
      : i18n.changeLanguage('en')
  }

  const handleLeave = nextPage => {
    if (unsavedPageType === PageType.CustomerProfile) {
      setProfileUnsavedOpen(false)
    }
    if (unsavedPageType === PageType.Audiogram) {
      setAudiogramUnsavedOpen(false)
    }
    setUnsavedPageType(null)
    setSelectedCustomerEmail(null)
    setCurrentAppointment(null)
    setSelectedCustomer(null)
    setSelectedHistory(null)
    setPage(nextPage)
  }

  useEffect(() => {
    if (page === 0) {
      navigate('/customer')
    } else if (page === 1) {
      navigate('/remote-fitting')
    } else if (page === 2) {
      navigate('/in-clinic-fitting')
    } else if (page === 3) {
      navigate('/backend-fitting')
    }
  }, [page])

  return (
    <Box minWidth='1440px'>
      <Grid
        container
        sx={{
          boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.03)',
          backgroundColor: theme.palette.white[0],
          position: 'relative',
          zIndex: 10
        }}
      >
        <Grid item container xs justifyContent='flex-start' alignItems='center'>
          <Grid item sx={{ padding: '0 0 0 16px' }}>
            <OrkaLogo />
          </Grid>
          <Grid item sx={{ marginLeft: '20px' }}>
            <Typography variant='h3'>
              {t('orka_fitting_console_title')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={6} justifyContent='center'>
          <Grid item>
            <FCTabs
              value={page || initialPage}
              onChange={(event, newValue) => {
                if ((page || initialPage) === 0 && !unsavedPageType) {
                  // Customer page goes to other pages
                  setSelectedCustomerEmail(null)
                  setCurrentAppointment(null)
                  setSelectedCustomer(null)
                  setSelectedHistory(null)
                  setPage(newValue)
                } else {
                  setNextPage(newValue)
                  if (!unsavedPageType) {
                    if (
                      currentPage === 'remote-fitting' &&
                      (location.pathname.split('/')[2] === 'appointment' ||
                        !location.pathname.split('/')[2])
                    ) {
                      setSessionEndDialogOpen(false)
                      setSelectedCustomerEmail(null)
                      setCurrentAppointment(null)
                      setSelectedCustomer(null)
                      setSelectedHistory(null)
                      setPage(newValue)
                    } else {
                      if (
                        selectedCustomerEmail ||
                        selectedCustomer ||
                        currentAppointment
                      ) {
                        setSessionEndDialogOpen(true)
                      } else {
                        setSelectedCustomerEmail(null)
                        setCurrentAppointment(null)
                        setSelectedCustomer(null)
                        setSelectedHistory(null)
                        setPage(newValue)
                      }
                    }
                  } else if (unsavedPageType === PageType.CustomerProfile) {
                    setProfileUnsavedOpen(true)
                  } else if (unsavedPageType === PageType.Audiogram) {
                    setAudiogramUnsavedOpen(true)
                  }
                }
              }}
            >
              <FCTab label={t('console_appointment_tab_client')} />
              <FCTab label={t('console_appointment_tab_remote_fitting')} />
              <FCTab label={t('console_appointment_tab_in_clinic_fitting')} />
              <FCTab label={t('console_appointment_tab_backend_fitting')} />
            </FCTabs>
          </Grid>
        </Grid>
        <Grid item container xs justifyContent='flex-end' alignItems='center'>
          <Grid item xs={10}>
            <Typography variant='h5' sx={{ textAlign: 'right' }}>
              {audiologistInfo &&
              (audiologistInfo.first_name || audiologistInfo.last_name)
                ? audiologistInfo.first_name + ' ' + audiologistInfo.last_name
                : ''}
            </Typography>
          </Grid>
          <Grid item container xs justifyContent='center'>
            <Grid
              item
              sx={{ display: 'flex', cursor: 'pointer' }}
              onClick={() => setOpenMenu(!openMenu)}
            >
              <Menu />
            </Grid>
            <FCMenu
              sx={{
                '.MuiPopover-paper': {
                  width: '172px',
                  top: '77px !important',
                  left: 'calc(100% - 180px) !important',
                  overflow: 'hidden',
                  ul: {
                    padding: '4px 0px !important',
                    li: {
                      padding: '8px 16px',
                      marginBottom: '0px'
                    }
                  }
                }
              }}
              open={openMenu}
              onClose={() => setOpenMenu(false)}
            >
              <FCMenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                <Typography variant='body14Med'>
                  {t('console_menu_item_log_out')}
                </Typography>
              </FCMenuItem>
              <FCMenuItem onClick={handleLanguageSwitch}>
                <Typography
                  variant='body14Med'
                  sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                >
                  <img src={i18n.language === 'en' ? CnIcon : EnIcon} />
                  {t('console_menu_item_switch_language')}
                </Typography>
              </FCMenuItem>
            </FCMenu>
          </Grid>
        </Grid>
      </Grid>
      {nextAppointment
        ? (
          <FCNotification
            notificationState={notificationState}
            setNotificationState={setNotificationState}
            setValue={setNextAppointment}
          >
            <Typography variant='h4'>next session</Typography>
            <Box sx={{ marginTop: '16px' }}>
              <Typography variant='body16Semi'>
                {nextAppointment.username}
              </Typography>
            </Box>
            <Box sx={{ marginTop: '4px', marginBottom: '22px' }}>
              <Typography variant='body14Reg'>
                {`${nextAppointment.start_time
                .getHours()
                .toString()
                .padStart(2, '0')}:${nextAppointment.start_time
                .getMinutes()
                .toString()
                .padStart(2, '0')}-${nextAppointment.end_time
                .getHours()
                .toString()
                .padStart(2, '0')}:${nextAppointment.end_time
                .getMinutes()
                .toString()
                .padStart(2, '0')}(GMT+8) ${moment(
                nextAppointment.start_time
              ).format('DD/MM/YY')}`}
              </Typography>
            </Box>
            <FCButton
              variant='contained'
              size='small'
              onClick={() => {
                handleApprochingAppointment(
                  nextAppointment.user,
                  nextAppointment.start_time,
                  selectedDate
                )
                setNotificationState({ ...notificationState, open: false })
                setCurrentAppointment(nextAppointment)
                setSelectedCustomerEmail(nextAppointment.user)
                navigate('/remote-fitting/audiogram')
              }}
            >
              {t('console_go_fitting_button')}
            </FCButton>
          </FCNotification>
          )
        : (
            ''
          )}
      <Outlet />
      <SessionEndDialog
        open={sessionEndDialogOpen}
        setOpen={setSessionEndDialogOpen}
        nextPage={nextPage}
        page={page}
        handleEndSession={handleEndSession}
      />
      <ProfileUnsavedDialog
        open={
          unsavedPageType === PageType.CustomerProfile && profileUnsavedOpen
        }
        setOpen={setProfileUnsavedOpen}
        nextPage={nextPage}
        handleLeave={handleLeave}
      />
      <AudiogramUnsavedDialog
        open={unsavedPageType === PageType.Audiogram && audiogramUnsavedOpen}
        setOpen={setAudiogramUnsavedOpen}
        nextPage={nextPage}
        handleLeave={handleLeave}
      />
    </Box>
  )
}

export default Console
