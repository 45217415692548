import React from 'react'
import { Grid, Typography } from '@mui/material'
import FCDialog from 'components/Dialog'
import FCButton from 'components/Button'
import WayIcon from 'assets/imgs/WayIcon.svg'
import { useTranslation } from 'react-i18next'

export const SessionEndDialog = props => {
  const { open, setOpen, handleEndSession, nextPage, page } = props
  const { t } = useTranslation()
  return (
    <FCDialog open={open}>
      <Grid container direction='column' wrap='nowrap'>
        <Grid item className='dialog-img'>
          <img src={WayIcon} alt='' />
        </Grid>
        <Grid item className='dialog-title'>
          <Typography variant='h5'>
            {t('end_session_dialog_title')}
          </Typography>
        </Grid>
        <Grid item className='dialog-description'>
          {page === 1
            ? (
              <Typography variant='body16Reg'>
                {t('end_session_dialog_description_page1')}
              </Typography>)
            : page === 2
              ? (
                <Typography variant='body16Reg'>
                  {t('end_session_dialog_description_page2')}
                </Typography>)
              : (
                <Typography variant='body16Reg'>
                  {t('end_session_dialog_description_page3')}
                </Typography>)}
        </Grid>
        <Grid item className='dialog-button'>
          <FCButton
            variant='outlined'
            size='medium'
            onClick={() => handleEndSession(nextPage)}
          >
            {t('end_session_dialog_end_button')}
          </FCButton>
          <FCButton
            variant='contained'
            size='medium'
            onClick={() => setOpen(false)}
          >
            {t('end_session_dialog_stay_button')}
          </FCButton>
        </Grid>
      </Grid>
    </FCDialog>
  )
}
