import React, { useEffect } from 'react'
import { Grid, Box, Typography, Stack } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'

import * as Icons from 'components/Icon'
import FCSlider from 'components/Slider'
import FCSelect from 'components/Select'
import { FCMenuItem } from 'components/Menu'
import LeftDevice from 'assets/imgs/LeftDevice.png'
import RightDevice from 'assets/imgs/RightDevice.png'
import { useCustomerContext } from 'contexts/Customer'
import { useWebSocketFittingContext } from 'contexts/WebSocket'
import { useDeviceContext } from 'contexts/Device'
import { DomeType, DomeSize } from 'config'
import { fetchDeviceInfo } from 'services/customers'
import { useTranslation } from 'react-i18next'

const Root = styled(Box)(({ theme }) => ({
  width: '510px',
  '& .device-imgs': {
    justifyContent: 'center',
    '& img': {
      width: '144px',
      margin: '0 40px'
    }
  },
  '& .device-orka-one': {
    justifyContent: 'center',
    marginTop: '16px',
    '& h3:first-of-type': {
      color: theme.palette.red[600],
      marginRight: '24px'
    },

    '& h3:last-of-type': {
      color: theme.palette.blue[600],
      marginLeft: '24px'
    }
  },
  '& .device-battery': {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '24px',
    height: '48px',
    stroke: theme.palette.black[700],
    fill: theme.palette.black[700],
    '& span:first-of-type': {
      marginLeft: '4px'
    },
    '& span:nth-of-type(2)': {
      margin: '0 24px',
      width: '104px',
      textAlign: 'center'
    },
    '& span:last-of-type': {
      marginLeft: '4px'
    }
  },
  '& .device-volume': {
    justifyContent: 'center',
    alignItems: 'center',
    height: '48px',
    '&>span:nth-of-type(1)': {
      margin: '0 24px',
      width: '104px',
      textAlign: 'center'
    }
  },
  '& .device-firmware': {
    justifyContent: 'center',
    alignItems: 'center',
    height: '48px',
    '& span:nth-of-type(2)': {
      margin: '0 24px',
      width: '104px',
      textAlign: 'center'
    }
  },
  '& .device-dome': {
    justifyContent: 'center',
    alignItems: 'center',
    height: '48px',
    '& > span': {
      margin: '0 24px',
      width: '104px',
      textAlign: 'center'
    },
    '& > div': {
      width: '162px'
    }
  }
}))
const OrkaDevice = props => {
  const theme = useTheme()
  const {
    deviceModel,
    leftDomeSize,
    rightDomeSize,
    leftDomeType,
    rightDomeType,
    setLeftDomeSize,
    setRightDomeSize,
    setLeftDomeType,
    setRightDomeType
  } = props

  const { selectedCustomer, selectedCustomerEmail } = useCustomerContext()
  const { wsFitting } = useWebSocketFittingContext()
  const { deviceInfo, setDeviceInfo } = useDeviceContext()
  const { t } = useTranslation()

  useEffect(() => {
    if (selectedCustomerEmail || selectedCustomer) {
      setDeviceInfo(null)
    }
  }, [selectedCustomerEmail, selectedCustomer])

  useEffect(() => {
    if (wsFitting && wsFitting.readyState === WebSocket.OPEN) {
      wsFitting.send(
        JSON.stringify({
          type: 'command',
          data: {
            name: 'get-device-info',
            values: ''
          }
        })
      )
      wsFitting.onmessage = e => {
        const deviceResults = JSON.parse(e.data)
        fetchDeviceInfo(deviceResults, setDeviceInfo)
      }
    }
  }, [wsFitting])

  return (
    <Root>
      <Grid container direction='column'>
        <Grid item>
          <Grid className='device-imgs' container direction='row' wrap='nowrap'>
            <img src={RightDevice} alt='' />
            <img src={LeftDevice} alt='' />
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            className='device-orka-one'
            container
            direction='row'
            wrap='nowrap'
          >
            <Typography variant='h3'>R</Typography>
            <Typography variant='h3'>{deviceModel}</Typography>
            <Typography variant='h3'>L</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            className='device-battery'
            container
            direction='row'
            wrap='nowrap'
          >
            <Icons.Battery level={deviceInfo && deviceInfo.rightBattery} />
            <Typography variant='body16Med'>
              {deviceInfo && deviceInfo.rightBattery}
            </Typography>
            <Typography variant='body18Semi'>{t('device_info_section_battery')}</Typography>
            <Icons.Battery level={deviceInfo && deviceInfo.leftBattery} />
            <Typography variant='body16Med'>
              {deviceInfo && deviceInfo.leftBattery}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            className='device-volume'
            container
            direction='row'
            wrap='nowrap'
          >
            <Stack spacing={1} direction='row' alignItems='center'>
              <Typography
                variant='body14Semi'
                style={{ color: `${theme.palette.red[600]}` }}
              >
                R
              </Typography>
              <FCSlider
                min={0}
                max={6}
                value={deviceInfo && deviceInfo.rightVolume}
                variant='view-only'
              />
              <Typography variant='body16Med'>
                {deviceInfo && deviceInfo.rightVolume}
              </Typography>
            </Stack>
            <Typography variant='body18Semi'>{t('device_info_section_volume')}</Typography>
            <Stack spacing={1} direction='row' alignItems='center'>
              <Typography
                variant='body14Semi'
                style={{ color: `${theme.palette.blue[600]}` }}
              >
                L
              </Typography>
              <FCSlider
                min={0}
                max={6}
                value={deviceInfo && deviceInfo.leftVolume}
                variant='view-only'
              />
              <Typography variant='body16Med'>
                {deviceInfo && deviceInfo.leftVolume}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            className='device-firmware'
            container
            direction='row'
            wrap='nowrap'
          >
            <Typography variant='body16Med'>Orka OS 1.1.168</Typography>
            <Typography variant='body18Semi'>{t('device_info_section_firmware')}</Typography>
            <Typography variant='body16Med'>Orka OS 1.1.168</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Grid className='device-dome' container direction='row' wrap='nowrap'>
            <FCSelect
              value={t(rightDomeType)}
              displayEmpty
              renderValue={selected => {
                if (selected === '') {
                  return (
                    <Typography
                      variant='body14Med'
                      sx={{ color: theme.palette.black[550] }}
                    >
                      {t('device_info_section_please_select_dome_type')}
                    </Typography>
                  )
                }
                return selected
              }}
              onChange={e => {
                setRightDomeType(e.target.value)
              }}
            >
              <FCMenuItem value={DomeType.Open}>{t('device_info_section_dome_type_open')}</FCMenuItem>
              <FCMenuItem value={DomeType.Vented}>{t('device_info_section_dome_type_vented')}</FCMenuItem>
              <FCMenuItem value={DomeType.Double}>{t('device_info_section_dome_type_double')}</FCMenuItem>
              <FCMenuItem value={DomeType.Customized}>{t('device_info_section_dome_type_customized')}</FCMenuItem>
            </FCSelect>
            <Typography variant='body18Semi'>{t('device_info_section_dome_type')}</Typography>
            <FCSelect
              value={t(leftDomeType)}
              displayEmpty
              renderValue={selected => {
                if (selected === '') {
                  return (
                    <Typography
                      variant='body14Med'
                      sx={{ color: theme.palette.black[550] }}
                    >
                      {t('device_info_section_please_select_dome_type')}
                    </Typography>
                  )
                }
                return selected
              }}
              onChange={e => {
                setLeftDomeType(e.target.value)
              }}
            >
              <FCMenuItem value={DomeType.Open}>{t('device_info_section_dome_type_open')}</FCMenuItem>
              <FCMenuItem value={DomeType.Vented}>{t('device_info_section_dome_type_vented')}</FCMenuItem>
              <FCMenuItem value={DomeType.Double}>{t('device_info_section_dome_type_double')}</FCMenuItem>
              <FCMenuItem value={DomeType.Customized}>{t('device_info_section_dome_type_customized')}</FCMenuItem>
            </FCSelect>
          </Grid>
        </Grid>
        <Grid item>
          <Grid className='device-dome' container direction='row' wrap='nowrap'>
            <FCSelect
              value={t(rightDomeSize)}
              displayEmpty
              renderValue={selected => {
                if (selected === '') {
                  return (
                    <Typography
                      variant='body14Med'
                      sx={{ color: theme.palette.black[550] }}
                    >
                      {t('device_info_section_please_select_dome_size')}
                    </Typography>
                  )
                }
                return selected
              }}
              onChange={e => {
                setRightDomeSize(e.target.value)
              }}
            >
              <FCMenuItem value={DomeSize.Small}>{t('device_info_section_dome_size_small')}</FCMenuItem>
              <FCMenuItem value={DomeSize.Medium}>{t('device_info_section_dome_size_medium')}</FCMenuItem>
              <FCMenuItem value={DomeSize.Large}>{t('device_info_section_dome_size_large')}</FCMenuItem>
            </FCSelect>
            <Typography variant='body18Semi'>{t('device_info_section_dome_size')}</Typography>
            <FCSelect
              value={t(leftDomeSize)}
              displayEmpty
              renderValue={selected => {
                if (selected === '') {
                  return (
                    <Typography
                      variant='body14Med'
                      sx={{ color: theme.palette.black[550] }}
                    >
                      {t('device_info_section_please_select_dome_size')}
                    </Typography>
                  )
                }
                return selected
              }}
              onChange={e => {
                setLeftDomeSize(e.target.value)
              }}
            >
              <FCMenuItem value={DomeSize.Small}>{t('device_info_section_dome_size_small')}</FCMenuItem>
              <FCMenuItem value={DomeSize.Medium}>{t('device_info_section_dome_size_medium')}</FCMenuItem>
              <FCMenuItem value={DomeSize.Large}>{t('device_info_section_dome_size_large')}</FCMenuItem>
            </FCSelect>
          </Grid>
        </Grid>
      </Grid>
    </Root>
  )
}

export default OrkaDevice
