import React from 'react'
import { Slider } from '@mui/material'
import { styled } from '@mui/material/styles'

const SliderBase = styled(Slider)(({ theme }) => ({
  color: theme.palette.brand[500],
  height: '8px',
  width: '168px',
  borderRadius: '4px',
  ' .MuiSlider-thumb': {
    width: '16px',
    height: '16px',
    backgroundColor: theme.palette.brand[700],
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    ':before': {
      boxShadow: '0px 0px 0px 0px #ffffff00 !important'
    },
    '&:hover, &.Mui-focusVisible': {
      boxShadow: `0px 0px 0px 4px ${theme.palette.brand[400]}4D`
    },
    '&.Mui-active': {
      boxShadow: `0px 0px 0px 6px ${theme.palette.brand[400]}80`
    }
  },
  ' .MuiSlider-rail': {
    color: theme.palette.gray[400]
  },
  '.MuiSlider-track': {
    border: 'none'
  },
  '.MuiSlider-markActive': {
    backgroundColor: `${theme.palette.brand[200]} !important`
  },
  '.MuiSlider-mark': {
    color: theme.palette.brand[200],
    backgroundColor: theme.palette.brand[400],
    width: '4px',
    height: '4px',
    borderRadius: '50%'
  }
}))

const SliderViewOnlyBase = styled(SliderBase)(({ theme }) => ({
  height: '16px',
  width: '144px',
  color: theme.palette.brand[600],
  cursor: 'default',
  borderRadius: '6px',
  '.MuiSlider-thumb': {
    display: 'none'
  }
}))

const FCSlider = props => {
  const { variant } = props
  return variant === 'view-only'
    ? (
      <SliderViewOnlyBase {...props} />
      )
    : (
      <SliderBase
        marks={Array(5)
          .fill()
          .map((_value, index) => {
            return { value: index + 1 }
          })}
        {...props}
      />
      )
}

export default FCSlider
