import React, { useState } from 'react'
import { Calendar } from 'antd'
import moment from 'moment'
import { styled } from '@mui/material/styles'
import { Badge, Typography } from '@mui/material'
import FCFab from 'components/Fab'
import Arrow from 'components/Icon/Arrow'

moment.updateLocale('en', {
  weekdaysMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
})

const CalendarBase = styled(Calendar)(({ theme }) => ({
  width: '672px',
  height: '608px',
  background: theme.palette.white[0],
  border: '1px solid rgba(0, 0, 0, 0)',
  borderRadius: '12px',
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.03)',
  '& thead tr': {
    height: '24px',
    width: '558px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '44px',
    '& th': {
      ...theme.typography.body16Semi,
      color: theme.palette.black[900],
      width: '42px'
    },
    '& th:nth-of-type(1)': {
      color: theme.palette.black[550]
    },
    '& th:nth-of-type(7)': {
      color: theme.palette.black[550]
    }
  },
  '& tbody tr': {
    marginTop: '30px',
    display: 'flex',
    width: '558px',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '44px'
  },
  '& .calendarTitle': {
    fontSize: '36px',
    fontWeight: 700,
    lineHeight: '58px',
    height: '64px',
    color: theme.palette.black[900]
  },
  '& .calendarHeader': {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '24px 40px'
  },
  '& .calendarFab': {
    margin: 'auto 0'
  },
  '& .ant-picker-date-panel': {
    margin: '0 57px'
  },
  '& .ant-picker-content': {
    width: '100%',
    tableLayout: 'fixed',
    borderCollapse: 'collapse'
  },
  '& .ant-picker-cell': {
    cursor: 'pointer',
    padding: 0,
    width: '42px',
    height: '42px',
    borderRadius: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .ant-picker-calendar-date': {
      width: '42px',
      height: '42px',
      borderRadius: '30px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.black[400],
      ...theme.typography.body18Semi
    },
    '& .date-activate': {
      background: theme.palette.black[50],
      color: theme.palette.black[400]
    },
    '& .selected-activate': {
      background: theme.palette.brand[400],
      color: theme.palette.white[0]
    }
  },
  '& .date-disabled': {
    background: theme.palette.black[50],
    color: theme.palette.black[400]
  },
  '& .selected-disabled': {
    background: theme.palette.brand[400],
    color: `${theme.palette.white[0]} !important`
  },
  '& .ant-picker-cell-in-view': {
    '& .date-activate': {
      background: theme.palette.black[200],
      color: theme.palette.black[900]
    },
    '& .selected-activate': {
      background: theme.palette.brand[600],
      color: theme.palette.white[0]
    },
    '& .appointment-activate': {
      borderRadius: '30px',
      border: `3px solid ${theme.palette.brand[400]}`
    }
  },
  '& .ant-picker-cell-today': {
    '& .date-activate': {
      background: theme.palette.black[200],
      color: theme.palette.brand[600]
    },
    '& .selected-activate': {
      background: theme.palette.brand[600],
      color: theme.palette.white[0]
    }
  },
  '& .ant-picker-cell-selected': {
    '& .ant-picker-cell-inner': {
      background: theme.palette.brand[600],
      color: theme.palette.white[0],
      transition: 'background 0.3s, color 0.3s'
    }
  }
}))

const FCCalendar = props => {
  const [selectedFlag, setSelectedFlag] = useState(false)
  const { appointments, selectedDate, setSelectedDate, newAppointment, setNewAppointment } = props
  const headerRender = () => {
    return (
      <div className='calendarHeader'>
        <Typography className='calendarTitle'>
          {selectedDate.format('MMMM YYYY')}
        </Typography>
        <div className='calendarFab'>
          <FCFab
            size='small'
            style={{ marginRight: '24px' }}
            onClick={() => handleMonthChange(-1)}
          >
            <Arrow />
          </FCFab>
          <FCFab
            size='small'
            onClick={() => handleMonthChange(1)}
          >
            <Arrow right />
          </FCFab>
        </div>
      </div>
    )
  }
  const cellRender = value => {
    const indexFlag = value.isSame(selectedDate, 'day')
    const disabledDate = value.isBefore(moment(), 'day')
    const isNewAppointment = newAppointment && value.isSame(moment(newAppointment.start_time), 'day')
    let appointmentFlag = false
    appointments && appointments.forEach(item => {
      if (moment(item.start_time).isSame(value, 'day') && moment(item.start_time).isAfter(moment().add(0, 'days'))) {
        appointmentFlag = true
      }
    })
    return (
      <div className={`${appointmentFlag ? 'appointment-activate' : ''}`}>
        <div
          className={
            disabledDate
              ? `ant-picker-calendar-date date-disabled ${
                  selectedFlag && indexFlag ? 'selected-disabled' : ''
                }`
              : `ant-picker-calendar-date date-activate ${
                  selectedFlag && indexFlag ? 'selected-activate' : ''
                }`
          }
        >
          {
            isNewAppointment
              ? (
                <Badge color='warning' variant='dot' style={{ transform: 'translate(10px, -5px)' }}>
                  <div style={{ transform: 'translate(-10px, 5px)' }}>
                    {value.get('date')}
                  </div>
                </Badge>)
              : <div>{value.get('date')}</div>
          }

        </div>
      </div>
    )
  }
  const handleMonthChange = value => {
    setSelectedFlag(false)
    if (selectedDate) {
      setSelectedDate(moment(selectedDate.add(value, 'month')))
    } else {
      setSelectedDate(moment().add(value, 'month'))
    }
  }

  const onSelect = newValue => {
    setSelectedFlag(true)
    setSelectedDate(newValue)
    setNewAppointment(null)
  }

  return (
    <CalendarBase
      value={selectedDate}
      headerRender={headerRender}
      onSelect={onSelect}
      dateFullCellRender={cellRender}
    />
  )
}

export default FCCalendar
