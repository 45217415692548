const Filter = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M15.7324 2H19V4H15.7324C15.3866 4.5978 14.7403 5 14 5C13.2597 5 12.6134 4.5978 12.2676 4H1V2H12.2676C12.6134 1.4022 13.2597 1 14 1C14.7403 1 15.3866 1.4022 15.7324 2Z'
    />
    <path
      d='M19 9H7.73244C7.38663 8.4022 6.74028 8 6 8C5.25972 8 4.61337 8.4022 4.26756 9H1V11H4.26756C4.61337 11.5978 5.25972 12 6 12C6.74028 12 7.38663 11.5978 7.73244 11H19V9Z'
    />
    <path
      d='M8.26756 16H1V18H8.26756C8.61337 18.5978 9.25972 19 10 19C10.7403 19 11.3866 18.5978 11.7324 18H19V16H11.7324C11.3866 15.4022 10.7403 15 10 15C9.25972 15 8.61337 15.4022 8.26756 16Z'
    />
  </svg>
)

export default Filter
