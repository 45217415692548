const Camera = props => {
  const { on } = props

  return on
    ? (
      <svg width='36' height='36' viewBox='0 0 36 36' xmlns='http://www.w3.org/2000/svg'>
        <rect x='4' y='10' width='20' height='16' rx='2' />
        <path d='M30.6061 12.5974L26.6061 14.3117C26.2384 14.4693 26 14.8308 26 15.2308V20.7692C26 21.1692 26.2384 21.5307 26.6061 21.6883L30.6061 23.4026C31.2659 23.6854 32 23.2014 32 22.4835V13.5165C32 12.7986 31.2659 12.3146 30.6061 12.5974Z' />
      </svg>
      )
    : (
      <svg width='36' height='36' viewBox='0 0 36 36' xmlns='http://www.w3.org/2000/svg'>
        <rect x='4' y='10' width='20' height='16' rx='2' />
        <path d='M30.6061 12.5974L26.6061 14.3117C26.2384 14.4693 26 14.8308 26 15.2308V20.7692C26 21.1692 26.2384 21.5307 26.6061 21.6883L30.6061 23.4026C31.2659 23.6854 32 23.2014 32 22.4835V13.5165C32 12.7986 31.2659 12.3146 30.6061 12.5974Z' />
        <path d='M5.59199 5.59396C5.97341 5.21353 6.48938 5 7.0272 5C7.56503 5 8.08099 5.21353 8.46242 5.59396L30.408 27.5209C30.7872 27.9036 31 28.4213 31 28.9609C31 29.5006 30.7872 30.0182 30.408 30.4009C30.2189 30.5909 29.9944 30.7415 29.7473 30.8443C29.5001 30.9471 29.2352 31 28.9677 31C28.7002 31 28.4353 30.9471 28.1881 30.8443C27.941 30.7415 27.7165 30.5909 27.5274 30.4009L5.59199 8.47398C5.21282 8.09128 5 7.57359 5 7.03397C5 6.49436 5.21282 5.97666 5.59199 5.59396Z' fill='#FFBF3D' />
      </svg>
      )
}

export default Camera
