import React from 'react'
import { Input } from '@mui/material'
import { styled } from '@mui/material/styles'

const InputBase = styled(Input)(({ theme }) => ({
  ...theme.typography.body14Med,
  padding: '6px 16px',
  borderRadius: '6px',
  border: `1px solid ${theme.palette.gray[500]}`,
  backgroundColor: theme.palette.gray[200],
  caretColor: theme.palette.brand[600],
  ':hover': {
    border: `1px solid ${theme.palette.brand[600]}`,
    boxShadow: `0 0 0 2px ${theme.palette.brand[300]}`
  },
  '&.Mui-focused': {
    border: `1px solid ${theme.palette.brand[600]}`,
    boxShadow: `0 0 0 2px ${theme.palette.brand[300]}`
  },
  '&.Mui-error': {
    caretColor: theme.palette.red[600],
    border: `1px solid ${theme.palette.red[600]}`,
    backgroundColor: theme.palette.red[100],
    boxShadow: 'none'
  },
  input: {
    maxHeight: '18px',
    padding: 0
  }
}))

const InputReadOnlyBase = styled(Input)(({ theme }) => ({
  ...theme.typography.body14Med,
  border: `1px solid ${theme.palette.gray[200]}`,
  padding: '6px 16px',
  borderRadius: '6px',
  backgroundColor: theme.palette.gray[200],
  caretColor: theme.palette.brand[600],
  input: {
    maxHeight: '18px',
    padding: 0
  }
}))

const FCInput = (props) => {
  const { readOnly } = props
  return readOnly
    ? (
      <InputReadOnlyBase {...props} disableUnderline fullWidth />
      )
    : (
      <InputBase {...props} disableUnderline fullWidth />
      )
}

export default FCInput
