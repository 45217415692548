import React, { useEffect, useState } from 'react'

import { Box, Grid, styled, Typography } from '@mui/material'
import FCInput from 'components/Input/FCInput'
import FCButton from 'components/Button'
import { useCustomerContext } from 'contexts/Customer'
import { postMedicalInfo } from 'services/apis/v1/customer'
import { getCustomerList, getCustomerMedicalInfo } from 'services/customers'
import { postNote } from 'services/apis/v1/appointment'
import { usePageContext } from 'contexts/Page'
import { useTranslation } from 'react-i18next'

const Root = styled(Box)(({ theme }) => ({
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.03)',
  borderRadius: '12px',
  height: '720px',
  backgroundColor: theme.palette.white[0],
  '& .profile-grey-text': {
    color: theme.palette.black[550],
    display: 'flex'
  },
  '& .profile-input': {
    marginTop: '2px'
  },
  '& .profile-divider': {
    height: '1px',
    backgroundColor: theme.palette.black[300],
    paddingTop: '0 !important',
    marginTop: '8px'
  }
}))

const Profile = props => {
  const { containerWidth } = props
  const { selectedCustomer, selectedCustomerEmail, allCustomers, setAllCustomers, setSelectedCustomer } = useCustomerContext()
  const { setUnsavedPageType, PageType } = usePageContext()
  const [medicalInfo, setMedicalInfo] = useState(null)
  const [disabledSave, setDisabledSave] = useState(true)
  const [postProfileLoading, setPostProfileLoading] = useState(false)
  const { t } = useTranslation()
  useEffect(() => {
    const fetchCusotmerInfo = async () => {
      if (!selectedCustomer && selectedCustomerEmail) {
        if (allCustomers) {
          setSelectedCustomer(allCustomers.filter(item => item.email === selectedCustomerEmail)[0])
        } else {
          const result = await getCustomerList()
          setAllCustomers(result)
          setSelectedCustomer(result.filter(item => item.email === selectedCustomerEmail)[0])
        }
      }
      const result = await getCustomerMedicalInfo(selectedCustomerEmail || selectedCustomer.email)
      setMedicalInfo(result)
    }
    if (selectedCustomer || selectedCustomerEmail) {
      fetchCusotmerInfo()
    }
  }, [selectedCustomer, selectedCustomerEmail])

  const handleProfileSave = async () => {
    setPostProfileLoading(true)
    if (selectedCustomer && !disabledSave) {
      await postMedicalInfo(selectedCustomer.email, medicalInfo)
      await postNote(selectedCustomer.email, medicalInfo.note)
      setDisabledSave(true)
      setUnsavedPageType(null)
      setPostProfileLoading(false)
    }
  }
  return (
    <Root
      sx={{
        width: containerWidth || {
          xs: '376px',
          xl: '424px'
        }
      }}
    >
      <Grid container justifyContent='center'>
        <Grid container direction='column' sx={{ padding: '24px' }}>
          <Grid item>
            <Typography variant='h5'>{t('client_tab_profile_section_title')}</Typography>
          </Grid>
          <Grid item container sx={{ marginBottom: '8px' }}>
            <Grid item xs={6} className='profile-grey-text'>
              <Typography variant='body12Med'>
                {selectedCustomer ? selectedCustomer.email : ''}&nbsp;
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={6}
              justifyContent='right'
              className='profile-grey-text'
            >
              <Typography variant='body12Med'>
                {selectedCustomer && selectedCustomer.country ? selectedCustomer.country.toUpperCase() : ''}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            rowSpacing={2}
            direction='column'
            sx={{ padding: '0px 8px' }}
          >
            <Grid item container spacing={1}>
              <Grid item container direction='column' xs={6}>
                <Grid item>
                  <Typography variant='body14Med'>{t('client_tab_profile_section_first_name')}</Typography>
                </Grid>
                <Grid item className='profile-input'>
                  <FCInput
                    readOnly
                    value={selectedCustomer ? selectedCustomer.first_name : ''}
                  />
                </Grid>
              </Grid>
              <Grid item container direction='column' xs={6}>
                <Grid item>
                  <Typography variant='body14Med'>{t('client_tab_profile_section_last_name')}</Typography>
                </Grid>
                <Grid item className='profile-input'>
                  <FCInput
                    readOnly
                    value={selectedCustomer ? selectedCustomer.last_name : ''}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container spacing={1}>
              <Grid item container direction='column' xs={6}>
                <Grid item>
                  <Typography variant='body14Med'>{t('client_tab_profile_section_gender')}</Typography>
                </Grid>
                <Grid item className='profile-input'>
                  <FCInput
                    readOnly
                    value={selectedCustomer ? selectedCustomer.gender : ''}
                  />
                </Grid>
              </Grid>
              <Grid item container direction='column' xs={6}>
                <Grid item>
                  <Typography variant='body14Med'>{t('client_tab_profile_section_age')}</Typography>
                </Grid>
                <Grid item className='profile-input'>
                  <FCInput
                    readOnly
                    value={selectedCustomer ? selectedCustomer.age : ''}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container columnSpacing={1} direction='column' xs>
              <Grid item>
                <Typography variant='body14Med'>{t('client_tab_profile_section_email')}</Typography>
              </Grid>
              <Grid item className='profile-input'>
                <FCInput
                  readOnly
                  value={selectedCustomer ? selectedCustomer.email : ''}
                />
              </Grid>
            </Grid>
            <Grid item className='profile-divider' />

            <Grid item container columnSpacing={1} direction='column' xs>
              <Grid item>
                <Typography variant='body14Med'>{t('client_tab_profile_section_chief_complaint')}</Typography>
              </Grid>
              <Grid item className='profile-input'>
                <FCInput
                  multiline
                  rows={3}
                  value={medicalInfo && medicalInfo.chiefComplaint ? medicalInfo.chiefComplaint : ''}
                  readOnly={!selectedCustomer}
                  onChange={e => {
                    setDisabledSave(false)
                    setUnsavedPageType(PageType.CustomerProfile)
                    setMedicalInfo({ ...medicalInfo, chiefComplaint: e.target.value })
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container columnSpacing={1} direction='column' xs>
              <Grid item>
                <Typography variant='body14Med'>
                  {t('client_tab_profile_section_hearing_aids_wear_experience')}
                </Typography>
              </Grid>
              <Grid item className='profile-input'>
                <FCInput
                  value={medicalInfo && medicalInfo.experience ? medicalInfo.experience : ''}
                  readOnly={!selectedCustomer}
                  onChange={e => {
                    setDisabledSave(false)
                    setUnsavedPageType(PageType.CustomerProfile)
                    setMedicalInfo({ ...medicalInfo, experience: e.target.value })
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container columnSpacing={1} direction='column' xs>
              <Grid item>
                <Typography variant='body14Med'>
                  {t('client_tab_profile_section_hearing_preference_lifestyle')}
                </Typography>
              </Grid>
              <Grid item className='profile-input'>
                <FCInput
                  multiline
                  rows={3}
                  value={medicalInfo && medicalInfo.lifeStyle ? medicalInfo.lifeStyle : ''}
                  readOnly={!selectedCustomer}
                  onChange={e => {
                    setDisabledSave(false)
                    setUnsavedPageType(PageType.CustomerProfile)
                    setMedicalInfo({ ...medicalInfo, lifeStyle: e.target.value })
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container columnSpacing={1} direction='column' xs>
              <Grid item>
                <Typography variant='body14Med'>{t('client_tab_profile_section_note')}</Typography>
              </Grid>
              <Grid item className='profile-input'>
                <FCInput
                  multiline
                  rows={3}
                  value={medicalInfo && medicalInfo.note ? medicalInfo.note : ''}
                  readOnly={!selectedCustomer}
                  onChange={e => {
                    setDisabledSave(false)
                    setUnsavedPageType(PageType.CustomerProfile)
                    setMedicalInfo({ ...medicalInfo, note: e.target.value })
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container justifyContent='center' rowSpacing={2}>
              <Grid item>
                <FCButton loading={postProfileLoading} variant='contained' size='small' disabled={disabledSave} onClick={() => { handleProfileSave() }}>
                  {t('client_tab_profile_section_save_changes')}
                </FCButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Root>
  )
}

export default Profile
