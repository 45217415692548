import { createContext, useContext, useState } from 'react'

const PageContext = createContext({})

const usePageContext = () => {
  return useContext(PageContext)
}

const usePageStates = () => {
  // judge whether the customer profile/audiogram is saved
  // unsaved page type: customer profile/ audiogram
  const [contentIsNotSaved, setContentIsNotSaved] = useState(false)
  const [unsavedPageType, setUnsavedPageType] = useState(null)
  const PageType = {
    CustomerProfile: 'profile',
    Audiogram: 'audiogram'
  }

  return {
    PageType,
    contentIsNotSaved,
    setContentIsNotSaved,
    unsavedPageType,
    setUnsavedPageType
  }
}

export { PageContext, usePageContext, usePageStates }
