import React from 'react'
import { Grid, styled, Typography, TextField, Box, useTheme } from '@mui/material'
import FCDialog from 'components/Dialog'
import FCButton from 'components/Button'
import UserIcon from 'assets/imgs/UserIcon.svg'
import { WarningCircle } from 'components/Icon'
import { FCHelperText } from 'components/Input'

const TextFieldBase = styled(TextField)(({ theme }) => ({
  caretColor: theme.palette.brand[600],
  marginTop: '16px',
  marginBottom: '22px',
  fieldset: {
    border: 'none'
  },
  '.MuiInputLabel-outlined': {
    ...theme.typography.body16Med
  },
  '.MuiInputLabel-shrink': {
    ...theme.typography.body12Med,
    color: theme.palette.black[550],
    top: 16
  },
  '.Mui-focused': {
    color: `${theme.palette.black[550]} !important`
  },
  '.MuiFormHelperText-root': {
    margin: '0'
  },
  '.Mui-error': {
    ...theme.typography.body12Med,
    color: `${theme.palette.red[600]} !important`,
    input: {
      border: `1px solid ${theme.palette.red[600]}`,
      boxShadow: `0 0 0 2px ${theme.palette.red[300]}`,
      ':hover': {
        border: `1px solid ${theme.palette.red[600]}`,
        boxShadow: `0 0 0 2px ${theme.palette.red[300]}`
      },
      '&.Mui-focused': {
        border: `1px solid ${theme.palette.red[600]}`,
        boxShadow: `0 0 0 2px ${theme.palette.red[300]}`
      }
    }
  },
  input: {
    borderRadius: '6px',
    color: theme.palette.black[900],
    backgroundColor: `${theme.palette.white[0]} !important`,
    ...theme.typography.body16Med,
    padding: '20px 16px 7px 16px',
    border: `1px solid ${theme.palette.gray[500]}`,
    ':hover': {
      border: `1px solid ${theme.palette.brand[600]}`,
      boxShadow: `0 0 0 2px ${theme.palette.brand[300]}`
    },
    '&.Mui-focused': {
      border: `1px solid ${theme.palette.brand[600]}`,
      boxShadow: `0 0 0 2px ${theme.palette.brand[300]}`
    }
  }
}))

export const EmailEditDialog = (props) => {
  const { emailDialog, setEmailDialog, handleSetEmail, confirmUserLoading } = props
  return (
    <FCDialog open={emailDialog.open}>
      <Grid container direction='column' wrap='nowrap'>
        <Grid item className='dialog-img'>
          <img src={UserIcon} alt='' />
        </Grid>
        <Grid item className='dialog-title'>
          <Typography variant='h5'>Please enter the user email</Typography>
        </Grid>
        <Grid item className='dialog-description'>
          <TextFieldBase
            id='outlined-adornment-username'
            fullWidth
            label='Email'
            error={emailDialog.error}
            helperText={
                emailDialog.error
                  ? (
                    <FCHelperText type='error'>
                      <WarningCircle />
                      Incorrect email.
                    </FCHelperText>
                    )
                  : (
                    <Box sx={{ height: '22px' }} />
                    )
              }
            onChange={e => {
              setEmailDialog({ ...emailDialog, email: e.target.value, error: false })
            }}
          />
        </Grid>
        <Grid item className='dialog-button'>
          <FCButton
            variant='outlined'
            size='medium'
            onClick={() => setEmailDialog({ ...emailDialog, open: false })}
          >
            cancle
          </FCButton>
          <FCButton
            variant='contained'
            size='medium'
            loading={confirmUserLoading}
            onClick={() => handleSetEmail()}
          >
            next
          </FCButton>
        </Grid>
      </Grid>
    </FCDialog>
  )
}
