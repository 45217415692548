import axios from 'axios'

import { verifyToken } from '../authentication'

const HTTP_HOST = process.env.REACT_APP_HTTP_HOST

export default function client () {
  const ax = axios.create({
    baseURL: HTTP_HOST,
    headers: {
      'Content-Type': 'application/json'
    }
  })

  ax.interceptors.request.use(async config => {
    // Verify token before ax request is sent
    const verifiedToken = await verifyToken()
    if (verifiedToken) {
      config.headers.Authorization = verifiedToken
    }
    return config
  })

  return ax
}
